import * as React from "react";
import { observer, inject } from "mobx-react";
import { Input, SelectModal, Button } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { action, observable } from "mobx";

interface Props {}
interface State {}

@inject("store")
@observer
class OrderConfigTablesClass extends MobxComponent<Props, State> {
  @observable modal: boolean = false;

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  @action setModal = (modal: boolean) => {
    this.modal = modal;
  };

  onSelect = (o: { label: string; value: string }) => {
    console.log(o);
    this.injected.store.order_config.setTable(o.value, o.label);
    this.setModal(false);
  };

  onClose = () => {
    this.setModal(false);
  };

  render() {
    const { store } = this.injected;
    const r = this.injected.store.r;
    const oc = this.injected.store.order_config.s;
    const od = this.injected.store.order_config.d;

    const table = r.service_dine_in_tables.find((tab) => {
      return oc.config_table_id === tab.id || oc.config_table_id === tab.short_id;
    });

    const buttonText = table ? table.name : "Select Table";

    return (
      <div className="mt-6 mx-auto" style={{ maxWidth: "350px" }}>
        <div className="flex justify-center items-center">
          <Button onClick={() => this.setModal(true)} style={{ minWidth: "150px" }}>
            {buttonText}
          </Button>
          {table && table.password && (
            <Input
              value={od.table_password}
              onChange={(e) => store.order_config.updateD({ table_password: e.target.value })}
              placeholder="Table Password"
              className="ml-2 w-40 max-w-0 text-center"
            />
          )}
        </div>

        {table && table.password && od.table_password !== table.password && (
          <div className="mt-4 text-center">
            <p className="text-md">Enter your table password to proceed</p>
          </div>
        )}

        <SelectModal
          innerId="tables-select"
          title="Select Your Table"
          level={2}
          width={420}
          active={this.modal}
          onSelect={this.onSelect}
          close={this.onClose}
          options={store.r.service_dine_in_tables.map((tab) => {
            return {
              label: tab.name,
              value: tab.id,
            };
          })}
        />
      </div>
    );
  }
}

export const OrderConfigTables = OrderConfigTablesClass;
