import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import darken from "polished/lib/color/darken";
import { styled, CS, RotateLoader } from "@lib/components";
import { OrderHistoryItem } from "./item";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

const List = styled("div")``;

const PageCtrl = styled("div")`
  display: flex;
  align-items: stretch;
  > div {
    border-right: var(--box-border);
    &:last-child {
      border-right: none;
    }
  }
`;

const PageArrow = styled("div")`
  ${CS.flex.center};
  padding: 10px;
  flex-grow: 1;
  cursor: pointer;
  &:hover {
    background: ${(props) => darken(0.05, props.theme.colors.background)};
  }
`;

const PageCount = styled("div")`
  ${CS.flex.center};
  padding: 10px;
  display: flex;
  width: 200px;
`;

interface Props {}
interface State {
  active_orders: number[];
}

@inject("store")
@observer
class CustomerOrderHistoryClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active_orders: [],
    };
  }

  toggleActive = (index: number) => {
    const active_orders = [...this.state.active_orders];
    const existing = active_orders.indexOf(index);
    if (existing !== -1) {
      active_orders.splice(existing, 1);
    } else {
      active_orders.push(index);
    }
    this.setState({ active_orders });
  };

  isActive = (index: number) => {
    return this.state.active_orders.indexOf(index) !== -1;
  };

  totalPages() {
    return Math.ceil((this.injected.store.order_history.s.count || 0) / 5);
  }

  changePage = (forward: boolean) => {
    const { store } = this.injected;
    const page = store.order_history.s.page;
    const totalPages = this.totalPages();
    if (forward) {
      if (page + 1 <= totalPages) {
        this.setState({ active_orders: [] });
        store.order_history.setPage(page + 1);
      }
    } else if (page - 1 >= 1) {
      this.setState({ active_orders: [] });
      store.order_history.setPage(page - 1);
    }
  };

  render() {
    if (!this.state) return;
    const { store } = this.injected;
    const { items, page, count, loading, error } = store.order_history.s;
    return (
      <>
        {loading && (
          <div className="p-6">
            <RotateLoader size={3} />
          </div>
        )}

        {!!error && (
          <div>
            <p className="text-center">
              Something went wrong,{" "}
              <span
                className="primary-text underline cursor"
                onClick={() => store.order_history.getHistory(1)}>
                try Again
              </span>
            </p>
          </div>
        )}

        {!loading && !error && (
          <>
            <List>
              {items.map((o, i) => {
                const active = this.isActive(i);
                return (
                  <OrderHistoryItem
                    o={o}
                    i={i}
                    key={i}
                    active={active}
                    toggleActive={() => this.toggleActive(i)}
                  />
                );
              })}
            </List>
            <PageCtrl>
              <PageArrow onClick={() => this.changePage(false)}>
                <FaChevronLeft />
              </PageArrow>
              <PageCount>
                {page} / {this.totalPages()}
              </PageCount>
              <PageArrow onClick={() => this.changePage(true)}>
                <FaChevronRight />
              </PageArrow>
            </PageCtrl>
          </>
        )}
      </>
    );
  }
}

export const CustomerOrderHistory = CustomerOrderHistoryClass;
