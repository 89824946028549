import * as React from "react";
import { observer, inject } from "mobx-react";
import { CircleIcon, RestaurantOpeningHours, RestaurantSpecialOpeningHours } from "@lib/components";
import { FaClock } from "react-icons/fa";
import { MobxComponent } from "../../../mobx/component";
import { ServiceInfo } from "./services";

interface Props {}

@inject("store")
@observer
class InfoOpeningHoursClass extends MobxComponent<Props, { active: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  render() {
    const r = this.injected.store.r;
    return (
      <>
        <CircleIcon icon={<FaClock />} />
        <h3 className="text-center font-normal mb-6 mt-4">Opening Hours</h3>
        <div className="text-md leading-tight">
          <RestaurantOpeningHours hours={r.location!.opening_hours} />
        </div>
        {r.location!.special_hours && r.location!.special_hours.length > 0 && (
          <div className="mt-6 ">
            <ServiceInfo
              title="Special Opening Hours"
              active={this.state.active}
              onClick={() => this.setState({ active: !this.state.active })}
              content={<RestaurantSpecialOpeningHours hours={r.location!.special_hours} />}
            />
          </div>
        )}
      </>
    );
  }
}

export const InfoOpeningHours = InfoOpeningHoursClass;
