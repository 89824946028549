import { observable, action } from "mobx";
import { RootStore } from "../store";
import { logger, CoreUtils, isPWA, listenDevicePWAChange } from "@lib/common";
import throttle from "lodash/throttle";
import { windowScrollTop } from "../../../client/utils";

export interface ViewState {
  breakpoint: T.UIBreakpoint;
  screen_width: number;
  scroll_top: number;
  top_nav_height: number;
  menu_nav_stuck: boolean;
  reseller_portal_url: string;
  is_web_view: boolean;
  is_pwa: boolean;
  is_kiosk: boolean;
  platform: string;
  app_id: string | null;
  domain: string;
  age_confirmed: boolean;
}

export class ViewStore {
  @observable s: ViewState;
  store: RootStore;

  constructor(store: RootStore, initialState?: ViewState) {
    this.store = store;
    this.s = {
      breakpoint: "md",
      screen_width: 560,
      scroll_top: 0,
      top_nav_height: 52,
      menu_nav_stuck: false,
      reseller_portal_url: "",
      is_web_view: false,
      is_pwa: false,
      is_kiosk: false,
      platform: "web",
      app_id: "",
      domain: "",
      age_confirmed: false,
      ...(initialState || {}),
    };
    if (typeof window !== "undefined") {
      this.windowResize();
      this.windowScroll();
      window.addEventListener("resize", throttle(this.windowResize, 200));
      window.addEventListener("scroll", throttle(this.windowScroll, 200));

      this.update({
        is_pwa: isPWA(),
      });
      listenDevicePWAChange((is_pwa) => {
        this.update({ is_pwa });
      });

      if (store.router.s.query.is_kiosk === "true") {
        this.s.is_kiosk = true;
        localStorage.setItem("is_kiosk", "true");
      } else {
        this.s.is_kiosk = localStorage.getItem("is_kiosk") === "true";
      }
    }
  }

  @action windowResize = () => {
    const width = window.innerWidth;
    const breakpoint = CoreUtils.ui.breakpoint(width);
    this.s.screen_width = width;
    this.s.breakpoint = breakpoint;
  };

  @action windowScroll = () => {
    try {
      this.s.scroll_top = windowScrollTop();
    } catch (e) {
      logger.captureException(e, "SCROLL FUNCTION ERROR");
    }
  };

  @action update(data: Partial<ViewState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof ViewState];
        if (value !== undefined && this.s) {
          // @ts-ignore
          this.s[key as keyof ViewState] = value;
        }
      }
    }
  }
}
