import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { FaTimesCircle } from "react-icons/fa";
import { Button, Input, BlockError, Tag } from "@lib/components";
import { formatCurrency, RestaurantUtils } from "@lib/common";
import { RestaurantMenuDishFullFragment } from "@lib/types/graphql";

interface Props {}
interface State {}

const errors: { [key: string]: string } = {
  not_found: "Promo not found",
  disabled: "Promo is disabled",
  not_available: "Promo is currently not available",
  logged_in_only: "Must be logged in to use this promo",
  already_used: "You have already used this promo",
  invalid: "This promo is no longer valid",
  invalid_service: "This promo is not valid for your chosen service",
  later_only: "Valid for pre-orders only",
  now_only: "Valid only for orders due immediately not pre-orders",
  generic: "Oops... something went wrong and has been logged",
  dishes_required_multi:
    "This promo will only be applied when certain dishes are added to your cart",
  dishes_required_single: "This promo will only be applied for '{{dish}}'",
};

@inject("store")
@observer
class OrderCartPromoClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;

    const { promo_form_code, promo_form_error } = store.order_config.d;

    const promo = store.cart.promo;

    if (promo) {
      const promoLimitDishesWarning = !store.cart.promoLimitToDishesValid;
      let promoLimitDishesWarningSingle: RestaurantMenuDishFullFragment | null = null;
      if (promoLimitDishesWarning) {
        if (promo.limit_to_dishes!.length === 1) {
          const find = RestaurantUtils.menu.findDish(store.menusAll, promo.limit_to_dishes![0]);
          if (find) {
            promoLimitDishesWarningSingle = find.dish;
          }
        }
      }

      return (
        <div>
          <div className="flex items-center justify-between border py-2 px-3 rounded bg-white">
            <div className="flex-grow">
              <p className="text-sm">
                {promo.name} <span className="font-semibold underline">({promo.code})</span>
              </p>
              {(!!promo.fixed_discount || !!promo.percent_discount) && (
                <div className="mt-1">
                  {!!promo.fixed_discount && (
                    <Tag className="mr-3 !text-xs">{formatCurrency(promo.fixed_discount)} Off</Tag>
                  )}
                  {!!promo.percent_discount && (
                    <Tag className="!text-xs">{promo.percent_discount}% Off</Tag>
                  )}
                </div>
              )}
            </div>
            <div className="cursor-pointer" onClick={store.cart.promoRemove}>
              <FaTimesCircle />
            </div>
          </div>
          {promoLimitDishesWarning && (
            <>
              {!!promoLimitDishesWarningSingle && (
                <BlockError className="mt-3">
                  This promo will only be applied for '{promoLimitDishesWarningSingle.name}'
                </BlockError>
              )}
              {!promoLimitDishesWarningSingle && (
                <BlockError className="mt-3">
                  This promo will only be applied when certain dishes are added to your cart
                </BlockError>
              )}
            </>
          )}
          {!store.cart.maxOrderPromoValid && (
            <BlockError className="mt-3">
              Promo cannot be used for orders over {formatCurrency(store.cart.maxOrderPromo!)}
            </BlockError>
          )}
          {!store.cart.minOrderPromoValid && (
            <BlockError className="mt-3">
              Minimum cart value of {formatCurrency(store.cart.minOrderPromo!)} required before this
              promo is applied
            </BlockError>
          )}
        </div>
      );
    }
    return (
      <div>
        <div className="flex">
          <Input
            name="promo"
            value={promo_form_code}
            className="!rounded-r-none"
            placeholder="Promo Code"
            onKeyPress={(e) => (e.key === "Enter" ? store.cart.promoAdd(promo_form_code) : null)}
            onChange={(e) => store.order_config.updateD({ promo_form_code: e.target.value })}
          />
          <Button
            color="white"
            className="!border-l-0 !rounded-l-none"
            onClick={() => store.cart.promoAdd(promo_form_code)}>
            <span className="text-sm">Add Code</span>
          </Button>
        </div>
        {promo_form_error && <BlockError className="mt-3">{errors[promo_form_error]}</BlockError>}
      </div>
    );
  }
}

export const OrderCartPromo = OrderCartPromoClass;
