import * as React from "react";
import { observer, inject } from "mobx-react";
import { Input } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";

interface Props {}
interface State {}

@inject("store")
@observer
class OrderConfigNumberOfPeopleClass extends MobxComponent<Props, State> {
  render() {
    const oc = this.injected.store.order_config;
    return (
      <div className="mt-6 mx-auto" style={{ maxWidth: "350px" }}>
        <Input
          className="text-center"
          value={oc.s.config_num_people}
          placeholder="Number Of People"
          onChange={(e) => {
            oc.setNumberOfPeople(parseInt(e.target.value, 10) || "");
          }}
        />
      </div>
    );
  }
}

export const OrderConfigNumberOfPeople = OrderConfigNumberOfPeopleClass;
