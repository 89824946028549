import { observable, action } from "mobx";
import { RootStore } from "../store";
import * as React from "react";
import { logger, RestaurantUtils, cloneDeepSafe, menuOptionSets, menuDish } from "@lib/common";
import {
  OrderDishChoiceFullFragment,
  OrderDishFullFragment,
  RestaurantMenuDishFullFragment,
  RestaurantOrderFullFragment,
} from "@lib/types/graphql";
import { v4 as uuid } from "uuid";

export interface OrderHistoryState {
  items: RestaurantOrderFullFragment[];
  page: number;
  count: number;
  loading: boolean;
  error: string;
}

export class OrderHistoryStore {
  @observable s: OrderHistoryState;

  store: RootStore;

  constructor(store: RootStore, initialState?: OrderHistoryState) {
    this.store = store;
    this.s = initialState || {
      items: [],
      page: 1,
      count: 0,
      loading: false,
      error: "",
    };
  }

  // INTERACTIONS
  clickReceipt = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
    e.stopPropagation();
    const { store } = this;
    const o = store.order_history.s.items[i];
    if (o) {
      store.modal.close();
      store.router.push(`/order/${o.id}`);
    }
  };
  clickOrder = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
    e.stopPropagation();
    const { store } = this;
    const od = store.order_config.d;
    if (!od.confirmed) {
      store.modal.show("order-config");
    } else {
      const o = cloneDeepSafe(store.order_history.s.items[i]);
      if (o) {
        for (const [dishIndex, dish] of o.dishes.entries()) {
          o.dishes[dishIndex].id = uuid();

          for (const [optionIndex] of dish.options.entries()) {
            o.dishes[dishIndex].options[optionIndex].option.id = uuid();
          }

          for (const [ingredientIndex] of dish.ingredients.entries()) {
            o.dishes[dishIndex].ingredients[ingredientIndex].ingredient.id = uuid();
          }

          for (const [choiceIndex] of dish.choices.entries()) {
            o.dishes[dishIndex].choices[choiceIndex].id = uuid();

            const choice = o.dishes[dishIndex].choices[choiceIndex];

            for (const [optionIndex] of choice.options.entries()) {
              o.dishes[dishIndex].choices[choiceIndex].options[optionIndex].option.id = uuid();
            }

            for (const [ingredientIndex] of choice.ingredients.entries()) {
              o.dishes[dishIndex].choices[choiceIndex].ingredients[ingredientIndex].ingredient.id =
                uuid();
            }
          }

          store.cart.push(dish);
        }
        store.modal.show("cart");
      }
    }
  };

  // DATA
  @action getHistory = async (page?: number) => {
    const c = this.store.customer.s.item;

    if (!c) return;

    this.s.page = page || this.s.page;

    try {
      this.update({
        error: "",
        loading: true,
      });

      const data = await this.store.api.customerOrders({
        restaurant_id: this.store.r.id,
        page: this.s.page,
      });

      this.update({
        items: data.items,
        count: data.count,
        error: "",
        loading: false,
      });
    } catch (e) {
      logger.captureException(e);
      this.update({
        items: [],
        count: 0,
        error: "true",
        loading: false,
      });
    }
  };
  @action setPage = (page: number) => {
    this.s.page = page;
    this.getHistory();
  };

  @action update = (data: Partial<OrderHistoryState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof OrderHistoryState];
        if (value !== undefined && this.s) {
          // @ts-ignore
          this.s[key as keyof OrderHistoryState] = value;
        }
      }
    }
  };

  // CHECKS
  orderInvalidCheck = (o: RestaurantOrderFullFragment) => {
    for (const d of o.dishes) {
      const invalid = this.orderDishInvalid(d);
      if (invalid) {
        return invalid;
      }
    }

    return "";
  };
  orderDishInvalid = (dish: OrderDishFullFragment): string => {
    if (!dish.dish_id) {
      return "missing_dish";
    }

    const current = RestaurantUtils.menu.findDish(this.store.menusAll, dish.dish_id);

    if (!current) {
      return "missing_dish";
    }

    const menuCheck = this.store.menuAvailabilityCheck(current.menu);

    if (menuCheck.isRestricted) {
      return "menu_unavailable";
    }

    if (current.dish.status) {
      return "dish_status";
    }

    const invalid =
      dish.type === "combo"
        ? this.orderDishComboInvalid(dish, current.dish)
        : this.orderDishStandardInvalid(dish, current.dish);

    if (!invalid) {
      // console.log(invalid, toJS(dish), current.dish);
    }

    return invalid;
  };
  orderDishStandardInvalid = (
    dish: OrderDishFullFragment | OrderDishChoiceFullFragment,
    currentDish: RestaurantMenuDishFullFragment & { surcharge_pct?: number },
    forCombo: boolean = false
  ) => {
    if (
      dish.price !==
      menuDish.price({
        dish: currentDish,
        service: this.store.order_config.s.config_service || null,
      })
    ) {
      return "price_change";
    }

    // CHECK NOTHING NEW ADDED AS IF SOMETHING IS REQUIRED, THE DISH GETS MESSED UP
    // ON FLIP SIDE BECAUSE THE DISH OPTION SETS ARE TRIMMED, ITS HARD TO DETECT A CHANGE
    /*
      if (dish.option_sets.length !== currentDish.option_sets.length) {
        console.log("OPTIONS_CHANGED", toJS(dish.option_sets), currentDish.option_sets);
        return "options_changed";
      }
    */
    /*
    if ((dish.ingredients || []).length !== currentDish.ingredients.length) {
      return "ingredients_changed";
    }
    */

    for (const ing of dish.ingredients) {
      const found = currentDish.ingredients.find((i) => i.id === ing.ingredient.ingredient_id);
      if (!found) {
        return "missing_ingredient";
      }
    }

    const optionSets = menuOptionSets.forDish({
      md: currentDish,
      r: {
        menus: this.store.menusAll,
        option_sets: this.store.rOptionSets,
      },
      platform: "online",
      service: this.store.order_config.s.config_service,
      odChoiceDishId: null,
      // surchargePct: currentDish.surcharge_pct,
    });

    for (const option of dish.options) {
      const found = optionSets.find((item) => {
        return item.id === option.option.option_set_id;
      });

      if (!found) {
        return "missing_option_set_1";
      }

      const existing = found.options.find((item) => item.id === option.option.option_id);

      if (!existing) {
        return "missing_option";
      }
      if (existing.price !== option.option.price) {
        return "option_price_change";
      }
    }

    return "";
  };
  orderDishComboInvalid = (
    dish: OrderDishFullFragment,
    currentDish: RestaurantMenuDishFullFragment
  ) => {
    const r = this.store.r;

    /*
    if (dish.price_type !== currentDish.price_type) {
      return "price_change_combo_1";
    }
    */

    if (
      dish.price !==
      menuDish.price({
        dish: currentDish,
        service: this.store.order_config.s.config_service || null,
      })
    ) {
      return "price_change_combo_2";
    }

    if (dish.choices.length !== currentDish.choices.length) {
      return "choices_change";
    }

    for (const choice of dish.choices) {
      const currentChoice = currentDish.choices.find((c) => c.id === choice.choice_id);

      if (!currentChoice) {
        return "missing_choice";
      }

      if (choice.dish_id) {
        const foundDishChoice = currentChoice.dishes.find((d) => d.dish_id === choice.dish_id);

        if (!foundDishChoice) {
          return "missing_choice_dish_1";
        }

        const current = RestaurantUtils.menu.findDish(this.store.menusAll, choice.dish_id);
        if (!current) {
          return "missing_choice_dish_2";
        }

        const invalid = this.orderDishStandardInvalid(choice, current.dish, true);
        if (invalid) {
          return invalid;
        }
      }
    }

    return "";
  };
}
