import * as React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { styled } from "@lib/components";
import { lighten } from "polished";
import { FaShoppingBag } from "react-icons/fa";
import { MainActionsRenderer } from "../../logic/main-actions";
import { formatCurrency } from "@lib/common";

interface Props {}

const Wrapper = styled("div")`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 0.14s all;
`;

const Button = styled("button")`
  width: 100%;
  color: ${({ theme }) => theme.colors.primary_text};
  background: ${({ theme }) => theme.colors.primary};
  &:hover {
    background: ${({ theme }) => lighten(0.06, theme.colors.primary)};
  }
`;

export const NavigationBottom = observer((props: Props) => {
  const { orderingDisabled, serviceBookingEnabled, serviceOrderingEnabled, modal, cart } =
    useStore();

  let startText = "Book Table / Order Online";
  if (serviceBookingEnabled && !serviceOrderingEnabled) {
    startText = "Book Table";
  } else if (!serviceBookingEnabled && serviceOrderingEnabled) {
    startText = "Order Online";
  }

  const baseButtonClass =
    "flex items-center cursor-pointer trans-200 px-6 py-3 shadow-xl font-semibold rounded text-lg";

  return (
    <Wrapper className="lg:hidden p-2">
      <MainActionsRenderer
        renderClosed={() => (
          <Button className={cn(baseButtonClass, "justify-center cursor-not-allowed")}>
            {orderingDisabled ? "Not Accepting Orders" : "Store Closed"}
          </Button>
        )}
        renderOpen={() => (
          <Button
            className={cn(baseButtonClass, "justify-between py-4")}
            onClick={() => modal.show("order-config")}>
            <FaShoppingBag className="mr-2 text-xl" />
            <span className="leading-none">{startText}</span>
            <span />
          </Button>
        )}
        renderCart={() => (
          <Button
            className={cn(baseButtonClass, "justify-between")}
            onClick={() => modal.show("cart")}>
            <div>
              {formatCurrency(cart.totalCart)} | {cart.itemsInCart}{" "}
              <span className="text-xs">items</span>
            </div>
            <div className="flex items-center text-sm ml-2">View Cart</div>
          </Button>
        )}
      />
    </Wrapper>
  );
});
