import * as Sentry from "@sentry/browser";
import { ExtraErrorData } from "@sentry/integrations";
import smoothscroll from "smoothscroll-polyfill";
import { onReactionError } from "mobx";
import { ApiError, cc, logger } from "@lib/common";
import Push from "push.js";

console.log("Sentry enabled: ", cc.production);

Sentry.init({
  enabled: cc.production,
  environment: cc.production ? "production" : "development",
  dsn: "https://78c472ed90a9415ca4b472a5fcf09b6e@sentry.io/1804902",
  attachStacktrace: true,
  ignoreErrors: [
    // Random error originating from sentry
    "undefined is not an object (evaluating 'window.webkit.messageHandlers.selectedDebugHandler.postMessage')",
    // Can safely ignore this error - https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications",
    // Random bullshit errors that are usually events or something ignorable
    "Non-Error exception captured",
    // The initial authentication check that fails always
    "Invalid Authentication",
    // Seems this happens if someone saves the HTML file and tries to open it
    "A history state object with URL 'file:///C:/'",
    // Random BS scripts
    "evaluating 'document.getElementsByTagName('video')[0].webkitExitFullScreen'",
    // Silly safari bug
    "from accessing a cross-origin frame. Protocols, domains, and ports must match",
    // Safari extension
    "undefined is not an object (evaluating 'p.manager')",
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    'Can"t find variable: ZiteReader',
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
  ],
  blacklistUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [new ExtraErrorData({ depth: 5 })],
  beforeSend: (event, hint) => {
    const exception = hint?.originalException;
    if (exception instanceof ApiError) {
      // Don't log errors for responses with 0 status code
      // They are usually not indicative of anything in particular
      // https://stackoverflow.com/questions/3825581/does-an-http-status-code-of-0-have-any-meaning
      if (exception.code === 0) {
        return null;
      }
      /*
      event.contexts = event.contexts || {};
      event.contexts.api_error = {
        url: exception.url,
        code: exception.code,
      };
      event.extra = {
        ...event.extra,
        response: exception.response,
      }
      */
    }
    return event;
  },
});

smoothscroll.polyfill();

mapboxgl.accessToken = cc.mapbox.apiKey;

Push.config({
  serviceWorker: "/serviceWorker.js",
});

onReactionError((e) => logger.captureException(e));

window.onunhandledrejection = (e: any) => {
  console.log("Unhandled Promise Rejection");
  logger.captureException(e);
};

declare global {
  interface Window {
    __INITIAL_THEME__: T.ThemeInterface;
    __INITIAL_STATE__: any;
    __fbInitialized?: boolean;
  }
}
