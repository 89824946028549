import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { VerticalMenu } from "./vertical-menu";
import { SideColumnWrapper } from "../components";
import { Select, styled } from "@lib/components";

interface Props {}
interface State {}

const SelectSVGArrow = `data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>`;

const SelectInput = styled(Select)`
  width: 100%;
  max-width: 210px;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  background-color: var(--gray-100);
  color: var(--gray-1000);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("${SelectSVGArrow}");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 24px;
  margin-right: 0px;
  border: none;
  border-radius: 5px;
  &:focus {
    border: none !important;
  }
  &::-ms-expand {
    display: none;
  }
`;

@inject("store")
@observer
class MenuNavClass extends MobxComponent<Props, State> {
  change = (selected: string[]) => {
    this.injected.store.filters.set(selected);
    this.injected.store.filters.scrollToCategory();
  };

  render() {
    const { store } = this.injected;
    const { filters, view } = store;

    /*
    const items = store.menusAll.map((m) => ({
      _id: m.id,
      name: m.display_name || m.name,
      nestedItems: m.categories.map((c) => ({
        _id: c.id,
        name: c.display_name || c.name,
      }))
    }));
    */

    const items = !store.menu
      ? []
      : [
          {
            _id: store.menu.id,
            name: store.menu.display_name || store.menu.name,
            nestedItems: store.menu.categories
              .filter((c) => {
                const { isRestricted } = store.menuAvailabilityCheck(c);
                return !(isRestricted && c.restrict_hide_unavailable);
              })
              .map((c) => ({
                _id: c.id,
                name: c.display_name || c.name,
              })),
          },
        ];

    return (
      <SideColumnWrapper topBarHeight={view.s.top_nav_height} className="pt-6">
        {store.menusAvailability.length > 1 && (
          <div className="px-3">
            <SelectInput
              dir="rtl"
              className=""
              value={store.filters.s.menu}
              onChange={(e) => {
                // untrusive.start();
                this.change([e.target.value]);
              }}
              options={store.menusAvailability.map((m) => ({
                label: m.isRestricted
                  ? `${m.menu.display_name || m.menu.name} (N/A)`
                  : m.menu.display_name || m.menu.name,
                value: m.menu.id,
                disabled: m.isRestricted,
              }))}
            />
          </div>
        )}

        <VerticalMenu
          items={items}
          selected={[filters.s.menu, filters.s.category]}
          onSelect={this.change}
        />
      </SideColumnWrapper>
    );
  }
}

export const MenuNav = MenuNavClass;
