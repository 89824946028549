import autobind from "autobind-decorator";
import { RootStore } from "../store";
import { observable, action, computed } from "mobx";
import {
  OrderDishFullFragment,
  RestaurantMenuCategoryFragment,
  RestaurantMenuDishFullFragment,
} from "@lib/types/graphql.d";
import {
  logger,
  RestaurantUtils,
  cloneDeepSafe,
  GN,
  centsToDecimal,
  orderDish,
  menuOptionSets,
  orderDishMut,
  menuDish,
  orderOptions,
} from "@lib/common";
import { v4 as uuid } from "uuid";

export interface DishState {
  category: RestaurantMenuCategoryFragment | null;
  menuDish: (RestaurantMenuDishFullFragment & { surcharge_pct?: number }) | null;
  orderDish: OrderDishFullFragment | null;
  disableDishNotes: boolean;
  optionSetErrors: { [key: string]: boolean | undefined };
  choiceErrors: { [key: string]: boolean | undefined };
  error: boolean;
  edit: number;
}

interface DishSetOpts {
  dish_id: string;
  category_id: string;
  os_id?: string; // used to select default options
  o_id?: string;
}

function defaultState(): DishState {
  return {
    category: null,
    menuDish: null,
    orderDish: null,
    disableDishNotes: false,
    optionSetErrors: {},
    choiceErrors: {},
    error: false,
    edit: -1,
  };
}

@autobind
export class DishStore {
  @observable s: DishState;

  store: RootStore;
  preset: DishSetOpts | null = null;

  constructor(store: RootStore, initialState?: DishState) {
    this.store = store;
    this.s = initialState ? observable(initialState) : observable(defaultState());
  }

  @computed get price() {
    if (!this.s.orderDish) return { price: 0, priceSingle: 0 };
    return orderDish.total(this.s.orderDish);
  }

  /*
  @computed get tags() {
    const dish = this.s.dish;
    if (!dish) return [];
    return this.store.r.dish_tags.filter((tag) => dish.tags.indexOf(tag.id) !== -1);
  }
  */

  @action errorsCheck() {
    if (!this.s.menuDish || !this.s.orderDish) return;

    this.errorsReset();

    const errors = orderDish.errors({
      md: this.s.menuDish,
      od: this.s.orderDish,
      r: {
        menus: this.store.menusAll,
        option_sets: this.store.rOptionSets,
      },
      platform: "online",
      service: this.store.order_config.s.config_service,
      surchargePct: this.s.menuDish.surcharge_pct,
    });

    this.s.choiceErrors = errors.choiceErrors;
    this.s.optionSetErrors = errors.optionErrors;
  }

  @action errorsReset() {
    this.s.choiceErrors = {};
    this.s.optionSetErrors = {};
  }

  @action toCart = () => {
    if (!this.s.menuDish || !this.s.orderDish) return;

    this.errorsCheck();

    const choiceErrored = Object.keys(this.s.choiceErrors).length > 0;
    const optionSetErrored = Object.keys(this.s.optionSetErrors).length > 0;

    if (choiceErrored || optionSetErrored) {
      console.log("ERROR");
      this.s.error = true;
      return;
    }

    this.s.error = false;

    const editIndex = this.s.edit;
    if (editIndex === -1) {
      const existingIndex = orderDish.existingIndex({
        md: this.s.menuDish,
        od: this.s.orderDish,
        cart: this.store.cart.dishes,
        prePopup: false,
      });
      if (existingIndex !== -1) {
        this.store.cart.dishes[existingIndex].quantity += this.s.orderDish.quantity;
      } else {
        this.store.cart.push(this.s.orderDish);
      }
    } else {
      this.store.cart.push(this.s.orderDish, editIndex);
    }

    if (editIndex !== -1 && this.store.view.s.screen_width < 1024) this.store.modal.toggle("cart");

    // this.store.modal.back();

    fbq("track", "AddToCart", {
      value: this.price.price,
      currency: "NZD",
      content_name: this.s.orderDish.name,
      content_category: this.s.category!.name,
      content_ids: [this.s.orderDish.dish_id!],
      content_type: "product",
    });

    this.s = defaultState();

    GN.add({
      type: "success",
      message: `<p>${editIndex !== -1 ? "Item edited" : "Item added to cart"}</p>`,
      duration: 2000,
    });
  };

  @action unset = () => {
    this.s = {
      category: null,
      menuDish: null,
      orderDish: null,
      disableDishNotes: false,
      optionSetErrors: {},
      choiceErrors: {},
      error: false,
      edit: -1,
    };
  };

  @action set = (opts: DishSetOpts) => {
    this.preset = null;

    const { dish_id, category_id, os_id, o_id } = opts;

    const { filters, menusAll } = this.store;
    const menu_id = filters.s.menu;

    // Find items
    const menu = menusAll.find((m) => m.id === menu_id)!;
    const category = menu.categories.find((c) => c.id === category_id)!;
    const dish = category.dishes.find((d) => d.id === dish_id)!;

    // Create order dish
    const od: OrderDishFullFragment = {
      id: uuid(),
      dish_id: dish.id,
      type: dish.type,
      name: dish.display_name || dish.name,
      print_name: dish.print_name,
      subtitle: dish.subtitle,
      notes: "",
      price: menuDish.price({ dish, service: this.store.order_config.s.config_service || null }),
      discount: 0,
      quantity: 1,
      position: this.store.cart.dishes.length,
      ingredients: [],
      options: [],
      choices: [],
    };

    if (dish.type !== "combo") {
      const optionSets = menuOptionSets.forDish({
        md: dish,
        r: {
          menus: this.store.menusAll,
          option_sets: this.store.rOptionSets,
        },
        platform: "online",
        service: this.store.order_config.s.config_service,
        odChoiceDishId: "",
        // surchargePct: dish.surcharge_pct || 0,
      });
      // Add selected primary option set
      if (os_id && o_id) {
        for (const option_set of optionSets) {
          if (option_set.id === os_id) {
            for (const option of option_set.options) {
              if (option.id === o_id) {
                const price = orderOptions.price({
                  od: od,
                  option: option,
                  service: this.store.order_config.s.config_service,
                  odChoiceId: null,
                  // @ts-ignore
                  surchargePct: dish.surcharge_pct,
                });
                od.options.push({
                  option: {
                    id: uuid(),
                    option_set_id: option_set.id,
                    option_id: option.id,
                    set_name: option_set.display_name || option_set.name,
                    name: option.name,
                    quantity: 1,
                    quantity_free: 0,
                    price: price,
                    position: 0,
                  },
                });
              }
            }
          }
        }
      }

      // Add default options
      for (const option_set of optionSets) {
        // Don't add default for those with selected primary option set
        if (option_set.id === os_id && o_id) continue;
        if (option_set.is_required && !option_set.is_multi && !option_set.is_quantity) {
          for (const option of option_set.options) {
            if (option.quantity === 1) {
              const price = orderOptions.price({
                od: od,
                option: option,
                service: this.store.order_config.s.config_service,
                odChoiceId: null,
                // @ts-ignore
                surchargePct: dish.surcharge_pct,
              });
              od.options.push({
                option: {
                  id: uuid(),
                  option_set_id: option_set.id,
                  option_id: option.id,
                  set_name: option_set.display_name || option_set.name,
                  name: option.name,
                  quantity: 1,
                  quantity_free: 0,
                  price: price,
                  position: 0,
                },
              });
            }
          }
        }
      }
    }

    this.s = {
      category: category,
      menuDish: dish,
      orderDish: od,
      disableDishNotes: menu.disable_dish_notes || false,
      optionSetErrors: {},
      choiceErrors: {},
      error: false,
      edit: -1,
    };

    if (dish.type === "combo") {
      for (const [i, choice] of dish.choices.entries()) {
        if (choice.dishes.length === 0) {
          // REMOVE CHOICES WITH NO DISHES TO ITS NAME
          this.s.menuDish!.choices.splice(i, 1);
        } else if (choice.dishes.length === 1) {
          // SET DEFAULT CHOICE
          this.setChoice(choice.id, choice.dishes[0].dish_id);
        }
      }
    }

    fbq("track", "ViewContent", {
      value: parseFloat(
        centsToDecimal(
          menuDish.price({ dish, service: this.store.order_config.s.config_service || null })
        )
      ),
      currency: "NZD",
      content_name: dish.name,
      content_category: category.name,
      content_ids: [dish.id],
      content_type: "product",
    });
  };

  setPreset = (opts: DishSetOpts) => {
    this.preset = opts;
  };

  togglePreset = () => {
    if (this.preset) {
      const find = RestaurantUtils.menu.findDish(this.store.menusAll, this.preset.dish_id);
      if (find) {
        const { menu, category, dish } = find;
        const menuCheck = this.store.menuAvailabilityCheck(menu);
        const categoryCheck = this.store.menuAvailabilityCheck(category);
        if (!menuCheck.isRestricted && !categoryCheck.isRestricted && dish.status !== "no_stock") {
          this.set(this.preset);
          return true;
        }
      }
    }
    return false;
  };

  @action setFromCart = (dish: OrderDishFullFragment, index: number) => {
    const find = RestaurantUtils.menu.findDish(this.store.menusAll, dish.dish_id!);
    if (find) {
      this.s = {
        category: find.category,
        menuDish: find.dish,
        orderDish: cloneDeepSafe(dish),
        disableDishNotes: find.menu.disable_dish_notes || false,
        optionSetErrors: {},
        choiceErrors: {},
        error: false,
        edit: index,
      };
    } else {
      logger.captureWarning(`Missing dish in menu setFromCart edit ${dish.dish_id}`);
    }
  };

  @action setChoice = (choiceId: string, choiceDishId: string) => {
    orderDishMut.comboChoiceSet({
      md: this.s.menuDish!,
      od: this.s.orderDish!,
      menus: this.store.menusAll,
      odChoiceId: choiceId,
      odChoiceDishId: choiceDishId,
      availabilityCheck: this.store.menuAvailabilityCheck,
      availabilityCallback: (availability) => {
        if (availability.reason === "age") {
          this.store.modal.show("over-18");
        } else {
          this.store.modal.show("menu-restrictions");
        }
      },
    });
  };

  @action update = (data: Partial<OrderDishFullFragment>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof OrderDishFullFragment];
        if (value !== undefined && this.s.orderDish) {
          // @ts-ignore
          this.s.orderDish[key] = value;
        }
      }
    }
  };
}
