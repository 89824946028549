import * as React from "react";
import { observer } from "mobx-react";
import { RestaurantOrderFullFragment, RestaurantStoreFragment } from "@lib/types/graphql";
import { styled } from "@lib/components";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import { cleanPhoneNumber } from "@lib/common";

type Props = {
  r: RestaurantStoreFragment;
  o: RestaurantOrderFullFragment;
};

const DriverIcon = styled.a`
  color: white;
  background: ${({ theme }) => theme.colors.primary};
  height: 32px;
  width: 32px;
  font-size: 14px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const OrderReceiptDriver = observer((props: Props) => {
  const { o } = props;
  const delivery = o.driver_deliveries.length > 0 ? o.driver_deliveries[0] : null;
  const driver = delivery?.driver;
  const phone = cleanPhoneNumber(driver?.phone || "");
  return (
    <div
      style={{ minHeight: `100px` }}
      className="px-4 border-t bg-gray-000 flex items-center mmd:rounded-br">
      {o.status === "complete" && (
        <div className="flex-grow py-4">
          <div className="text-center px-8">
            <p className="text-sm font-semibold text-gray-900 uppercase">Delivery Completed</p>
            <p className="text-md text-gray-800">Enjoy your food</p>
          </div>
        </div>
      )}
      {o.status !== "complete" && !driver && (
        <div className="flex-grow py-4">
          <div className="text-center px-8">
            <p className="text-sm font-semibold text-gray-900 uppercase">Delivery Tracking</p>
            <p className="text-md text-gray-800">
              Once your order has been collected
              <br />
              driver tracking will start
            </p>
          </div>
        </div>
      )}
      {o.status !== "complete" && !!driver && (
        <div className="py-4 w-full">
          <div className="flex items-center w-full">
            <div className="mr-4">
              <img className="h-12 w-12 bg-gray-100 rounded-full" src={driver.avatar!} />
            </div>
            <div className="mr-4">
              <p className="text-lg">
                {driver.first_name} {driver.last_name}
              </p>
            </div>
            <div className="flex-grow flex items-center justify-end">
              <DriverIcon className="mr-2" href={`tel:${phone}`}>
                <FaPhone />
              </DriverIcon>
              <DriverIcon href={`sms:${phone}`}>
                <FaEnvelope />
              </DriverIcon>
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
