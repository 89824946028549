import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { styled, withTheme, LinkTag, Modal, ModalContent } from "@lib/components";
import { formatCurrency, logger } from "@lib/common";

interface Props {}

const MapEmbed = styled("div")`
  height: 360px;
  overflow: hidden;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

@inject("store")
@observer
class MapModalClass extends MobxComponent<Props> {
  mb?: mapboxgl.Map;

  componentDidUpdate() {
    const { modal } = this.injected.store;
    if (modal.isVisible("map")) {
      this.draw();
    }
  }

  draw = () => {
    if (typeof window === "undefined") return;
    setTimeout(() => {
      try {
        const { theme } = this.injected;
        const { r, serviceDeliveryZones } = this.injected.store;
        const [lng, lat] = r.location!.address.coords.coordinates;
        const el = document.getElementById("map-embed");

        if (!el) return;

        this.mb = new mapboxgl.Map({
          container: el,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [lng, lat],
          zoom: r.website!.map_zoom || 12,
        });

        new mapboxgl.Marker({ color: theme.colors.primary }).setLngLat([lng, lat]).addTo(this.mb);

        if (serviceDeliveryZones.length > 0) {
          this.mb.on("load", () => {
            if (this.mb) {
              for (const zone of serviceDeliveryZones) {
                this.mb.addLayer({
                  id: zone.id,
                  type: "fill",
                  layout: {},
                  paint: {
                    "fill-color": "#292929",
                    "fill-opacity": 0.2,
                  },
                  source: {
                    type: "geojson",
                    data: {
                      type: "Feature",
                      properties: {},
                      geometry: zone.polygon /* || {
                        type: "Polygon",
                        coordinates: [zone.polygon.map((p) => [p.lng, p.lat])],
                      },*/,
                    },
                  },
                });

                this.mb.on("click", zone.id, (e) => {
                  if (this.mb) {
                    new mapboxgl.Popup()
                      .setLngLat(e.lngLat)
                      .setHTML(
                        `<div style="color: #333;"><p class="font-bold mb-1">${
                          zone.name
                        }</p><p>Delivery Fee: ${formatCurrency(zone.price || 0)}</p></div>`
                      )
                      .addTo(this.mb);
                  }
                });

                this.mb.on("mouseenter", zone.id, () => {
                  if (this.mb) {
                    this.mb.getCanvas().style.cursor = "pointer";
                  }
                });

                this.mb.on("mouseleave", zone.id, () => {
                  if (this.mb) {
                    this.mb.getCanvas().style.cursor = "";
                  }
                });
              }
            }
          });
        }
      } catch (e) {
        logger.captureException(e);
      }
    }, 500);
  };

  render() {
    const { r, modal } = this.injected.store;
    const [lng, lat] = r.location!.address.coords.coordinates;
    return (
      <Modal
        id="modal-map-embed"
        width="md"
        closeButton={true}
        active={modal.isVisible("map")}
        close={() => modal.hide("map")}>
        <ModalContent paddingtb={0} paddinglr={0}>
          <MapEmbed id="map-embed" />
        </ModalContent>
        <ModalContent paddingtb={25} paddinglr={25} className="text-center">
          <p>{r.location!.address.display}</p>
          <LinkTag
            className="inline-block mt-2"
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
            View In Google Maps
          </LinkTag>
        </ModalContent>
      </Modal>
    );
  }
}

/*
@inject("store")
@observer
class MapLeafletModalClass extends MobxComponent<Props> {
  componentDidUpdate() {
    const { modal } = this.injected.store;
    if (modal.isVisible("map")) {
      this.draw();
    }
  }

  draw = () => {
    if (typeof window === "undefined") return;
    setTimeout(() => {
      try {
        const { theme } = this.injected;
        const { r, serviceDeliveryZones } = this.injected.store;
        const [lng, lat] = r.location!.address.coords.coordinates;
        const el = document.getElementById("map-embed");

        if (!el) return;

        // @ts-ignore
        var map = L.map("map-embed").setView({ lat, lng }, r.website!.map_zoom || 12);

        // @ts-ignore
        L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          {
            attribution:
              'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            id: "mapbox/streets-v11",
            tileSize: 512,
            accessToken: cc.mapbox.apiKey,
          }
        ).addTo(map);

        // @ts-ignore
        L.marker({ lat, lng }).addTo(map);

        if (serviceDeliveryZones.length > 0) {
          map.on("load", () => {
            if (map) {
              for (const zone of serviceDeliveryZones) {
                // @ts-ignore
                L.polygon([zone.polygon.map((p: { lng: any; lat: any }) => [p.lng, p.lat])], {
                  fillColor: "#292929",
                  opacity: 0.2,
                }).addTo(map);

                map.on("click", (e) => {
                  if (map) {
                    // @ts-ignore
                    L.popup()
                      // @ts-ignore
                      .setLatLng(e.latlng)
                      .setContent(
                        `<div style="color: #333;"><p class="font-bold mb-1">${
                          zone.name
                        }</p><p>Delivery Fee: ${formatCurrency(zone.price || 0)}</p></div>`
                      )
                      .openOn(map);
                  }
                });

                map.on("mouseenter", () => {
                  if (map) {
                    // @ts-ignore
                    L.DomUtil.addClass(map._container, "pointer-cursor-enabled");
                  }
                });

                map.on("mouseleave", () => {
                  if (map) {
                    // @ts-ignore
                    L.DomUtil.removeClass(map._container, "pointer-cursor-enabled");
                  }
                });
              }
            }
          });
        }
      } catch (e) {
        logger.captureException(e);
      }
    }, 500);
  };

  render() {
    const { r, modal } = this.injected.store;
    const [lng, lat] = r.location!.address.coords.coordinates;
    return (
      <Modal
        id="modal-map-embed"
        width="md"
        closeButton={true}
        active={modal.isVisible("map")}
        close={() => modal.hide("map")}>
        <ModalContent paddingtb={0} paddinglr={0}>
          <MapEmbed id="map-embed" />
        </ModalContent>
        <ModalContent paddingtb={25} paddinglr={25} className="text-center">
          <p>{r.location!.address.display}</p>
          <LinkTag
            className="inline-block mt-2"
            target="_blank"
            href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}>
            View In Google Maps
          </LinkTag>
        </ModalContent>
      </Modal>
    );
  }
}
*/

export const MapModal = withTheme(MapModalClass);
/**
 * @deprecated Use MapLeafletModal instead.
 */
// export const MapLeafletModal = withTheme(MapLeafletModalClass);
