import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Button, RotateLoader } from "@lib/components";
import { withTheme } from "styled-components";
import { RestaurantPaymentTypeEnumEnum } from "@lib/types/graphql";
import { useETA } from "../../logic/eta/useETA";
import { formatTime } from "@lib/common";
import cn from "classnames";

interface Props {}

const DeliveryETA = observer(() => {
  const { eta } = useETA();
  if (eta.loading) {
    return null;
  }
  if (!eta.data || eta.error) {
    return null;
  }
  if (eta.data.error || !eta.data.delivery_at) {
    return null;
  }
  let etaText = (
    <span>
      Estimated delivery between <u>{formatTime(eta.data.delivery_at)}</u> to{" "}
      <u>{formatTime(eta.data.delivery_at + 1000 * 60 * 10)}</u>
    </span>
  );
  return (
    <div className="text-center">
      <p className="text-sm">{etaText}</p>
    </div>
  );
});

@inject("store")
@observer
class CheckoutButtonClass extends MobxComponent<Props, {}> {
  interval: any;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.forceUpdate();
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { store, theme } = this.injected;
    const checkout = store.checkout.s;
    const paymentMethod = store.order_config.s.payment_type;
    const continueMethods: RestaurantPaymentTypeEnumEnum[] = ["poli", "paymark_eftpos", "windcave"];
    let serviceClosesIn = null;
    let serviceClosed = false;
    const showDeliveryEta =
      store.managedDeliveryActive && store.order_config.s.config_due === "now";
    if (store.order_config.s.config_due === "now") {
      const ci = store.serviceClosesIn();
      serviceClosesIn = ci ? ci.minutes : null;
      serviceClosed = !store.serviceOpen();
    }
    return (
      <div id="checkout-button">
        {!serviceClosed && (
          <div className="mb-4">
            {serviceClosesIn !== null && (
              <p className={cn("text-center text-md text-error", showDeliveryEta && "mb-1")}>
                Closing soon, orders must be placed within {serviceClosesIn} minutes
              </p>
            )}
            {showDeliveryEta && <DeliveryETA />}
          </div>
        )}
        {serviceClosed && (
          <p className="text-center text-md text-error mb-4">
            Sorry, this service has closed and orders are not being accepted
          </p>
        )}
        <Button full={true} color="primary" type="submit" disabled={serviceClosed}>
          {!checkout.loading && (
            <span>
              {continueMethods.indexOf(paymentMethod as RestaurantPaymentTypeEnumEnum) !== -1
                ? "Continue"
                : "Place Order"}
            </span>
          )}
          {checkout.loading && <RotateLoader size={2} color={theme.colors.primary_text} />}
        </Button>
      </div>
    );
  }
}

// @ts-ignore
export const CheckoutButton = withTheme(CheckoutButtonClass);
