import * as React from "react";
import { shadeAlternate, styled, css } from "@lib/components";

interface ChoiceProps extends React.HTMLAttributes<HTMLDivElement> {
  active: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export const ChoiceComponent = styled("div")<ChoiceProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 0;
  padding: 10px 5px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  width: 100%;
  max-width: 120px;
  margin: 0 auto;
  ${({ active, theme }) =>
    !active
      ? ""
      : css`
          background: ${shadeAlternate(0.1, theme.colors.background)};
        `}
  &:hover {
    background: ${({ theme }) => shadeAlternate(0.1, theme.colors.background)};
  }
  ${({ disabled, theme }) =>
    !disabled
      ? ""
      : css`
          cursor: not-allowed;
          &:hover {
            background: transparent;
          }
        `}
  ${({ hidden }) =>
    !hidden
      ? ""
      : css`
          display: none;
        `}
`;

export const Choice = (props: ChoiceProps) => {
  if (props.hidden) {
    return null;
  }
  return <ChoiceComponent {...props} />;
};

export const ChoiceIcon = styled("div")`
  margin-bottom: 10px;
  font-size: 26px;
  svg {
    min-width: 36px;
  }
`;
