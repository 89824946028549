import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { ImageWrapper, Image } from "./components";
import { DishModalIngredients } from "./sections/ingredients";
import { DishModalOptionSet } from "./sections/option-sets";
import { DishModalChoice } from "./sections/choice";
import { DishModalNotes } from "./sections/notes";
import { HitBox, Button, Modal, ModalContent } from "@lib/components";
import { FaExclamationCircle } from "react-icons/fa";
import { CoreUtils, formatCurrency, menuDish } from "@lib/common";

interface Props {}
interface State {}

@inject("store")
@observer
class DishModalClass extends MobxComponent<Props, State> {
  image = () => {
    const { store } = this.injected;
    const dish = store.dish.s.menuDish!;
    const image = !dish.image
      ? null
      : CoreUtils.image.uc(dish.image, {
          w: 420,
          h: 240,
          fm: "jpg",
        });
    if (!image) return null;
    return (
      <ImageWrapper>
        <Image url={image} />
      </ImageWrapper>
    );
  };

  content = () => {
    const { store } = this.injected;
    const md = store.dish.s.menuDish!;
    const od = store.dish.s.orderDish!;
    const category = store.dish.s.category!;
    const { price } = store.dish.price;
    const priceSuffix = !md.option_set_primary && md.type !== "combo" && md.price_suffix;

    return (
      <ModalContent paddingtb={md.image ? 25 : 35} paddinglr={25}>
        <div className="flex justify-between">
          <div>
            <h4 className="">{md.display_name || md.name}</h4>
            <p className="-mt-1 text-sm font-semibold italic">
              {category.display_name || category.name}
            </p>
          </div>
          <div className="text-right">
            <h4 className="">{formatCurrency(price)}</h4>
            {priceSuffix && (
              <p className="-mt-1 text-xs">
                {od.quantity > 1 &&
                  formatCurrency(
                    menuDish.price({
                      dish: md,
                      service: store.order_config.s.config_service || null,
                    })
                  )}{" "}
                {md.price_suffix}
              </p>
            )}
          </div>
        </div>

        {md.subtitle && (
          <p className="font-semibold" style={{ marginTop: "-3px", marginBottom: "3px" }}>
            {md.subtitle}
          </p>
        )}

        {md.description && <p className="mt-1">{md.description}</p>}

        {/*tags.length > 0 && (
          <div className="flex flex-wrap">
            {tags.map((tag, i) => (
              <div key={i} className="mr-1 mt-2"><DishTag {...tag}/></div>
            ))}
          </div>
        )*/}
      </ModalContent>
    );
  };

  controls = () => {
    const { store } = this.injected;
    const orderDish = store.dish.s.orderDish!;
    const isFreeDish = store.cart.added_free_dish === orderDish.id;
    const isEdit = store.dish.s.edit !== -1;
    return (
      <ModalContent
        paddingtb={15}
        paddinglr={25}
        className="flex flex-row justify-between content-center items-center">
        {!isFreeDish ? (
          <HitBox
            up={() => store.dish.update({ quantity: orderDish.quantity + 1 })}
            down={() =>
              orderDish.quantity > 1
                ? store.dish.update({ quantity: orderDish.quantity - 1 })
                : null
            }
            value={orderDish.quantity}
          />
        ) : (
          <div />
        )}
        <Button
          color="primary-inverse"
          className="select-none !text-md"
          onClick={store.dish.toCart}>
          {isEdit ? "Update Item" : "Add To Cart"}
        </Button>
      </ModalContent>
    );
  };

  render() {
    const { store } = this.injected;
    const { menuDish, category, error } = store.dish.s;
    return (
      <Modal
        id="modal-dish"
        width={420}
        closeButton={true}
        active={!!menuDish && !!category}
        preventClose={store.modal.s.active === "order-expired-warning"}
        close={() => store.dish.unset()}>
        {!!menuDish && !!category && (
          <div>
            {this.image()}

            {this.content()}

            {menuDish.type === "combo" &&
              menuDish.choices.map((choice) => (
                <DishModalChoice key={choice.id} choiceId={choice.id} />
              ))}

            {menuDish.type === "standard" && menuDish.option_sets.length > 0 && (
              <DishModalOptionSet selectedChoiceId="" />
            )}

            {menuDish.type === "standard" && menuDish.ingredients.length > 0 && (
              <DishModalIngredients selectedChoiceId="" />
            )}

            <DishModalNotes />

            {error && (
              <ModalContent
                className="bg-error text-white text-md flex items-center"
                paddinglr={25}
                paddingtb={12}>
                <FaExclamationCircle className="mr-2" />
                <span>Please correct any options marked in red</span>
              </ModalContent>
            )}

            {this.controls()}
          </div>
        )}
      </Modal>
    );
  }
}

export const DishModal = DishModalClass;
