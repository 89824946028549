import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { styled } from "@lib/components";
import { MenuNav } from "./nav";
import { MenusList } from "./list";
import { MenusCart } from "./cart";

interface Props {}

const Wrapper = styled("div")`
  position: relative;
  padding: 0 0 0 0;
`;

const Content = styled("div")`
  position: relative;
  z-index: 1;
  max-width: ${({ theme }) => theme.breakpoints.xxl}px;
  width: 100%;
  margin: 0 auto;
`;

@inject("store")
@observer
class MenusClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { view } = store;
    const sideColumnClass = "hidden lg:block sticky";
    const sideColumnStyle = { top: `${view.s.top_nav_height}px` };
    return (
      <Wrapper id="dish-grid" className="bg-gray-000">
        <Content className="flex items-start">
          <div className={cn(sideColumnClass, "w-1/5 xl:w-1/4 text-right")} style={sideColumnStyle}>
            <MenuNav />
          </div>
          <div className="flex-1 lg:border-l lg:border-r">
            <MenusList />
          </div>
          <div className={cn(sideColumnClass, "w-1/3 xl:w-1/4")} style={sideColumnStyle}>
            <MenusCart />
          </div>
        </Content>
      </Wrapper>
    );
  }
}

export const Menus = MenusClass;
