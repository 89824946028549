import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import {
  ConnectionIndicatorCircle,
  RotateLoader,
  OrderNumber,
  OrderTimeline,
  Modal,
  ModalContent,
  DetailRowList,
} from "@lib/components";
import { ApiError, formatDateTime, formatOrderStatus, logger } from "@lib/common";
import Mqtt from "mqtt";
import { MQTT_ENDPOINT, MQTT_PASSWORD, MQTT_USERNAME, mqttTopics } from "../../../config";

interface Props {}

const errors: { [key: string]: string } = {
  not_found: "Order not found, try refresh the page",
  generic: "Something went wrong, refresh the page or contact us",
};

const BookingReceiptModalClass = observer((props: Props) => {
  const store = useStore();
  const { r } = store;
  const { routeId } = store.booking;
  const { loading, error, item } = store.booking.s;

  const [connected, setConnected] = React.useState(false);

  function handleNewMessage(topic: string, message: Buffer) {
    if (!store.booking.s.item) return;
    let data: T.MQTT.PublicBookingUpdateMessage;
    try {
      data = JSON.parse(message.toString());
      if (data.event === "status") {
        store.booking.updateItem(data);
        store.sendPushNotification(`Booking ${store.booking.s.item.number} Updated`, {
          body: `Your booking status has been updated to ${formatOrderStatus(data.status)}`,
          timeout: 30000,
          requireInteraction: true,
        });
      }
    } catch (err) {
      logger.captureException(err);
      return;
    }
  }

  React.useEffect(() => {
    let interval: any;
    let mqtt: Mqtt.MqttClient;

    (async () => {
      try {
        if (!routeId) {
          return;
        }

        store.initPushNotification();

        if (!item || item.id !== routeId) {
          store.booking.update({ loading: true });
          const result = await store.api.booking_find({ id: routeId });
          store.booking.update({
            loading: false,
            error: "",
            item: result.booking,
          });
        }

        mqtt = Mqtt.connect(MQTT_ENDPOINT, {
          clean: false,
          keepalive: 15,
          username: MQTT_USERNAME,
          password: MQTT_PASSWORD,
          clientId: `${routeId}-${Date.now()}`,
          reconnectPeriod: 5000, // attempt reconnection every 5s,
        });

        console.log("[MQTT] Connect");

        mqtt.on("error", (err) => {
          logger.captureException(err);
          console.log(`[MQTT] Error`, err);
        });
        mqtt.on("close", () => {
          console.log(`[MQTT] Disconnected from server `);
        });
        mqtt.on("connect", () => {
          console.log(`[MQTT] Connected successfully `);
        });
        mqtt.on("message", handleNewMessage);
        mqtt.subscribe(mqttTopics.bookingUpdates(routeId), { qos: 1 }, (err, granted) => {
          if (err) {
            console.log("[MQTT] Subscribe error", err);
          } else {
            console.log(`[MQTT] Subscribed to topic`, granted);
          }
        });

        interval = setInterval(() => {
          setConnected(mqtt?.connected || false);
        }, 1000);
      } catch (e) {
        if (e instanceof ApiError && e.code === 404) {
          store.booking.update({
            loading: false,
            error: "not_found",
            item: null,
          });
        } else {
          logger.captureException(e);
          store.booking.update({
            loading: false,
            error: "generic",
            item: null,
          });
        }
      }
    })();

    return () => {
      clearInterval(interval);
      mqtt?.end();
    };
  }, [routeId]);

  const active = !!routeId;

  return (
    <Modal
      id="booking-receipt-modal"
      width={420}
      closeButton={true}
      active={active}
      close={() => store.router.push("/")}>
      {loading && (
        <ModalContent paddinglr={20} paddingtb={100} className="rounded-sm">
          <RotateLoader size={3} />
        </ModalContent>
      )}

      {!loading && error && (
        <ModalContent paddinglr={20} paddingtb={50} className="rounded-sm text-center">
          <p className="text-error">{errors[error]}</p>
        </ModalContent>
      )}

      {!loading && !error && item && (
        <div>
          <ModalContent
            paddinglr={20}
            paddingtb={25}
            className="flex justify-between content-center items-center !border-0">
            <OrderNumber>#{item.number}</OrderNumber>
            <div className="text-right mt-1">
              <p className="">Booking Receipt</p>
              <p className="text-sm">
                {connected ? "Real-time updates" : "Real-time updates disconnected"}
                <ConnectionIndicatorCircle
                  className="ml-1"
                  status={connected ? "connected" : "disconnected"}
                />
              </p>
            </div>
          </ModalContent>

          <OrderTimeline status={item.status} isDelivery={false} isBooking={true} />

          <ModalContent paddinglr={20} paddingtb={25}>
            <DetailRowList
              className="text-md"
              items={[
                {
                  l: "Placed",
                  v: formatDateTime(item.created),
                },
                {
                  l: "Booking For",
                  v: formatDateTime(item.config_timestamp),
                },
                {
                  l: "No. Of People",
                  v: item.config_num_people,
                },
                {
                  l: "Last Updated",
                  h: !!item.updated,
                  v: item.updated ? formatDateTime(item.updated) : "",
                },
                {
                  l: "Notes",
                  v: item.notes,
                },
                {
                  l: "Name",
                  v: item.customer_name,
                },
                {
                  l: "E-mail",
                  v: item.customer_email,
                },
                {
                  l: "Phone",
                  v: item.customer_phone,
                },
                {
                  l: "Store Address",
                  v: r.location!.address.display,
                },
              ]}
            />
          </ModalContent>
        </div>
      )}
    </Modal>
  );
});

export const BookingReceiptModal = BookingReceiptModalClass;
