import * as React from "react";
import { observer, inject } from "mobx-react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaYoutubeSquare,
  FaPinterestSquare,
  FaTripadvisor,
} from "react-icons/fa";
import { cc, CoreUtils } from "@lib/common";
import { MobxComponent } from "../../../mobx/component";
import { Wrapper, SocialIcons, Divider } from "./components";

interface Props {}

@inject("store")
@observer
class FooterClass extends MobxComponent<Props> {
  toggleTermsModal = () => {
    this.injected.store.modal.toggle("terms");
  };

  render() {
    const r = this.injected.store.r;
    const w = r.website!;
    const isWebView = this.injected.store.view.s.is_web_view;
    const isPwa = this.injected.store.view.s.is_pwa;
    const termsActive = true;

    const oneSocialLinkActive =
      !!w.social_link_facebook ||
      !!w.social_link_instagram ||
      !!w.social_link_twitter ||
      !!w.social_link_youtube ||
      !!w.social_link_trip_advisor ||
      !!w.social_link_pinterest;

    const powered_by_style = {
      color: `${cc.color}`,
    };

    const alcoholLicense = CoreUtils.image.s3(r.setting!.alcohol_license_off_site_image);

    return (
      <Wrapper
        color={w.footer_color_text}
        background={w.footer_color_background}
        isWebView={isWebView}
        className="px-4 py-12 pb-20 lg:pb-12">
        <div className="text-center">
          {oneSocialLinkActive && !isWebView && !isPwa && (
            <SocialIcons>
              {w.social_link_facebook && (
                <a href={w.social_link_facebook} target="_blank">
                  <FaFacebookSquare />
                </a>
              )}
              {w.social_link_instagram && (
                <a href={w.social_link_instagram} target="_blank">
                  <FaInstagram />
                </a>
              )}
              {w.social_link_twitter && (
                <a href={w.social_link_twitter} target="_blank">
                  <FaTwitterSquare />
                </a>
              )}
              {w.social_link_youtube && (
                <a href={w.social_link_youtube} target="_blank">
                  <FaYoutubeSquare />
                </a>
              )}
              {w.social_link_pinterest && (
                <a href={w.social_link_pinterest} target="_blank">
                  <FaPinterestSquare />
                </a>
              )}
              {w.social_link_trip_advisor && (
                <a href={w.social_link_trip_advisor} target="_blank">
                  <FaTripadvisor />
                </a>
              )}
            </SocialIcons>
          )}
          {oneSocialLinkActive && !isWebView && <Divider />}
          {(!isWebView || !isPwa || termsActive) && (
            <div id="powered-by-attribution">
              <p className="text-md">
                {!isWebView && !isPwa && (
                  <span>
                    Powered By{" "}
                    <a
                      id="powered-by-attribution-link"
                      style={powered_by_style}
                      href="https://www.booknorder.co.nz"
                      target="_blank"
                      className="underline">
                      {cc.name}
                    </a>
                  </span>
                )}
                {!isWebView && termsActive && " - "}
                {termsActive && (
                  <a className="underline !text-white" onClick={this.toggleTermsModal}>
                    Terms & Conditions
                  </a>
                )}
                {alcoholLicense && " - "}
                {alcoholLicense && (
                  <a className="underline !text-white" href={alcoholLicense} target="_blank">
                    Liquor License
                  </a>
                )}
              </p>
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

export const Footer = FooterClass;
