import { ModalContent, RotateLoader } from "@lib/components";
import * as React from "react";

export function OrderReceiptLoading() {
  return (
    <ModalContent paddinglr={20} paddingtb={100} className="rounded-sm">
      <RotateLoader size={3} />
    </ModalContent>
  );
}

export function OrderReceiptError(props: { error: string }) {
  return (
    <ModalContent paddinglr={20} paddingtb={50} className="rounded-sm text-center">
      <p className="text-error">{props.error}</p>
    </ModalContent>
  );
}
