import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, Button } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";

interface Props {}

@inject("store")
@observer
class LogoutWarningModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { modal } = store;
    return (
      <Modal
        id="modal-logout-warning"
        width="sm"
        closeButton={true}
        active={modal.isVisible("logout-warning")}
        close={() => modal.hide("logout-warning")}>
        <ModalContent>
          <p className="text-error text-md">Warning</p>
          <h4>Logout</h4>
        </ModalContent>

        <ModalContent>
          <p className="">Logging out will remove any items that have been added to your cart</p>
        </ModalContent>

        <ModalContent className="text-right" paddingtb={15}>
          <Button className="mr-2" onClick={() => modal.hide("logout-warning")}>
            <p>Cancel</p>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              modal.hide("logout-warning");
              store.customer.logout();
            }}>
            <p>Proceed</p>
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const LogoutWarningModal = LogoutWarningModalClass;
