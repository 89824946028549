import * as React from "react";
import { css } from "@lib/components";

export const ContentCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
  > * {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;
