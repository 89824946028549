import * as React from "react";
import { observer, inject } from "mobx-react";
import { MenusDropdown } from "./dropdown";
import { MenusTabs } from "./tabs";
import { MobxComponent } from "../../../../mobx/component";
import { styled, withTheme, DotsLoader, shadeAlternate } from "@lib/components";

const Wrapper = styled("div")`
  border-top: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.colors.background)};
`;

const Content = styled("div")`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
`;

@inject("store")
@observer
class NavigationMenusClass extends MobxComponent<{}, { mounted: boolean }> {
  constructor(props: {}) {
    super(props);
    this.state = {
      mounted: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state) return null;
    const { theme } = this.injected;
    return (
      <Wrapper className="lg:hidden">
        {!this.state.mounted && (
          <DotsLoader
            size={10}
            style={{ height: "43px" }}
            className="flex justify-center items-center"
            color={theme.colors.text}
          />
        )}
        {this.state.mounted && (
          <div>
            <MenusDropdown className="flex sm:hidden" />
            <Content>
              <MenusDropdown className="hidden sm:flex" />
              <MenusTabs />
            </Content>
          </div>
        )}
      </Wrapper>
    );
  }
}

export const NavigationMenus = withTheme(NavigationMenusClass);
