import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { OrderItems } from "@lib/components";

type Props = {};

export const OrderCartItems = observer((props: Props) => {
  const { cart } = useStore();
  return (
    <OrderItems
      items={cart.dishes}
      detailComboChoiceNames={false}
      noStockDishes={cart.noStock.dishes}
      noStockOptions={cart.noStock.options}
      itemContent={(dish, i) => (
        <div className="mt-2 text-sm">
          <a onClick={() => cart.edit(i)} className="!no-underline primary-text inline-block mr-2">
            Edit
          </a>
          <a onClick={() => cart.remove(i)} className="!no-underline primary-text inline-block">
            Remove
          </a>
        </div>
      )}
    />
  );
});
