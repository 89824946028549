import { hot } from "react-hot-loader/root";
import * as React from "react";
import * as Sentry from "@sentry/browser";
import { inject, observer } from "mobx-react";
import { Navigation } from "./views/nav";
import { NavigationBottom } from "./views/nav-bottom";
import { Header } from "./views/header";
import { Info } from "./views/info";
import { Footer } from "./views/footer";
import { OrderConfigModal } from "./modals/order-config";
import { DishModal } from "./modals/dish";
import { CartModal } from "./modals/cart";
import { CheckoutModal } from "./modals/checkout";
import { ReceiptModal } from "./modals/order-receipt";
import { BookingReceiptModal } from "./modals/booking-receipt";
import { AuthModal } from "./modals/auth";
import { CustomerModal } from "./modals/customer";
import { OrderHistoryModal } from "./modals/order-history";
import { MenuRestrictionsModal } from "./modals/prompts/menu-restrictions";
import { OrderRestartModal } from "./modals/prompts/order-restart";
import { LogoutWarningModal } from "./modals/prompts/logout-warning";
import { OrderExpiredWarning } from "./modals/prompts/order-expired-warning";
import { Over18Modal } from "./modals/prompts/over-18";
import { MapModal } from "./modals/map";
import { TermsModal } from "./modals/terms";
import { ResellerPortalBackModal } from "./modals/prompts/reseller-portal-back";
import { CustomPaymentModal } from "./modals/custom-payment";
import { CoverLoader } from "./components/CoverLoader";
import { MobxComponent } from "../mobx/component";
import { Menus } from "./views/menus";
import { NavigationAlert } from "./views/nav/alert";
import { PaymentPaymarkEftposModal } from "./modals/payments/paymark-eftpos";
import { ReceiptKioskModal } from "./modals/order-receipt-kiosk";
import { PWAInstall } from "@lib/components";
import { companyPWAIsEnabled } from "@lib/common";

interface Props {}
interface State {}

@inject("store")
@observer
class AppClass extends MobxComponent<Props, State> {
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key as keyof React.ErrorInfo]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { store } = this.injected;
    const { isLoggedIn } = store.customer;
    const { logged_in_only } = store.r.setting!;
    const blur = logged_in_only && !isLoggedIn;
    const isPaymentForm = store.router.s.path === "/online-payment";

    if (isPaymentForm) {
      return (
        <main>
          <CustomPaymentModal />
        </main>
      );
    }

    return (
      <main>
        <NavigationAlert />
        <Navigation />
        <NavigationBottom />
        <div
          id="scroll-root"
          style={
            !blur
              ? undefined
              : {
                  filter: "blur(5px)",
                  transform: "scale(1.01)",
                }
          }>
          <Header />
          <Menus />
          <Info />
          <Footer />
        </div>
        <OrderConfigModal />
        <OrderRestartModal />
        <CartModal />
        <CheckoutModal />
        <PaymentPaymarkEftposModal />
        <ReceiptModal />
        <ReceiptKioskModal />
        <BookingReceiptModal />
        <DishModal />
        <AuthModal />
        <CustomerModal />
        <OrderHistoryModal />
        <MenuRestrictionsModal />
        <LogoutWarningModal />
        <Over18Modal />
        <OrderExpiredWarning />
        <MapModal />
        <ResellerPortalBackModal />
        <TermsModal />
        <CoverLoader />
        <PWAInstall
          isPwa={store.view.s.is_pwa}
          enabled={companyPWAIsEnabled(store.r.website?.pwa)}
        />
      </main>
    );
  }
}

export const App = hot(AppClass);
