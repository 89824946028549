import * as React from "react";
import autobind from "autobind-decorator";
import { observable, action, computed } from "mobx";
import { logger, GN, ApiError } from "@lib/common";
import { RootStore } from "../store";
import { Social } from "../../libs/social";
import { CustomerProfileFragment } from "@lib/types/graphql";

export interface CustomerState {
  item: CustomerProfileFragment | null;
  fb_loading: boolean;
  fb_error: string;
}

@autobind
export class CustomerStore {
  store: RootStore;
  authRefreshTimer: any;
  @observable s: CustomerState;

  constructor(store: RootStore, initialState?: CustomerState) {
    this.store = store;

    this.s = initialState || {
      item: null,
      fb_error: "",
      fb_loading: false,
    };

    if (typeof window !== "undefined" && !this.s.item) {
      this.loginRefresh(false);
    }
  }

  @action login = (c: CustomerProfileFragment, isRefresh = false) => {
    // Set customer
    this.s.item = c;

    this.store.order_config.update({
      customer_name: c.name || "",
      customer_email: c.email || "",
      customer_phone: c.phone || "",
    });

    // Update checkout
    if (!isRefresh) {
      // Set auth timers
      clearInterval(this.authRefreshTimer);
      this.authRefreshTimer = setInterval(() => {
        this.loginRefresh();
      }, 1000 * 60 * 30); // 30 minutes

      // Update modal and route
      this.store.modal.back("auth");
      const path = this.store.router.s.path;
      if (["/login", "/register"].indexOf(path) !== -1) {
        this.store.router.push("/");
      }

      // Notify customer
      GN.add({
        type: "success",
        message: `<p>Successfully logged in</p>`,
        duration: 2000,
      });

      this.store.order_history.getHistory(1);
    }

    // this.store.cart.check_promo();
    // this.store.order_history.update({ page: 1 });
    // this.store.order_history.get(1);
  };
  @action loginRefresh = (isRefresh = true) => {
    this.store.api
      .customerLoginRefresh({})
      .then(({ customer }) => this.login(customer, isRefresh))
      .catch((e) => {
        if (e instanceof ApiError && e.code === 401) {
          this.logout();
        } else {
          logger.captureException(e);
        }
      });
  };
  @action logout = async () => {
    if (!this.s.item) return;

    try {
      await this.store.api.customerLogout();
    } catch (e) {
      GN.add({
        type: "error",
        message: `<p>Error logging out, try again</p>`,
        duration: 5000,
      });
      return;
    }

    try {
      this.s.item = null;
      clearInterval(this.authRefreshTimer);
      this.store.order_config.update({
        customer_name: "",
        customer_email: "",
        customer_phone: "",
      });
      this.store.order_history.update({ items: [], count: 0 });
      this.store.cart.clear();
    } catch (e) {
      logger.captureException("logout error", e);
    } finally {
      this.s.item = null;
      GN.add({
        type: "success",
        message: `<p>Logged out</p>`,
        duration: 3000,
      });
    }
  };

  loginFacebook = async () => {
    try {
      this.update({
        fb_loading: true,
        fb_error: "",
      });

      let res = await Social.fb.getLoginStatus();

      if (res.status !== "connected") {
        res = await Social.fb.login();
      }

      if (res.status !== "connected") {
        this.update({
          fb_loading: true,
          fb_error: "Could not login via Facebook",
        });
        return;
      }

      const { accessToken, userID } = res.authResponse;

      const { customer } = await this.store.api.customerLoginFacebook({
        uid: userID,
        token: accessToken,
      });

      this.login(customer);

      this.update({
        fb_loading: true,
        fb_error: "",
      });
    } catch (e) {
      if (typeof e === "string" || e.error !== "idpiframe_initialization_failed") {
        logger.captureException(e);
      }
      this.update({
        fb_loading: true,
        fb_error: "Could not login via Facebook",
      });
    }
  };

  // CUSTOMER
  @computed get displayName() {
    const c = this.s.item;
    if (!c) return "";
    return c.name || c.email;
  }
  @computed get isLoggedIn() {
    return !!this.s.item?.id;
  }

  @action update(data: Partial<CustomerState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CustomerState];
        if (value !== undefined && this.s) {
          // @ts-ignore
          this.s[key as keyof CustomerState] = value;
        }
      }
    }
  }
  @action updateItem(data: Partial<CustomerProfileFragment>) {
    if (!this.s.item) return;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CustomerProfileFragment];
        if (value !== undefined && this.s && this.s.item) {
          // @ts-ignore
          this.s.item[key as keyof CustomerProfileFragment] = value;
        }
      }
    }
  }
}
