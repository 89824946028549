import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import { FaHandPointDown, FaClock } from "react-icons/fa";
import { Choice, ChoiceIcon } from "../components";
import { MobxComponent } from "../../../../mobx/component";
import { formatServiceType } from "@lib/common";
import { RestaurantServiceTypeEnumEnum } from "@lib/types/graphql";

interface Props {}

@inject("store")
@observer
class OrderConfigDueClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { service, order_config } = store;
    const open = this.injected.store.serviceOpen();
    const nowAvailable = service.order_now && open;
    const laterAvailable = service.order_later;

    const choiceClass = `w-1/2 sm:w-32 !m-1`;

    const nowElement = (
      <Choice
        className={choiceClass}
        active={order_config.s.config_due === "now"}
        disabled={!nowAvailable}
        onClick={() => {
          if (nowAvailable) {
            order_config.setDue("now");
          }
        }}>
        <ChoiceIcon>
          <FaHandPointDown />
        </ChoiceIcon>
        <p>Now</p>
      </Choice>
    );

    const laterElement = (
      <Choice
        className={choiceClass}
        active={order_config.s.config_due === "later"}
        disabled={!laterAvailable}
        onClick={() => {
          if (laterAvailable) {
            order_config.setDue("later");
          }
        }}>
        <ChoiceIcon>
          <FaClock />
        </ChoiceIcon>
        <p>Later</p>
      </Choice>
    );

    const serviceWord = formatServiceType(
      order_config.s.config_service as RestaurantServiceTypeEnumEnum
    );

    return (
      <div className="mt-6">
        {!nowAvailable && !laterAvailable && (
          <div>
            <p className="text-center text-error text-md">
              Sorry, we are currently not accepting orders for {serviceWord.toLowerCase()}
            </p>
          </div>
        )}
        {(nowAvailable || laterAvailable) && (
          <div>
            <p className={cn("text-lg text-center", nowAvailable ? "mb-6" : "mb-3")}>
              When Would You Like Your Order
            </p>
            {!nowAvailable && (
              <p className="mb-6 text-center text-md">
                {serviceWord} currently closed but you can order for later
              </p>
            )}
            <div
              className={cn("w-full mx-auto flex flex-wrap justify-center")}
              style={{ maxWidth: "500px" }}>
              {nowAvailable && nowElement}
              {laterAvailable && laterElement}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export const OrderConfigDue = OrderConfigDueClass;
