import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { CheckoutDetails } from "./details";
import { CheckoutCustomer } from "./customer";
import { CheckoutPayment } from "./payment";
import { CheckoutButton } from "./button";
import { FaExclamationCircle } from "react-icons/fa";
import { Checkbox, Button, Modal, ModalContent, ModalTitle } from "@lib/components";

interface Props {}
interface State {}

const errors: { [key: string]: string } = {
  internet:
    "Error, internet disconnected momentarily. Order and payment may be processed. Wait for email confirmation or try again",
  restaurant_at_capacity: "Store at capacity and cannot accept new orders",
  restaurant_closing_soon: "Store is closing soon and orders cannot be placed any more",
  stripe_generic: "Credit card error, try again or another method",
  stripe_loading_fail: "Error loading payment form, please try again",
  stripe_loading_error: "Error loading payment form, choose another method and try again",
  something_went_wrong: "Something went wrong",
  payment_fail: "Payment failed, please try again or select another payment method",
  payment_cancelled: "Payment cancelled, please try again or select another payment method",
  payment_error: "Error processing online payment, try again or pick another payment method",
  payment_declined: "Your payment method was declined",
  card_declined: "Your card was declined and payment couldn't be processed",
  card_unsupported:
    "Your card is currently not supported, please try another card or payment method",
  store_closed: "The store is closed and no longer accepting orders",
  accept_terms: "Please accept the terms & conditions",
  age_confirmation: "Please confirm you are over 18",
  required_payment: "Please select a payment method",
  required_phone: "Please enter your phone number",
  required_email: "Please enter your email",
  required_name: "Please enter your name",
  invalid_email: "Please enter a valid e-mail address",
  invalid_phone: "Please enter a valid phone number",
  invalid_cc_number: "Your credit card number is invalid",
  invalid_cvc:
    "Your card's CVC code is incorrect. A temporary charge may appear on your bank that will disappear shortly",
  retry_cc_number: "Something went wrong, re-enter your credit card number and try again",
  closed: "The store is closed/closing and not accepting orders",
  promo_login_required: "Must be logged in to use this promo",
  promo_used: "You have already used this promo",
};

@inject("store")
@observer
class CheckoutModalClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    const { isLoggedIn } = store.customer;
    const { ageRestrictedItemsInCart } = store.cart;
    const showAcceptMarketing = false; // !store.customer.s.item?.accept_marketing;
    const showLoginRecommendation = !isLoggedIn && !store.view.s.is_kiosk;
    const { serviceSafe } = store;
    return (
      <Modal
        id="checkout-modal"
        width={440}
        closeButton={true}
        active={store.modal.isVisible("checkout")}
        close={() => store.modal.back()}>
        <form onSubmit={store.checkout.order_commence}>
          <ModalTitle paddinglr={25} paddingtb={15} className="rounded-t">
            <div className="flex items-center">
              <div>
                <h4>Checkout</h4>
                <p className="text-md">Confirm your order details</p>
              </div>
            </div>
          </ModalTitle>

          <ModalContent paddinglr={25} paddingtb={15}>
            <CheckoutDetails />
          </ModalContent>

          <ModalTitle
            paddinglr={25}
            paddingtb={15}
            className="flex justify-between content-center items-center">
            <div>
              <h4>Customer</h4>
              {showLoginRecommendation && (
                <p className="text-md">Login to save your order & details</p>
              )}
            </div>
            {showLoginRecommendation && (
              <Button
                type="button"
                size="xs"
                color="primary-inverse"
                className="!text-sm"
                onClick={() => store.modal.show("auth")}>
                Login
              </Button>
            )}
          </ModalTitle>

          <ModalContent paddinglr={25} paddingtb={20}>
            <CheckoutCustomer />
          </ModalContent>

          <ModalTitle paddinglr={25} paddingtb={15}>
            <h4>Payment Method</h4>
          </ModalTitle>

          <ModalContent paddinglr={25} paddingtb={20}>
            <CheckoutPayment />
          </ModalContent>

          {ageRestrictedItemsInCart && (
            <ModalContent paddinglr={25} paddingtb={15}>
              <div className="flex justify-center items-center">
                <Checkbox
                  id="age_confirmation-checkout"
                  name="age_confirmation"
                  checked={store.checkout.s.age_confirmation}
                  onChange={(e) => store.checkout.update({ age_confirmation: e.target.checked })}
                  label={<p className="inline-block text-md">I'm over the age of 18</p>}
                />
              </div>
            </ModalContent>
          )}

          <ModalContent paddinglr={40} paddingtb={16} className="text-gray-1100">
            {store.order_config.s.config_service !== "delivery" &&
              serviceSafe?.notice_vaccine_entry && (
                <div className="bg-gray-100 py-4 px-6 mb-4 -mx-5 rounded">
                  <p className="mb-1 font-semibold">Vaccine Verification</p>
                  <p className="text-md">
                    Under public health orders by the Government, all guests over the age of 12 must
                    be double vaccinated before entering a venue. Vaccine passports will be scanned
                    prior to entry. By accepting the terms below, you agree to this
                  </p>
                </div>
              )}
            {showAcceptMarketing && (
              <div
                className="flex cursor-pointer mb-3"
                onClick={() => {
                  store.checkout.update({ accept_marketing: !store.checkout.s.accept_marketing });
                }}>
                <div style={{ marginTop: "3px" }}>
                  <Checkbox
                    id="accept-marketing-checkout"
                    name="accept_marketing"
                    checked={store.checkout.s.accept_marketing}
                  />
                </div>
                <p className="ml-3 text-md leading-relaxed">
                  Keep me up to date on new promos, deals and updates via e-mail or SMS{" "}
                  <span className="text-sm">(Optional)</span>
                </p>
              </div>
            )}
            <div
              className={
                showAcceptMarketing ? "flex cursor-pointer" : "flex justify-center cursor-pointer"
              }
              onClick={() => {
                store.checkout.update({ accept_terms: !store.checkout.s.accept_terms });
              }}>
              <div style={{ marginTop: "3px" }}>
                <Checkbox
                  id="accept-terms-checkout"
                  name="accept_terms"
                  checked={store.checkout.s.accept_terms}
                />
              </div>
              <p className="ml-3 text-md leading-relaxed">
                I accept the terms & conditions -{" "}
                <a className="link" onClick={() => store.modal.show("terms")}>
                  View
                </a>
              </p>
            </div>
          </ModalContent>

          {store.checkout.s.error && (
            <ModalContent className="bg-error text-white" paddinglr={25} paddingtb={12}>
              <p className="text-md flex items-center">
                <FaExclamationCircle className="mr-2 w-8" />
                {store.checkout.s.error.indexOf(" ") !== -1
                  ? store.checkout.s.error
                  : errors[store.checkout.s.error]}
              </p>
            </ModalContent>
          )}

          <ModalContent paddinglr={25} paddingtb={20}>
            <CheckoutButton />
          </ModalContent>
        </form>
      </Modal>
    );
  }
}

export const CheckoutModal = CheckoutModalClass;
