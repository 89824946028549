import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Table, TableBody, TableCell, TableRow } from "@lib/components";
import { formatCurrency, formatDate, formatDateTimeSimple, formatServiceType } from "@lib/common";
import { RestaurantServiceTypeEnumEnum } from "@lib/types/graphql";

interface Props {}
interface State {}

@inject("store")
@observer
class CheckoutDetailsClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    const oc = store.order_config.s;
    const l = store.r.location!;

    let dueText = "Now / ASAP";
    if (oc.config_due === "later") {
      dueText = formatDateTimeSimple(oc.config_timestamp);
      if (oc.config_service === "delivery" && store.r.service_delivery?.time_delivery_between) {
        dueText = `${formatDate(oc.config_timestamp)} ${
          store.r.service_delivery?.time_delivery_between
        }`;
      }
    }

    return (
      <Table className="text-md">
        <TableBody>
          <TableRow>
            <TableCell>Service</TableCell>
            <TableCell>
              {formatServiceType(oc.config_service as RestaurantServiceTypeEnumEnum)}
            </TableCell>
          </TableRow>

          {oc.config_service === "dine_in" && (
            <TableRow>
              <TableCell>Table</TableCell>
              <TableCell>{oc.config_table_name}</TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>Due</TableCell>
            <TableCell>{dueText}</TableCell>
          </TableRow>

          {oc.config_service === "delivery" && (
            <TableRow>
              <TableCell className="w-32">Delivery Address</TableCell>
              <TableCell>{oc.config_address!.display}</TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>Store Address</TableCell>
            <TableCell>{l.address.display.split(", New Zealand")[0]}</TableCell>
          </TableRow>

          <TableRow className="!border-0">
            <TableCell>Total</TableCell>
            <TableCell>{formatCurrency(store.cart.total)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export const CheckoutDetails = CheckoutDetailsClass;
