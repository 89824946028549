import * as React from "react";
import { RestaurantServiceTypeEnumEnum } from "@lib/types/graphql";
import { logger } from "@lib/common";
import { useStore } from "../../../mobx/component";

export function useETA() {
  const store = useStore();
  const [eta, setEta] = React.useState<{
    data: T.API.StoreSchedulerNextTimeRes | null;
    loading: boolean;
    error: boolean;
  }>({
    data: null,
    loading: true,
    error: false,
  });

  async function getEta() {
    try {
      /*
      setEta({
        data: null,
        loading: true,
        error: false,
      });
      */
      const data = await store.api.schedulerNextTime({
        service: store.order_config.s.config_service as RestaurantServiceTypeEnumEnum,
        driving_time_seconds: store.order_config.s.config_driving_time,
      });
      console.log(data);
      setEta({
        data: data,
        loading: false,
        error: false,
      });
    } catch (e) {
      logger.captureException(e);
      setEta({
        data: null,
        loading: false,
        error: true,
      });
    }
  }

  React.useEffect(() => {
    getEta();
    const interval = setInterval(() => getEta(), 10000);
    return () => clearInterval(interval);
  }, []);

  return { eta, etaRefresh: getEta };
}
