import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../../mobx/component";
import { CoreUtils } from "@lib/common";
import { HeaderWrapper } from "../components";

export const HeaderA = observer(() => {
  const store = useStore();
  const r = store.r;
  const isWebView = store.view.s.is_web_view;
  const isPwa = store.view.s.is_pwa;
  const w = r.website!;
  const backgroundImage = CoreUtils.image.uc(w.header_image_background);
  const headerLogoImage = CoreUtils.image.uc(w.header_image_logo);

  const image = headerLogoImage ? (
    <img
      id="header-logo-image"
      className="h-auto mb-3 mx-auto rounded"
      src={headerLogoImage}
      alt={`Logo - ${r.name}`}
    />
  ) : null;

  const renderLinkLogo = image && w.header_image_logo_link && !isWebView && !isPwa;
  const renderLogo = image && (!w.header_image_logo_link || isWebView || isPwa);
  return (
    <HeaderWrapper
      className="flex items-center justify-center w-full"
      color={w.header_color_text}
      background={w.header_color_background}
      tint={w.header_color_tint}
      image={backgroundImage}
      paddingTop={0}>
      <div className="text-center" style={{ zIndex: 1 }}>
        {renderLinkLogo && <a href={w.header_image_logo_link}>{image}</a>}
        {renderLogo && image}
        {w.header_title !== " " && <h1 className="text-3xl">{w.header_title || r.name}</h1>}
        {w.header_subtitle && <p className="text-xl font-semibold mt-2">{w.header_subtitle}</p>}
      </div>
    </HeaderWrapper>
  );
});
