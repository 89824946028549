import * as React from "react";
import cn from "classnames";
import { MobxComponent } from "../../../../mobx/component";
import { OptionListWrapper, OptionListItem } from "../components";
import { ModalDropContent, HitBox, Switch } from "@lib/components";
import {
  cloneDeepSafe,
  formatCurrency,
  menuOptionSets,
  orderOptions,
  orderOptionsMut,
} from "@lib/common";
import {
  RestaurantMenuDishOptionFullFragment,
  RestaurantMenuDishOptionSetFullFragment,
} from "@lib/types/graphql";
import { action, computed, toJS } from "mobx";
import { inject, observer } from "mobx-react";

interface Props {
  selectedChoiceId: string;
}
interface State {}

@inject("store")
@observer
class DishModalOptionSetClass extends MobxComponent<Props, State> {
  @action onSelect = (args: {
    os: RestaurantMenuDishOptionSetFullFragment;
    option: RestaurantMenuDishOptionFullFragment;
  }) => {
    const { store } = this.injected;
    const { os, option } = args;
    const { selectedChoiceId: choiceId } = this.props;
    const od = store.dish.s.orderDish!;
    orderOptionsMut.select({
      od: od,
      os: os,
      option: option,
      odChoiceId: choiceId,
      optionRemove: (index) => this.orderOptions.splice(index, 1),
      optionAdd: (opt) => this.orderOptions.push({ option: opt }),
    });
    this.reCalcOptions();
  };

  @action onHitBox = (args: {
    os: RestaurantMenuDishOptionSetFullFragment;
    option: RestaurantMenuDishOptionFullFragment;
    direction: "up" | "down";
  }) => {
    const { store } = this.injected;
    orderOptionsMut.selectHitBox({
      od: store.dish.s.orderDish!,
      os: args.os,
      option: args.option,
      odChoiceId: this.props.selectedChoiceId,
      direction: args.direction,
      optionAdd: (opt) => this.orderOptions.push({ option: opt }),
      optionRemove: (index) => this.orderOptions.splice(index, 1),
      optionEditQty: (index, qty) => (this.orderOptions[index].option.quantity += qty),
    });
    this.reCalcOptions();
  };

  @action reCalcOptions = () => {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    orderOptionsMut.calc({
      od: store.dish.s.orderDish!,
      md: store.dish.s.menuDish!,
      r: {
        menus: store.menusAll,
        option_sets: store.rOptionSets,
      },
      platform: "online",
      service: store.order_config.s.config_service,
      odChoiceId: selectedChoiceId,
      odChoiceDishId: this.choiceDishId,
      surchargePct: store.dish.s.menuDish!.surcharge_pct,
    });
  };

  @computed get choiceDishId() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const od = store.dish.s.orderDish!;
    return od.choices.find((c) => c.id === selectedChoiceId)?.dish_id || "";
  }

  @computed get menuOptionSets() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const md = store.dish.s.menuDish!;
    return menuOptionSets.forDish({
      md: md,
      r: {
        menus: store.menusAll,
        option_sets: store.rOptionSets,
      },
      platform: "online",
      service: store.order_config.s.config_service,
      odChoiceDishId: this.choiceDishId,
      // surchargePct: md.surcharge_pct,
    });
  }

  @computed get orderOptions() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const od = store.dish.s.orderDish!;
    return orderOptions.inDish({ od, odChoiceId: selectedChoiceId });
  }

  render() {
    const { selectedChoiceId } = this.props;
    const { store } = this.injected;
    const option_sets = this.menuOptionSets;
    const od = store.dish.s.orderDish!;
    const md = store.dish.s.menuDish!;
    // console.log({ surchargePct: md.surcharge_pct });
    return (
      <>
        {option_sets.map((os) => {
          const { label } = menuOptionSets.description({ os });
          const { freeRemaining, freeTotal, totalSelected } = orderOptions.freeAvailable({
            od: od,
            os: os,
            odChoiceId: selectedChoiceId,
          });

          // !(option_sets.length > 1 && freeTotal)
          const free_description = !(option_sets.length > 1 && freeTotal)
            ? ""
            : `First ${freeTotal} Free / ${totalSelected} Chosen`;

          const controlType = menuOptionSets.controlType({ os });

          const options = orderOptions.forDish({
            optionSet: os,
            od: od,
            odChoiceId: selectedChoiceId,
            platform: "online",
            service: store.order_config.s.config_service,
          });

          const errorKey = selectedChoiceId ? `${selectedChoiceId}-${os.id}` : os.id;
          const error = store.dish.s.error && (store.dish.s.optionSetErrors[errorKey] || false);

          // console.log({ options: cloneDeepSafe(options) });

          if (options.length === 0) {
            return null;
          }

          return (
            <ModalDropContent
              key={os.id}
              title={(() => {
                return (
                  <div>
                    <p className={cn("text-md font-semibold", { "text-error": error })}>
                      {os.display_name || os.name}
                    </p>
                    <p className={cn("text-md", { "text-error": error })}>{label}</p>
                    {free_description && <p className={cn("text-sm italic")}>{free_description}</p>}
                  </div>
                );
              })()}
              paddingtb={10}
              paddinglr={25}
              cPaddingtb={15}
              cPaddinglr={25}>
              <OptionListWrapper>
                {options.map((o, i) => {
                  const orderOption = orderOptions.inDishById({
                    od: od,
                    option: o,
                    odChoiceId: selectedChoiceId,
                  });
                  const price = orderOptions.price({
                    od: od,
                    option: o,
                    service: store.order_config.s.config_service,
                    odChoiceId: selectedChoiceId,
                    surchargePct: md.surcharge_pct,
                  });
                  const showPrice = !!price && freeRemaining === 0;
                  return (
                    <OptionListItem
                      key={i}
                      cursor={o.no_stock ? "initial" : ""}
                      onClick={
                        controlType === "switch" ? () => this.onSelect({ os, option: o }) : () => {}
                      }>
                      <div className="pr-2">
                        <p className="text-md leading-snug">{o.name}</p>
                        {o.description && <p className="text-sm text-gray-800">{o.description}</p>}
                      </div>
                      <div className="flex items-center">
                        {o.no_stock && (
                          <p className="text-xs text-error leading-none whitespace-no-wrap uppercase">
                            UN-AVAILABLE
                          </p>
                        )}

                        {!o.no_stock && (
                          <>
                            {showPrice && <p className="pr-2 text-md">+{formatCurrency(price)}</p>}

                            {controlType === "switch" && (
                              <Switch
                                id={`${o.id}-${i}-${selectedChoiceId || "x"}`}
                                name={`${o.id}-${i}-${selectedChoiceId || "x"}`}
                                checked={(orderOption?.option.quantity || 0) >= 1}
                                onChange={() => this.onSelect({ os, option: o })}
                              />
                            )}

                            {controlType === "hitbox" && (
                              <HitBox
                                up={() => this.onHitBox({ os, option: o, direction: "up" })}
                                down={() => this.onHitBox({ os, option: o, direction: "down" })}
                                value={orderOption?.option.quantity || 0}
                                small={true}
                              />
                            )}
                          </>
                        )}
                      </div>
                    </OptionListItem>
                  );
                })}
              </OptionListWrapper>
            </ModalDropContent>
          );
        })}
      </>
    );
  }
}

export const DishModalOptionSet = DishModalOptionSetClass;
