import * as React from "react";
import { styled, shadeAlternate } from "@lib/components";

export const PaymentButtonWrapper = styled("div")``;

const Btn = styled("div")`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 15px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  color: ${({ theme }) => theme.colors.text};
  transition: 0.23s all;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size}px;

  > .check-circle {
    transition: 0.23s all;
    border: 2px solid ${({ theme }) => shadeAlternate(0.1, theme.colors.border)};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    &:after {
      content: " ";
    }
  }

  &:hover,
  &.active {
    transform: scale(1.015);
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    > .check-circle {
      border: 1px solid ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colors.primary};
      width: 14px;
      height: 14px;
    }
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const PaymentButton = (props: { active: boolean; title: string; onClick: () => void }) => (
  <Btn className={props.active ? "active" : ""} onClick={props.onClick}>
    <span className="check-circle" />
    <span className="text">{props.title}</span>
  </Btn>
);

export const PaymentImageWrapper = styled("div")`
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PaymentImage = styled("div")`
  text-align: center;
  img {
    max-height: 28px;
  }
`;
