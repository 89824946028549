import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Button, Modal, ModalContent } from "@lib/components";

interface Props {}

@inject("store")
@observer
class OrderExpiredWarningModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { modal } = store;
    return (
      <Modal
        id="modal-order-expired-warning"
        width="sm"
        closeButton={true}
        active={modal.isVisible("order-expired-warning")}
        wrapperClick={(e) => e.stopPropagation()}
        close={() => {
          modal.show("order-config");
        }}>
        <ModalContent>
          <h4>Order Time Expired</h4>
        </ModalContent>

        <ModalContent>
          <p className="text-md">
            We regret to inform you that the time reserved for your order has expired. You will need
            to restart your order. Orders scheduled for a later time must be placed within 20
            minutes
          </p>
        </ModalContent>

        <ModalContent className="text-right" paddingtb={15}>
          <Button
            color="primary"
            className="!w-24"
            onClick={(e) => {
              e.stopPropagation();
              modal.show("order-config");
            }}>
            <p>Restart</p>
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const OrderExpiredWarning = OrderExpiredWarningModalClass;
