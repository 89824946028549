import * as React from "react";
import cn from "classnames";
import { observer } from "mobx-react";

interface Props {
  // multiLevel: boolean;
  selected: string[];
  onSelect: (selected: string[]) => void;
  items: Array<{
    _id: string;
    name: string;
    nestedItems?: Array<{ _id: string; name: string }>;
  }>;
}

export const VerticalMenu = observer((props: Props) => {
  return (
    <>
      {props.items.map((item) => {
        const active = item._id === props.selected[0];

        const wrapperClass = cn("py-1 cursor-pointer", {});

        const titleWrapperClass = cn(
          "flex justify-end px-5 border-r-4 border-transparent text-md font-medium",
          active ? "mb-2" : "mb-1"
        );

        // const titleClass = cn("text-xs font-bold text-gray-800 bg-gray-100 px-2 py-1 -mr-2 rounded", active && "text-gray-1000");
        const titleClass = cn(
          "font-bold text-gray-800 flex items-center underline",
          active && "text-gray-1000"
        );

        return (
          <div key={item._id} className={wrapperClass} onClick={() => props.onSelect([item._id])}>
            {props.items.length > 1 && (
              <div className={titleWrapperClass}>
                <div className={titleClass} style={{ fontSize: "1.1rem" }}>
                  {item.name}
                </div>
              </div>
            )}

            {item.nestedItems && (
              <div className={active ? "" : "hidden"}>
                {item.nestedItems.map((nestedItem) => {
                  const nestedActive = nestedItem._id === props.selected[1];
                  const nestedClass = cn(
                    "px-5 py-1 text-md border-r-4",
                    nestedActive ? "font-bold border-primary" : "text-gray-900 border-transparent"
                  );
                  return (
                    <div
                      key={nestedItem._id}
                      className={nestedClass}
                      onClick={(e) => {
                        e.stopPropagation();
                        props.onSelect(["", nestedItem._id]);
                      }}>
                      {nestedItem.name}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
});
