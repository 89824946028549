import { RootStore } from "../store";
import { observable, action, computed } from "mobx";
import { RestaurantOrderFullFragment } from "@lib/types/graphql";

export interface OrderState {
  item: RestaurantOrderFullFragment | null;
  loading: boolean;
  error: string;
}

export class OrderStore {
  @observable s: OrderState;

  store: RootStore;

  constructor(store: RootStore, initialState?: OrderState) {
    this.store = store;
    this.s = {
      item: null,
      loading: true,
      error: "",
      ...(initialState || {}),
    };
  }

  @computed get routeId() {
    const path = this.store.router.s.path;
    return path.indexOf("/order/") !== -1 ? path.split("/order/")[1] : null;
  }

  @action update = (data: Partial<OrderState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof OrderState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof OrderState] = value;
        }
      }
    }
  };

  @action updateItem(data: Partial<RestaurantOrderFullFragment>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof RestaurantOrderFullFragment];
        if (value !== undefined && this.s && this.s.item) {
          // @ts-ignore
          this.s.item[key as keyof RestaurantOrderFullFragment] = value;
        }
      }
    }
  }

  @action updateDriverCoords(lat: number, lng: number) {
    const o = this.s.item;
    if (o && o.driver_deliveries.length > 0) {
      const delivery = o.driver_deliveries[0];
      if (delivery.driver) {
        delivery.driver.coords = {
          type: "Point",
          coordinates: [lng, lat],
        };
      }
    }
  }
}
