import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Button, Modal, ModalContent } from "@lib/components";

interface Props {}

@inject("store")
@observer
class ResellerPortalBackModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { modal } = store;
    return (
      <Modal
        id="modal-reseller-portal-back"
        width="sm"
        closeButton={true}
        active={modal.isVisible("reseller-portal-back")}
        close={() => modal.hide("reseller-portal-back")}>
        <ModalContent>
          <h4>Go Back To Restaurant Search?</h4>
        </ModalContent>
        <ModalContent>
          <p className="">Any items in your cart will be lost</p>
        </ModalContent>
        <ModalContent className="text-right" paddingtb={15}>
          <Button className="mr-2" onClick={() => modal.hide("order-restart")}>
            <p>Cancel</p>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              store.loader.update({
                title: "",
                active: true,
                opacity: 0.75,
                message: "",
              });
              window.location.href = store.view.s.reseller_portal_url;
            }}>
            <p>Go Back</p>
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const ResellerPortalBackModal = ResellerPortalBackModalClass;
