import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { FaChevronCircleLeft, FaShoppingBag } from "react-icons/fa";
import { FiShoppingBag } from "react-icons/fi";
import { BlockError, Button, RotateLoader } from "@lib/components";
import { OrderCartItems } from "./order-items";
import { OrderCartTotals } from "./order-totals";
import { formatCurrency } from "@lib/common";
import { OrderCartPromo } from "./promo";

interface Props {
  showChangeDetails?: boolean;
}

export const OrderCart = observer((props: Props) => {
  const store = useStore();
  const { cart, modal } = store;
  const items = cart.dishes;
  const deliveryService = store.r.service_delivery!;
  const proceedToCheckoutValid = cart.minOrderDeliveryValid;
  const showFreeDelivery =
    !!deliveryService.free_delivery_min && store.order_config.s.config_service === "delivery";
  const stockError = cart.noStock.dishes.length > 0 || cart.noStock.options.length > 0;

  async function checkout() {
    if (!store.cart.minOrderDeliveryValid) return;

    const noStock = await cart.checkStock();
    if (noStock.dishes.length > 0 || noStock.options.length > 0) {
      return;
    }

    store.modal.show("checkout"); // checkout

    fbq("track", "InitiateCheckout", {
      value: store.cart.totalCartWithDiscount,
      num_items: store.cart.itemsInCart,
      currency: "NZD",
    });
  }

  return (
    <>
      {items.length === 0 && (
        <>
          <h3 className="flex items-center bg-gray-100 text-gray-900 rounded p-2 -mx-2">
            <FiShoppingBag className="mr-3 text-2xl" />
            <span className="">Cart Empty</span>
          </h3>
          <p className="mt-4">Your cart is currently empty. Go ahead and add some items to it</p>
        </>
      )}
      {items.length !== 0 && (
        <div className="children:mb-5 last-mb-0">
          <div className="!mb-2">
            <h3 className="flex items-center bg-gray-100 text-gray-900 rounded p-2 -mx-2">
              <FiShoppingBag className="mr-3 text-2xl" />
              <span className="">Your Cart</span>
            </h3>
            {showFreeDelivery && (
              <p className="text-sm mt-2 text-gray-900">
                Free delivery for orders over {formatCurrency(deliveryService.free_delivery_min!)}
              </p>
            )}
          </div>

          <div>
            <OrderCartItems />
            <OrderCartTotals />
          </div>

          <OrderCartPromo />

          <div>
            {!cart.minOrderDeliveryValid && (
              <BlockError className={stockError ? "mb-2" : "mb-4"}>
                Minimum {formatCurrency(cart.minOrderDelivery!)} required for delivery
              </BlockError>
            )}
            {stockError && (
              <BlockError className="mb-4">
                Items marked in red are no longer in stock, remove them to proceed
              </BlockError>
            )}
            <Button
              full={true}
              color="primary"
              onClick={checkout}
              loading={cart.noStock.loading}
              disabled={!proceedToCheckoutValid || cart.noStock.loading}>
              {cart.noStock.loading && <RotateLoader size={2} color="white" />}
              {!cart.noStock.loading && `Proceed To Checkout`}
            </Button>
          </div>
        </div>
      )}
      {props.showChangeDetails && (
        <a
          className="mt-6 text-sm no-underline flex items-center mx-1"
          onClick={() => modal.show("order-restart")}>
          <FaChevronCircleLeft className="text-xs mr-2" />
          Change order details
        </a>
      )}
    </>
  );
});
