import * as React from "react";
import { observer, inject } from "mobx-react";
import { CircleIcon, Button } from "@lib/components";
import { FaMapMarkerAlt } from "react-icons/fa";
import { MobxComponent } from "../../../mobx/component";

interface Props {}

@inject("store")
@observer
class InfoLocationClass extends MobxComponent<Props> {
  render() {
    const r = this.injected.store.r;
    const modal = this.injected.store.modal;
    return (
      <>
        <CircleIcon icon={<FaMapMarkerAlt />} />
        <div className="text-center">
          <h3 className="mb-4 font-normal mt-4">Location</h3>
          <p>{r.location!.address.display}</p>
          <Button
            className="mt-3"
            size="xs"
            color="primary-inverse"
            onClick={() => modal.toggle("map")}>
            View Map
          </Button>
        </div>
        {r.location!.phone && (
          <div className="text-center mt-6">
            <h3 className="mb-2 font-normal">Phone</h3>
            <p className="">{r.location!.phone}</p>
          </div>
        )}
      </>
    );
  }
}

export const InfoLocation = InfoLocationClass;
