import { styled } from "@lib/components";

export const SideColumnWrapper = styled.div<{ topBarHeight: number }>`
  height: 100%;
  max-height: calc(100vh - ${({ topBarHeight }) => topBarHeight}px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-button {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track-piece {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-corner {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
`;
