import * as React from "react";
import { observer } from "mobx-react";
import { RestaurantOrderFullFragment, RestaurantStoreFragment } from "@lib/types/graphql";
import { styled } from "@lib/components";
import { useTheme } from "../../../../mobx/component";
import { DriverTrackingMap } from "../../../components/DriverTrackingMap";

type Props = {
  r: RestaurantStoreFragment;
  o: RestaurantOrderFullFragment;
  tab: number;
  type: "desktop" | "mobile";
};

const Map = styled.div`
  flex-grow: 1;
  height: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mmd}px) {
    height: 300px;
  }
`;

export const OrderReceiptMap = observer((props: Props) => {
  const { r, o } = props;
  const theme = useTheme();
  const id = props.type === "desktop" ? "order-receipt-map-desktop" : "order-receipt-map-mobile";
  const delivery = o.driver_deliveries.length > 0 ? o.driver_deliveries[0] : null;
  const driver = delivery?.driver;
  const lat = driver?.coords?.coordinates[1];
  const lng = driver?.coords?.coordinates[0];
  const deliveryTrackable =
    delivery?.status === "at_restaurant" || delivery?.status === "to_customer";
  const deliveryCompleted = o.status === "complete" || delivery?.status === "completed";

  return (
    <DriverTrackingMap
      mapId={id}
      mapEl={<Map id={id} onFocus={(e) => e.target.blur()} />}
      mapResize={props.tab}
      deliveryTrackable={deliveryTrackable}
      deliveryCompleted={deliveryCompleted}
      driverLat={lat}
      driverLng={lng}
      coordsPickup={r.location!.address.coords.coordinates}
      coordsDropoff={o.config_address!.coords.coordinates}
      colorDriver={theme.colors.primary}
      colorDriverBorder={theme.colors.border}
      colorPin={theme.colors.primary}
    />
  );
});
