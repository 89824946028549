import * as React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useStore } from "../../../../mobx/component";
import { CoreUtils, formatCurrency, serviceGetStatusReadyTime } from "@lib/common";
import { FaMapMarkerAlt, FaShoppingBag, FaTruck, FaClock } from "react-icons/fa";
import { styled, css, ConnectionIndicatorCircle } from "@lib/components";

interface HeaderProps extends React.HTMLAttributes<HTMLHeadingElement> {
  image: string;
  tint: string;
}

export const Content = styled("div")`
  max-width: ${({ theme }) => theme.breakpoints.lg}px;
`;

export const ImageBox = styled("div")<HeaderProps>`
  height: 260px;
  min-height: 260px;
  width: 100%;
  ${({ image, tint }) =>
    image && !tint
      ? css`
          background: url(${image}) no-repeat center center;
          background-size: cover;
        `
      : css``}
  ${({ image, tint }) =>
    image && tint
      ? css`
          background: linear-gradient(${tint}, ${tint}), url(${image}) no-repeat center center;
          background-size: cover;
        `
      : css``}
`;

export const HeaderB = observer(() => {
  const store = useStore();
  const r = store.r;
  const w = r.website!;
  const backgroundImage = CoreUtils.image.uc(w.header_image_background);

  const pickup = r.service_pickup!;
  const delivery = r.service_delivery!;

  const points: string[] = [];

  let pickupEta: number | null = null;
  let deliveryEta: number | null = null;

  if (pickup.enabled) {
    const pickup_auto_status_time_ready = serviceGetStatusReadyTime({
      timestamp: Date.now(),
      auto_status_time_ready: pickup.auto_status_time_ready,
      auto_status_time_ready_periods: pickup.auto_status_time_ready_periods,
    });
    pickupEta = (pickup.auto_status_time_confirmed || 0) + (pickup_auto_status_time_ready || 0);
  }

  if (delivery.enabled) {
    const delivery_auto_status_time_ready = serviceGetStatusReadyTime({
      timestamp: Date.now(),
      auto_status_time_ready: delivery.auto_status_time_ready,
      auto_status_time_ready_periods: delivery.auto_status_time_ready_periods,
    });
    deliveryEta =
      (delivery.auto_status_time_confirmed || 0) +
      (delivery_auto_status_time_ready || 0) +
      (delivery.auto_status_time_on_route || 0);
    if (delivery.min_order) {
      points.push(`Min. delivery ${formatCurrency(delivery.min_order)}`);
    }
    if (delivery.free_delivery_min) {
      points.push(`Free delivery above ${formatCurrency(delivery.free_delivery_min)}`);
    }
  }

  let preOrdersOnly = true;
  for (const m of store.menusAll) {
    if (!m.restrict_pre_order) {
      preOrdersOnly = false;
      break;
    }
  }

  function Details() {
    return (
      <div className="flex flex-col items-start">
        <div>
          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-medium leading-tight mb-1">
            {w.header_title || r.name}
          </h1>

          <div className="text-gray-800 text-sm md:text-base">
            <div className="flex flex-wrap items-center children:mr-3 last-mr-0">
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-xs" />
                <span className="ml-2">{r.location!.name}</span>
              </div>

              {!preOrdersOnly && (
                <>
                  {pickupEta && (
                    <div className="flex items-center">
                      <FaShoppingBag className="text-xs" />
                      <span className="ml-2">{pickupEta} min</span>
                    </div>
                  )}

                  {deliveryEta && (
                    <div className="flex items-center">
                      <FaTruck className="text-xs" />
                      <span className="ml-2">{deliveryEta} min</span>
                    </div>
                  )}

                  <div className="flex items-center">
                    <ConnectionIndicatorCircle
                      status={store.storeOpen ? "connected" : "disconnected"}
                    />
                    <span className="ml-2 hidden sm:block">
                      {store.storeOpen ? "Open" : "Pre-orders only"}
                    </span>
                  </div>
                </>
              )}

              {preOrdersOnly && (
                <>
                  <div className="flex items-center">
                    <FaClock className="text-xs" />
                    <span className="ml-2">Pre-orders only</span>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="hidden sm:block border-b mt-3" />
        </div>

        <div className="children:mt-3">
          {/*<div>
            {r.listing?.tags.reduce((a, t, i) => {
              if (i !== 0)
                return `${a} • ${t}`;
              return t;
            }, "")}
          </div>*/}

          <div className="hidden sm:flex flex-wrap text-sm md:text-base children:mr-2 last-mr-0">
            {r.listing?.tags.map((t: string, i: number) => (
              <div key={i}>
                {i !== 0 && <span className="mr-2">•</span>}
                <span className="">{t}</span>
              </div>
            ))}
          </div>

          {points.length > 0 && (
            <div>
              <div className="flex flex-wrap items-center -m-1">
                {points.map((p, i) => (
                  <div key={i} className="p-1">
                    <span className="bg-white rounded py-1 px-2 text-sm">{p}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function Image(props: { className: string }) {
    return (
      <div className={cn("w-full md:w-1/2 md:px-4 lg:px-8", props.className)}>
        <ImageBox
          image={backgroundImage!}
          tint={w.header_color_tint}
          className="md:rounded md:shadow-lg"
        />
      </div>
    );
  }

  return (
    <header className="bg-gray-100 md:px-12 md:py-10">
      <Content className="mx-auto">
        <div className="flex flex-wrap items-center justify-between md:-mx-4 lg:-mx-8">
          <Image className="md:hidden" />

          <div className="hidden md:block w-1/2 px-4 lg:px-8">
            <Details />
          </div>

          <div className="md:hidden w-full bg-gray-000 p-4">
            <div className="w-full py-6 px-5 sm:px-6 bg-gray-100 rounded shadow-md -mt-24">
              <Details />
            </div>
          </div>

          <Image className="hidden md:block" />
        </div>
      </Content>
    </header>
  );
});
