import * as React from "react";
import { RootStore } from "./store";
import { MobXProviderContext } from "mobx-react";
import { ThemeContext } from "styled-components";

export interface InjectedProps {
  theme: T.ThemeInterface;
  store: RootStore;
}

export class MobxComponent<Props = {}, State = {}> extends React.Component<Props, State> {
  get injected() {
    return this.props as InjectedProps & Props;
  }
}

export function useStore(): RootStore {
  const data = React.useContext(MobXProviderContext);
  return data.store;
}

export function useTheme(): T.ThemeInterface {
  return React.useContext(ThemeContext);
}
