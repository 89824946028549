import * as React from "react";
import { Provider as ProviderComponent } from "mobx-react";
import { RootStore } from "../mobx/store";
import { App } from "./app";
import { ThemeContext } from "styled-components";

interface Props {
  store: RootStore;
  theme: T.ThemeInterface;
}

export default class Application extends React.Component<Props> {
  render() {
    return (
      <ProviderComponent store={this.props.store}>
        <ThemeContext.Provider value={this.props.theme}>
          <App />
        </ThemeContext.Provider>
      </ProviderComponent>
    );
  }
}
