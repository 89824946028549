import * as React from "react";
import { observer } from "mobx-react";
import {
  deliveryFormatTrackingLink,
  formatCurrency,
  formatDateTime,
  formatPaymentMethodName,
  formatPaymentStatus,
  formatServiceType,
  PaymentMethods,
  restaurantGetPaymentIncCustom,
} from "@lib/common";
import { DetailRowList, LinkTag } from "@lib/components";
import { RestaurantOrderFullFragment, RestaurantStoreFragment } from "@lib/types/graphql";

type Props = {
  r: RestaurantStoreFragment;
  o: RestaurantOrderFullFragment;
};

export const OrderReceiptDetails = observer((props: Props) => {
  const { r, o } = props;
  return (
    <DetailRowList
      className="text-md"
      items={[
        {
          l: "Placed",
          v: formatDateTime(o.created),
        },
        /*
        {
          l: t("order.receipt.details.due"),
          v: (() => {
            if (o.config_due === "now") {
              return t("order.receipt.details.due_asap");
            }
            return t("datetimeFromTimestamp", { value: o.config_timestamp });
          })(),
        },
        */
        {
          l: "Total",
          v: formatCurrency(o.payment_total),
        },
        {
          l: "Type",
          v: formatServiceType(o.config_service),
        },
        {
          l: "Table",
          h: o.config_service !== "dine_in" || !o.config_table_name,
          v: o.config_table_name,
        },
        {
          l: "Payment Method",
          v: (() => {
            // If payments length is 0, it was likely a cash or card online order that would have been removed
            if (o.payments.length === 0) {
              if (r.payment_cash?.enabled && r.payment_card?.enabled) {
                return "Cash / EFTPOS (In-Store)";
              } else if (r.payment_cash?.enabled) {
                return "Cash (In-Store)";
              } else if (r.payment_card?.enabled) {
                return "EFTPOS (In-Store)";
              } else {
                return "Unpaid (Pay In-Store)";
              }
            }

            return o.payments.reduce((a, payment, i) => {
              const pm = restaurantGetPaymentIncCustom(r, payment);

              let label: string =
                payment.type === "custom" ? "Custom" : formatPaymentMethodName(payment.type);
              if (o.config_service === "delivery" && pm.label_delivery) {
                label = pm.label_delivery;
              } else if (pm.label) {
                label = pm.label;
              }

              return i === 0 ? label : ` | ${label}`;
            }, "");
          })(),
        },
        {
          l: "Payment Status",
          h:
            o.payments.length === 0 ||
            !o.payments[0] ||
            o.payments[0]?.type === "cash" ||
            o.payments[0]?.type === "card" ||
            PaymentMethods.indexOf(o.payments[0]?.type) === -1,
          v:
            o.payments.length === 0 || !o.payments[0] ? null : (
              <span
                className={
                  o.payments[0].status === "success"
                    ? "text-success"
                    : ["error", "cancelled"].indexOf(o.payments[0].status) !== -1
                    ? "text-error"
                    : ""
                }>
                <span className="block">{formatPaymentStatus(o.payments[0].status)}</span>
              </span>
            ),
        },
        {
          l: "Notes",
          v: o.notes,
        },
        {
          l: "Name",
          v: o.customer_name,
        },
        {
          l: "E-Mail",
          v: o.customer_email && <span style={{ wordBreak: "break-all" }}>{o.customer_email}</span>,
        },
        {
          l: "Phone",
          v: o.customer_phone,
        },
        {
          l: "Delivery Address",
          h: o.config_service !== "delivery",
          v: (() => {
            if (o.config_service !== "delivery") {
              return null;
            }
            const notesPrefix = o.config_address?.notes ? `${o.config_address?.notes} - ` : "";
            return notesPrefix + o.config_address!.display;
          })(),
        },
        {
          l: "Delivery Tracking",
          h: o.config_service !== "delivery" || !o.tookan_delivery_tracking,
          v: (
            <span>
              <LinkTag
                target="_blank"
                href={deliveryFormatTrackingLink(o.tookan_delivery_tracking)}>
                Real-time tracking link
              </LinkTag>
            </span>
          ),
        },
        {
          l: "Store Address",
          v: r.location!.address.display,
        },
      ]}
    />
  );
});
