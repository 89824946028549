import * as React from "react";
import autobind from "autobind-decorator";
import isEmail from "validator/lib/isEmail";
import { observable, action, computed, toJS } from "mobx";
import { RootStore } from "../store";
import { cc, centsToDecimal, cloneDeepSafe, logger, orderDishesSort } from "@lib/common";
import { untrusive } from "../../../client/untrusive";
import {
  RestaurantOrderCreateFragment,
  RestaurantOrderPaymentFragment,
  RestaurantPaymentTypeEnumEnum,
  RestaurantPaymentTypeSubEnumEnum,
  RestaurantServiceTimeEnumEnum,
  RestaurantServiceTypeEnumEnum,
} from "@lib/types/graphql";

export interface CheckoutState {
  no_contact_delivery: boolean;
  age_confirmation: boolean;
  accept_terms: boolean;
  accept_marketing: boolean;
  save_guest: boolean;
  paymark_eftpos_modal: boolean;
  card_expiry: string;
  card_cvv: string;
  card_token: string;
  card_error: string;
  address_field: string; // CATCH
  loading: boolean;
  error: string;
}

@autobind
export class CheckoutStore {
  @observable s: CheckoutState;
  store: RootStore;
  paymark_check_interval: any;

  constructor(store: RootStore, initialState?: CheckoutState) {
    this.store = store;
    this.s = initialState || this.initialState();
  }

  initialState(): CheckoutState {
    const delivery = this.store.r.service_delivery!;
    return {
      no_contact_delivery:
        delivery.notice_no_contact === "optional" || delivery.notice_no_contact === "always",
      age_confirmation: false,
      accept_terms: false,
      accept_marketing: false,
      save_guest: false,
      paymark_eftpos_modal: false,
      address_field: "",
      card_expiry: "",
      card_cvv: "",
      card_token: "",
      card_error: "",
      loading: false,
      error: "",
    };
  }

  isPaymentMethodValid(payment: { max_order: number; restrict_services: string[] }) {
    const total = this.store.cart.total;
    const oc = this.store.order_config.s;

    let valid = true;

    if (
      valid &&
      payment.restrict_services.length !== 0 &&
      payment.restrict_services.indexOf(oc.config_service) === -1
    ) {
      valid = false;
    }

    if (valid && payment.max_order && total >= payment.max_order) {
      valid = false;
    }

    return valid;
  }

  @computed get paymentMethods() {
    const { store } = this;
    const { r } = store;

    const methods: Array<{
      type: RestaurantPaymentTypeEnumEnum;
      subtype?: RestaurantPaymentTypeSubEnumEnum;
      custom_id?: string;
      label: string;
      label_delivery: string;
    }> = [];

    if (store.view.s.is_kiosk) {
      methods.push({
        type: "cash",
        label: r.payment_cash!.label,
        label_delivery: r.payment_cash!.label_delivery,
      });
      methods.push({
        type: "card",
        label: r.payment_card!.label,
        label_delivery: r.payment_card!.label_delivery,
      });
      return methods;
    }

    let country = "nz";
    try {
      if (cc.production) {
        country = geotargetly_country_code().toLowerCase();
      }
    } catch (e) {}

    const isDelivery = store.order_config.s.config_service === "delivery";
    const isDeliveryManaged = r.service_delivery!.managed_enabled;

    // Only allow in-person payments for people in NZ
    if (country === "nz") {
      // If this is a managed delivery, don't allow in-person payments
      if (!(isDelivery && isDeliveryManaged)) {
        for (const custom of r.payment_custom) {
          if (custom.enable_online && this.isPaymentMethodValid(custom)) {
            methods.push({
              type: "custom",
              custom_id: custom.id,
              label: custom.label,
              label_delivery: custom.label_delivery,
            });
          }
        }

        if (r.payment_cash!.enabled && this.isPaymentMethodValid(r.payment_cash!)) {
          methods.push({
            type: "cash",
            label: r.payment_cash!.label,
            label_delivery: r.payment_cash!.label_delivery,
          });
        }

        if (r.payment_card!.enabled && this.isPaymentMethodValid(r.payment_card!)) {
          methods.push({
            type: "card",
            label: r.payment_card!.label,
            label_delivery: r.payment_card!.label_delivery,
          });
        }
      }
    }

    if (r.payment_stripe!.active && this.isPaymentMethodValid(r.payment_stripe!)) {
      methods.push({
        type: "stripe",
        label: r.payment_stripe!.label,
        label_delivery: r.payment_stripe!.label_delivery,
      });
    }

    if (r.payment_windcave!.active && this.isPaymentMethodValid(r.payment_windcave!)) {
      const oc = this.store.order_config.s;
      const wc = r.payment_windcave!;
      const wcAllEnabled = wc.enabled_methods.length === 0;
      const windcaveCardEnabled = wcAllEnabled || wc.enabled_methods.indexOf("card") !== -1;
      const windcaveA2AEnabled = wcAllEnabled || wc.enabled_methods.indexOf("a2a") !== -1;
      const windcaveCardAvailable =
        wc.restrict_services_card.length === 0 ||
        wc.restrict_services_card.indexOf(oc.config_service) !== -1;
      const windcaveA2AAvailable =
        wc.restrict_services_a2a.length === 0 ||
        wc.restrict_services_a2a.indexOf(oc.config_service) !== -1;
      if (windcaveCardEnabled && windcaveCardAvailable) {
        methods.push({
          type: "windcave",
          subtype: "windcave_card",
          label: "Card Online",
          label_delivery: "Card Online",
        });
      }
      if (windcaveA2AEnabled && windcaveA2AAvailable) {
        methods.push({
          type: "windcave",
          subtype: "windcave_a2a",
          label: "Bank Transfer",
          label_delivery: "Bank Transfer",
        });
      }
    }

    if (r.payment_bambora!.active && this.isPaymentMethodValid(r.payment_bambora!)) {
      methods.push({
        type: "bambora",
        label: r.payment_bambora!.label,
        label_delivery: r.payment_bambora!.label_delivery,
      });
    }

    if (r.payment_paymark_eftpos!.active && this.isPaymentMethodValid(r.payment_paymark_eftpos!)) {
      methods.push({
        type: "paymark_eftpos",
        label: r.payment_paymark_eftpos!.label || "Paymark Online EFTPOS",
        label_delivery: r.payment_paymark_eftpos!.label_delivery || "Paymark Online EFTPOS",
      });
    }

    if (r.payment_poli!.active && this.isPaymentMethodValid(r.payment_poli!)) {
      methods.push({
        type: "poli",
        label: r.payment_poli!.label,
        label_delivery: r.payment_poli!.label_delivery,
      });
    }

    return methods;
  }
  @computed get validate() {
    const r = this.store.r;
    const oc = this.store.order_config.s;

    const isDineIn = oc.config_service === "dine_in";
    const isKiosk = this.store.view.s.is_kiosk;

    const customer_name_optional =
      this.store.view.s.is_kiosk ||
      (isDineIn && this.store.r.service_dine_in!.customer_name_optional);

    const customer_email_optional =
      this.store.view.s.is_kiosk ||
      (isDineIn && this.store.r.service_dine_in!.customer_email_optional);

    const customer_phone_optional =
      this.store.view.s.is_kiosk ||
      (isDineIn && this.store.r.service_dine_in!.customer_phone_optional);

    const checkout = this.s;
    let error = null;
    const address_field = document.getElementById("address_field_imp") as HTMLInputElement;
    const { logged_in_only } = r.setting!;

    if (address_field && address_field.value) error = "something_went_wrong";
    if (logged_in_only && !this.store.customer.isLoggedIn) error = "something_went_wrong";
    if (oc.config_due === "now" && !this.store.serviceOpen()) error = "closed";
    if (!oc.payment_type) error = "required_payment";

    if (!isDineIn || !isKiosk) {
      if (!oc.customer_name) error = "required_name";
      if (!oc.customer_phone) error = "required_phone";
      if (!oc.customer_email) error = "required_email";
      if (!isEmail(oc.customer_email)) error = "invalid_email";
    } else {
      if (!customer_name_optional && !oc.customer_name) error = "required_name";
      if (!customer_phone_optional && !oc.customer_phone) error = "required_phone";
      if (!customer_email_optional && !oc.customer_email) error = "required_email";
      if (!customer_email_optional && !isEmail(oc.customer_email)) error = "invalid_email";
    }

    if (!checkout.accept_terms) error = "accept_terms";
    if (this.store.cart.ageRestrictedItemsInCart && !checkout.age_confirmation)
      error = "age_confirmation";
    if (oc.payment_type === "bambora" && checkout.card_error) error = "invalid_cc_number";
    if (oc.payment_type === "bambora" && !checkout.card_token) error = "invalid_cc_number";
    return error;
  }

  @action async order_commence(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (this.s.loading) return;
    logger.info("CHECKOUT");
    const { store } = this;
    const checkout = store.checkout.s;
    try {
      const error = this.validate;
      if (error) {
        this.s.error = error;
        return;
      }

      this.s.loading = true;
      const noStock = await this.store.cart.checkStock();
      if (noStock.dishes.length > 0 || noStock.options.length > 0) {
        this.store.modal.back();
        this.s.loading = false;
        return;
      }

      this.s.error = "";

      // CONSTRUCT ORDER
      const requestArgs = await this.order_build();

      const { order, orderPayments } = requestArgs;

      console.log(toJS(requestArgs));

      /*
      const validate = await this.store.api.order_validate({ order, fees, dishes });

      if (validate.outcome) {
        this.order_error(undefined, validate.message);
        return;
      }
      */

      const payment = orderPayments[0];

      if (payment.type === "poli") {
        await this.poli_init(requestArgs);
      } else if (payment.type === "windcave") {
        await this.windcave_init(requestArgs);
      } else if (payment.type === "paymark_eftpos") {
        await this.paymark_eftpos_init(requestArgs);
      } else {
        if (payment.type === "stripe") {
          console.log("Stripe: create payment method");
          const stripe = window.stripe!;
          const stripeCard = window.stripeCard!;
          const stripeData = await stripe.createPaymentMethod({
            type: "card",
            card: stripeCard,
            billing_details: {
              name: order.customer_name,
              email: order.customer_email,
              phone: order.customer_phone,
            },
          });
          const { paymentMethod, error: errorPayment } = stripeData;

          if (errorPayment || !paymentMethod) {
            console.log("Stripe: error in payment method", errorPayment);
            this.order_error(undefined, errorPayment?.message || "payment_fail");
            return;
          }

          if (!paymentMethod.card?.three_d_secure_usage?.supported) {
            console.log("Stripe: card doesnt support 3ds secure");
            this.order_error(undefined, "card_unsupported");
            return;
          }

          // @ts-ignore - docs say cvc_check is a string, types say boolean
          if (paymentMethod.card?.checks.cvc_check === "fail") {
            console.log("Stripe: fail CVC check");
            this.order_error(undefined, "invalid_cvc");
            return;
          }

          console.log("Stripe: payment method created", paymentMethod);
          requestArgs.stripePaymentMethodId = paymentMethod.id;
        }

        if (payment.type === "bambora") {
          requestArgs.bamboraToken = checkout.card_token;
        }

        await this.order_complete(requestArgs);
      }
    } catch (e) {
      this.order_error(e);
    }
  }
  @action async order_complete(data: T.API.StoresOrderCreateReq) {
    const store = this.store;
    // const c = store.customer.s.item;
    const response = await store.api.order_create(data);

    // HANDLE FAIL OUTCOME
    if (response.outcome) {
      if (response.message === "stripe_requires_action") {
        console.log("Stripe: handle requires action");
        const stripe = window.stripe!;
        const { error: errorAction, paymentIntent } = await stripe.handleCardAction(
          response.stripe_payment_intent_client_secret!
        );
        if (errorAction) {
          console.log("Stripe: error in requires action", errorAction);
          this.order_error(undefined, "payment_fail");
          return;
        }
        console.log("Stripe: requires action success", paymentIntent);
        data.stripePaymentIntentId = paymentIntent!.id;
        delete data.stripePaymentMethodId; // needed to confirm intent
        await this.order_complete(data);
        return;
      }
      this.order_error(undefined, response.message);
      return;
    }

    // HANDLE SUCCESS
    const { order, new_order_id } = response;

    // UPDATE CUSTOMER & ORDER PROFILE

    /*
    if (customer.type !== "guest") {

      store.customer.update({ item: customer });

      // UPDATE ORDER HISTORY
      const order_history = [...store.order_history.s.items];
      order_history.splice(order_history.length - 1, 1);
      order_history.unshift(order);
      store.order_history.update({ items: order_history });

    }
    */

    // SAVE PROMO TO LOCALSTORAGE IF NOT LOGGED IN
    if (order.promo_old_id) {
      localStorage.setItem(order.promo_old_id, "true");
    }

    if (window.stripeCard) {
      window.stripeCard.clear();
    }

    // CLOSE MODAL
    store.modal.s.active = "";
    store.modal.s.last = "";

    // STOP LOADING, REMOVE ERROR & CLEAR CHECKOUT BUT PRESERVE CUSTOMER DETAILS
    this.update(this.initialState());
    store.cart.clear();
    store.order_config.updateD(store.order_config.initialDisposableSate());
    store.order_config.clearTimers(); // expiry timer

    if (data.acceptMarketing) {
      store.customer.updateItem({ accept_marketing: true });
    }

    if (store.view.s.is_kiosk) {
      store.order_config.update({
        ...store.order_config.initialState(),
        id: new_order_id,
      });
      store.modal.show("order-receipt-kiosk");
    } else {
      store.order_config.update({
        ...store.order_config.initialState(),
        id: new_order_id,
        customer_name: store.order_config.s.customer_name,
        customer_email: store.order_config.s.customer_email,
        customer_phone: store.order_config.s.customer_phone,
        origin: store.order_config.s.origin,
      });
      store.order.update({ item: order, loading: false, error: "" });
      store.router.push(`/order/${order.id}`);
    }

    untrusive.stop();

    fbq("track", "Purchase", {
      value: parseFloat(centsToDecimal(order.payment_total)),
      num_items: order.dishes.reduce((a, v) => a + v.quantity, 0),
      currency: "NZD",
    });
  }
  async order_build(): Promise<T.API.StoresOrderCreateReq> {
    const oc = this.store.order_config.s;
    const checkout = this.s;
    const cart = this.store.cart;

    const address = cloneDeepSafe(oc.config_address);

    if (oc.config_service === "delivery" && checkout.no_contact_delivery) {
      address!.notes = address!.notes
        ? `No contact delivery - ${address!.notes}`
        : `No contact delivery`;
    }

    if (address && address.coords && address.coords.coordinates) {
      if (typeof address.coords.coordinates[0] === "string") {
        address.coords.coordinates[0] = parseFloat(address.coords.coordinates[0]);
      }
      if (typeof address.coords.coordinates[1] === "string") {
        address.coords.coordinates[1] = parseFloat(address.coords.coordinates[1]);
      }
    }

    console.log("PLACE ORDER", oc.id);

    const order: RestaurantOrderCreateFragment = {
      id: oc.id,
      notes: oc.notes,
      origin: oc.origin,
      ready_at: oc.ready_at,
      delivery_at: oc.delivery_at,
      customer_id: this.store.customer.s.item?.id || null,
      customer_name: oc.customer_name,
      customer_phone: oc.customer_phone,
      customer_email: oc.customer_email,
      customer_ip_address: "",
      config_service: oc.config_service as RestaurantServiceTypeEnumEnum,
      config_due: oc.config_due as RestaurantServiceTimeEnumEnum,
      config_timestamp: oc.config_timestamp,
      config_zone_id: oc.config_zone_id,
      config_zone_name: oc.config_zone_name,
      config_driving_time: oc.config_driving_time,
      config_distance: oc.config_distance,
      config_table_id: oc.config_table_id,
      config_table_name: oc.config_table_name,
      config_num_people: typeof oc.config_num_people === "string" ? 0 : oc.config_num_people,
      payment_total: cart.total,
      payment_cart: cart.totalCart,
      payment_tip: oc.payment_tip,
      payment_tax: cart.tax,
      promo_old_id: oc.promo_old_id,
      promo_old_code: oc.promo_old_code,
      promo_old_discount: cart.discount,
    };

    try {
      order.customer_ip_address = geotargetly_ip();
    } catch (e) {
      if (e && typeof e.message === "string" && e.message.indexOf("is not defined") === -1) {
        logger.captureException(e);
      }
    }

    const origin = this.store.router.getOrigin();

    const paymentId = await this.store.api.gen_id({ table: "restaurant_order_payment" });

    let payment_type_sub = oc.payment_type_sub || null;
    if (oc.payment_type === "stripe") {
      if (!!this.store.r.payment_stripe?.acc_id && !!this.store.r.payment_stripe.acc_active) {
        payment_type_sub = "stripe_e";
      }
    }

    const payment: RestaurantOrderPaymentFragment = {
      id: paymentId.id,
      order_id: "", // will be deleted
      created: Date.now(),
      type: oc.payment_type as RestaurantPaymentTypeEnumEnum,
      type_id: oc.payment_custom_id || null,
      type_sub: payment_type_sub,
      amount: order.payment_total,
      // The status will be set correctly in the API
      status: "success",
    };

    const fees = cloneDeepSafe(cart.fees);

    const dishes = orderDishesSort(this.store.menusAll, cloneDeepSafe(oc.dishes));

    // Don't set dish payment ID as its just an assumption
    /*
    for (const [i] of dishes.entries()) {
      dishes[i].payment_id = payment.id;
    }
    */

    // If customer has enabled marketing or it is toggled in checkout
    const acceptMarketing =
      this.store.customer.s.item?.accept_marketing || checkout.accept_marketing;

    return {
      order: order,
      address: address,
      fees: fees,
      dishes: dishes,
      orderPayments: [payment],
      origin: origin,
      acceptMarketing: acceptMarketing,
    };
  }

  @action async order_callback() {
    const { store } = this;
    try {
      const router = store.router.s;
      const order_id = router.query.order_callback;
      const type = router.query.type;
      const outcome = router.query.outcome as "fail" | "cancelled";

      store.router.push("/");
      store.loader.update({
        active: false,
        opacity: 0,
        title: "",
        message: "",
      });

      const restored = store.sessionRestore(order_id);

      setTimeout(() => {
        try {
          document.getElementById("checkout-button")?.scrollIntoView();
        } catch (e) {
          logger.captureException(e);
        }
      }, 1000);

      if (!restored) return;

      if (type === "poli" || type === "windcave") {
        if (outcome === "cancelled" || outcome === "fail") {
          this.order_error(undefined, `payment_${outcome}`);
          return;
        }

        /*
        const token = router.query.token;
        const order = await this.order_build();
        order._id = order_id;
        order.payment.status = "success";
        order.payment.reference = token;

        await this.order_complete({ order });
        */
      } else {
        throw new Error(`Unknown order callback type ${type}`);
      }
    } catch (e) {
      this.order_error(e);
    }
  }
  check_order_callback = () => {
    if (typeof window !== "undefined") {
      if (this.store.router.s.query.order_callback) {
        this.order_callback();
      }
    }
  };

  @action order_error = (e?: any, message?: string) => {
    const isNetworkError = e && e.message === "Network Error";
    const isStripeError = e && (e?.message || "").indexOf("createPaymentMethod") !== -1;
    if (typeof e !== "undefined" && !isNetworkError) {
      logger.captureException(e);
    }
    this.s.error = isNetworkError
      ? "internet"
      : isStripeError
      ? "stripe_loading_error"
      : message || "something_went_wrong";
    this.s.loading = false;
    untrusive.stop();
    this.store.loader.update({
      active: false,
      opacity: 0,
      title: "",
      message: "",
    });
  };

  @action poli_init = async (data: T.API.StoresOrderCreateReq) => {
    const { store } = this;

    const origin = store.router.getOrigin();

    const response = await store.api.order_create_poli({
      ...data,
      origin: origin,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave(data.order.id);

    window.location.replace(response.data.NavigateURL);
  };
  @action windcave_init = async (data: T.API.StoresOrderCreateReq) => {
    const { store } = this;

    const origin = store.router.getOrigin();

    const response = await store.api.order_create_windcave({
      ...data,
      origin: origin,
    });

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    store.sessionSave(data.order.id);

    window.location.replace(response.redirect_url);
  };

  @action paymark_eftpos_init = async (data: T.API.StoresOrderCreateReq) => {
    const { store } = this;

    const response = await store.api.order_create_paymark_eftpos(data);

    if (response.outcome) {
      this.order_error(undefined, response.message);
      return;
    }

    this.update({
      paymark_eftpos_modal: true,
    });

    window.openjs.init({
      elementId: "paymark-eftpos-iframe",
      sessionId: response.session_id,
    });

    this.paymark_check_interval = setInterval(async () => {
      const { status } = await store.api.order_create_paymark_eftpos_check({
        session_id: response.session_id,
      });
      if (status === "AUTHORISED") {
        window.location.href = `/order/${data.order.id}`;
      } else if (["DECLINED", "EXPIRED", "ERROR"].indexOf(status) !== -1) {
        const errorMessage = status === "DECLINED" ? "payment_declined" : "payment_fail";
        this.paymark_eftpos_cancel(errorMessage);
      } else {
        // waiting, do nothing
      }
    }, 3500);
  };
  @action paymark_eftpos_cancel = (errorMessage?: string) => {
    clearInterval(this.paymark_check_interval);
    this.update({
      paymark_eftpos_modal: false,
    });
    this.order_error(undefined, errorMessage || "payment_cancelled");
    // Don't call the below line by the off chance payment ends up going thru
    // this.store.api.order_create_paymark_eftpos_cancel({ session_id: "" }).catch(logger.captureException);
  };

  @action update = (data: Partial<CheckoutState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof CheckoutState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof CheckoutState] = value;
        }
      }
    }
  };
}
