import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { FastField, FastFieldProps, Form, Formik, FormikHelpers } from "formik";
import { StripeCardForm } from "../checkout/methods/stripe";
import { withTheme } from "styled-components";
import { cloneDeepSafe, CoreUtils, logger, decimalToCents } from "@lib/common";
import { FormGroup, Input, Button, LinkTag, RotateLoader } from "@lib/components";
import { untrusive } from "../../../../client/untrusive";

interface Props {}
interface State {
  mounted: boolean;
  stripeError: string;
  error: string;
  success: boolean;
}

type FormValues = T.API.StoresCustomPaymentRequest;

const errors: { [key: string]: string } = {
  generic: "Something went wrong, try again or contact us",
  stripe_generic: "Credit card error, refresh the page and try again",
  payment_fail: "Payment failed, please try again or select another payment method",
  payment_cancelled: "Payment cancelled, please try again or select another payment method",
  payment_error: "Error processing online payment, try again or pick another payment method",
  payment_declined: "Your payment method was declined",
  card_declined: "Your card was declined and payment couldn't be processed",
};

const initialValues: FormValues = {
  name: "",
  email: "",
  reference: "",
  amount: "",
  token: "",
};

@inject("store")
@observer
class CustomPaymentModalClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mounted: false,
      stripeError: "",
      error: "",
      success: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
    const root = document.getElementById("root")!;
    root.style.overflowY = "auto";
  }

  componentWillUnmount() {
    const root = document.getElementById("root")!;
    root.style.overflowY = "hidden";
  }

  submit = async (v: FormValues, form: FormikHelpers<FormValues>) => {
    const stop = () => {
      untrusive.stop();
      form.setSubmitting(false);
    };
    const error = (message: string, e?: any) => {
      stop();
      this.setState({ error: message });
      if (e) {
        logger.captureException(e);
      }
    };
    try {
      const { store } = this.injected;

      v = cloneDeepSafe(v);

      if (v.amount) {
        v.amount = decimalToCents(v.amount);
      }

      untrusive.start();
      form.setSubmitting(true);
      this.setState({ error: "" });

      const stripe = window.stripe!;
      const stripeCard = window.stripeCard!;

      const { paymentMethod, error: errorPayment } = await stripe.createPaymentMethod(
        "card",
        stripeCard,
        {
          billing_details: {
            name: v.name,
            email: v.email,
          },
        }
      );

      if (errorPayment) {
        console.log(errorPayment);
        return error("payment_fail");
      }

      v.paymentMethodId = paymentMethod!.id;

      const response = await store.api.custom_payment(v);

      if (response.outcome) {
        if (response.message !== "stripe_requires_action") {
          return error(response.message);
        }

        let intent;
        let errorAction;
        if (v.amount) {
          const result = await stripe.handleCardAction(response.intent_client_secret!);
          intent = result.paymentIntent;
          errorAction = result.error;
        } else {
          const result = await stripe.handleCardSetup(response.intent_client_secret!, {});
          intent = result.setupIntent;
          errorAction = result.error;
        }

        if (errorAction) {
          console.log(errorAction);
          return error("payment_fail");
        }

        v.intentId = intent!.id;

        const secondResponse = await store.api.custom_payment(v);
        if (secondResponse.outcome) {
          return error(secondResponse.message);
        }
      }

      this.setState({ success: true });
    } catch (e) {
      error("generic", e);
    } finally {
      stop();
    }
  };

  render() {
    const { error, success } = this.state;
    const { mounted } = this.state;
    const { store } = this.injected;

    const r = store.r;

    const stripe = r.payment_stripe!;

    const enabled =
      (!!stripe.stripe_user_id || !!stripe.publishable_key) && !!stripe.custom_payment_email;

    if (!stripe || !enabled) {
      return (
        <div className="flex justify-center items-center mt-6" style={{ minHeight: "80vh" }}>
          <div className="w-full p-5 text-center" style={{ maxWidth: "500px" }}>
            <h3 className="">{r.name} Payment Form</h3>
            <p className="my-3 leading-relaxed">
              This online payment page has not been configured correctly. Please contact the site
              owner to rectify the issue.
            </p>
            <p className="small">
              <LinkTag href="/">Back To Home</LinkTag>
            </p>
          </div>
        </div>
      );
    }

    const logo = !r.website!.top_nav_logo_image ? null : (
      <div className="mb-4">
        <img
          alt="Business logo"
          className="rounded mx-auto"
          style={{ maxHeight: "60px" }}
          src={
            CoreUtils.image.uc(r.website!.top_nav_logo_image, { fm: "png", h: 60, fit: "clip" })!
          }
        />
      </div>
    );

    return (
      <div className="flex justify-center items-center mt-6" style={{ minHeight: "80vh" }}>
        <div className="w-full p-5" style={{ maxWidth: "460px" }}>
          <>
            {!mounted && <RotateLoader size={3} />}

            {mounted && (
              <>
                <FormGroup contentClassName="text-center">
                  {logo}
                  <h3 className="">{r.name} Payment Form</h3>
                  <p className="mt-2 text-md">
                    You have reached our online payment form. Please complete the fields below to
                    make a payment. If an amount is not entered, your details will be saved but you
                    will not be charged.
                  </p>
                </FormGroup>

                {success && (
                  <FormGroup contentClassName="text-center">
                    <p className="text-success leading-relaxed">
                      Your payment was successfully processed and your details have been saved.
                      Thank you
                    </p>
                  </FormGroup>
                )}

                {!success && (
                  <Formik<FormValues> initialValues={initialValues} onSubmit={this.submit}>
                    {(form) => {
                      const { isSubmitting, submitCount } = form;
                      const showFormError = submitCount > 0 && this.state.error;
                      return (
                        <Form>
                          <FormGroup>
                            <FastField name="name">
                              {({ field }: FastFieldProps<any>) => (
                                <div className="mb-2">
                                  <Input
                                    {...field}
                                    type="text"
                                    placeholder="Full Name"
                                    required={true}
                                  />
                                </div>
                              )}
                            </FastField>

                            <FastField name="email">
                              {({ field }: FastFieldProps<any>) => (
                                <div className="mb-2">
                                  <Input
                                    {...field}
                                    type="email"
                                    placeholder="E-mail Address"
                                    required={true}
                                  />
                                </div>
                              )}
                            </FastField>

                            <FastField name="reference">
                              {({ field }: FastFieldProps<any>) => (
                                <div className="mb-2">
                                  <Input
                                    {...field}
                                    type="text"
                                    placeholder="Payment Ref."
                                    required={true}
                                  />
                                </div>
                              )}
                            </FastField>

                            <FastField name="amount">
                              {({ field }: FastFieldProps<any>) => (
                                <div className="mb-2">
                                  <Input
                                    {...field}
                                    type="number"
                                    min="0"
                                    step="0.01"
                                    placeholder="Amount ($)"
                                  />
                                </div>
                              )}
                            </FastField>

                            <div>
                              <StripeCardForm />
                            </div>
                          </FormGroup>

                          {showFormError && <FormGroup no_border={true} error={errors[error]} />}

                          <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                            {isSubmitting && (
                              <RotateLoader
                                size={2}
                                color={this.injected.theme.colors.primary_text}
                              />
                            )}
                            {!isSubmitting && "Submit Payment"}
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </>
            )}
          </>
        </div>
      </div>
    );
  }
}

// @ts-ignore
export const CustomPaymentModal = withTheme(CustomPaymentModalClass);
