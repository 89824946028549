import * as React from "react";
import { MobxComponent } from "../../../../mobx/component";
import { ModalDropContent, Switch } from "@lib/components";
import { OptionListWrapper, OptionListItem } from "../components";
import { inject, observer } from "mobx-react";
import { RestaurantMenuDishIngredientFragment } from "@lib/types/graphql";
import { action, computed } from "mobx";
import { menuDish, orderDish, orderDishMut } from "@lib/common";

interface Props {
  selectedChoiceId: string;
}
interface State {}

@inject("store")
@observer
export class DishModalIngredients extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  @action toggleIngredient = (ing: RestaurantMenuDishIngredientFragment) => {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const od = store.dish.s.orderDish!;
    orderDishMut.ingredientToggle({
      ing: ing,
      od: od,
      odChoiceId: selectedChoiceId,
      add: (ingredient) => this.ingredientsRemoved.push(ingredient),
      remove: (index) => this.ingredientsRemoved.splice(index, 1),
    });
  };

  @computed get ingredientsRemoved() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const od = store.dish.s.orderDish!;
    return orderDish.ingredientsRemoved({ od, odChoiceId: selectedChoiceId });
  }

  @computed get ingredients() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const md = store.dish.s.menuDish!;
    const od = store.dish.s.orderDish!;
    return menuDish.ingredients({ md, od, menus: store.menusAll, odChoiceId: selectedChoiceId });
  }

  render() {
    const { store } = this.injected;
    const { selectedChoiceId } = this.props;
    const od = store.dish.s.orderDish!;
    if (this.ingredients.length === 0) return null;
    return (
      <ModalDropContent
        title={(() => {
          return (
            <div className="">
              <p className="text-md font-semibold">Ingredients</p>
              <p className="text-md">Un-check to remove</p>
            </div>
          );
        })()}
        paddingtb={15}
        paddinglr={25}
        cPaddingtb={15}
        cPaddinglr={25}>
        <OptionListWrapper>
          {this.ingredients.map((ing, i) => {
            return (
              <OptionListItem key={ing.id} onClick={() => this.toggleIngredient(ing)}>
                <p className="pr-1 text-md">{ing.name}</p>
                <Switch
                  id={`${ing.id}-${i}-${selectedChoiceId || "base"}`}
                  name={`${ing.id}-${i}-${selectedChoiceId || "base"}`}
                  checked={
                    orderDish.ingredientActive({ id: ing.id, od, odChoiceId: selectedChoiceId })
                      .active
                  }
                  onChange={() => this.toggleIngredient(ing)}
                />
              </OptionListItem>
            );
          })}
        </OptionListWrapper>
      </ModalDropContent>
    );
  }
}
