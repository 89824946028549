import * as React from "react";

type Props = {
  mapId: string;
  mapEl: React.ReactElement;
  mapResize: any;
  deliveryTrackable: boolean;
  deliveryCompleted: boolean;
  driverLat: number | undefined;
  driverLng: number | undefined;
  coordsPickup: [number, number];
  coordsDropoff: [number, number];
  colorDriver: string;
  colorDriverBorder: string;
  colorPin: string;
};

export function DriverTrackingMap(props: Props) {
  const {
    mapId,
    mapEl,
    mapResize,
    deliveryTrackable,
    deliveryCompleted,
    driverLat,
    driverLng,
    coordsPickup,
    coordsDropoff,
    colorDriver,
    colorDriverBorder,
    colorPin,
  } = props;

  const map = React.useRef<mapboxgl.Map | null>(null);
  const driverCoords = React.useRef<{ lat: number; lng: number } | null>(null);
  const isAnimation = React.useRef(false);

  const trackingEnabled =
    deliveryTrackable && typeof driverLat === "number" && typeof driverLng === "number";

  // Initialize the map
  React.useEffect(() => {
    const el = document.getElementById(mapId)!;
    map.current = new mapboxgl.Map({
      container: el,
      style: "mapbox://styles/booknorder/ckk6wplop0rmf17pgjta63w5c", // mapbox://styles/mapbox/streets-v11",
      center: coordsPickup,
      zoom: 4,
    });

    const markerCollection: GeoJSON.FeatureCollection = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {
            description: "PICKUP",
          },
          geometry: {
            type: "Point",
            coordinates: coordsPickup,
          },
        },
        {
          type: "Feature",
          properties: {
            description: "DROPOFF",
          },
          geometry: {
            type: "Point",
            coordinates: coordsDropoff,
          },
        },
      ],
    };

    map.current.on("load", () => {
      if (!map.current) return;

      // Set current driver coords
      if (trackingEnabled) {
        driverCoords.current = { lat: driverLat!, lng: driverLng! };
      }

      // Add map sources
      map.current.addSource("places", {
        type: "geojson",
        data: markerCollection,
      });
      map.current.addSource("driver", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: trackingEnabled
            ? [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [driverLng!, driverLat!],
                  },
                },
              ]
            : [],
        },
      });

      // Add map layers
      map.current.addLayer({
        id: "places-layer",
        source: "places",
        type: "symbol",
        layout: {
          "text-field": ["get", "description"],
          "text-variable-anchor": ["bottom"],
          "text-offset": [0, -3.1],
          "text-font": ["DIN Pro Bold"],
          "text-size": 12,
        },
      });
      map.current.addLayer({
        id: "driver-layer",
        type: "circle",
        source: "driver",
        paint: {
          "circle-color": colorDriver,
          "circle-radius": 7,
          "circle-stroke-width": 2,
          "circle-stroke-color": colorDriverBorder,
        },
      });

      // Add map markers
      new mapboxgl.Marker({ color: colorPin }).setLngLat(coordsPickup).addTo(map.current);

      new mapboxgl.Marker({ color: colorPin }).setLngLat(coordsDropoff).addTo(map.current);

      // Fit map to the markers
      map.current.fitBounds([coordsPickup, coordsDropoff], {
        padding: 60,
      });

      // This is needed in case job is booked in after order is placed
      // The customers map panel expands out slowly to the right
      // Resize needs to be called so map fits render area correctly after fully expanded
      setTimeout(() => {
        map.current?.resize();
      }, 1000);
    });

    return () => {
      map.current?.remove();
      map.current = null;
    };
  }, []);

  // Track driver location
  React.useEffect(() => {
    const source = map.current?.getSource("driver");

    // Remove driver location from map once delivery is completed
    if (deliveryCompleted && !!map.current) {
      if (source?.type === "geojson") {
        requestAnimationFrame(() => {
          source.setData({
            type: "FeatureCollection",
            features: [],
          });
        });
      }
    }

    // If no lat, lng or delivery tracking, halt
    if (!trackingEnabled) return;
    if (!source || source.type !== "geojson") return;

    if (!driverCoords.current) {
      // No existing driver location, set data for first time
      source.setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "Point",
              coordinates: [driverLng!, driverLat!],
            },
          },
        ],
      });
      driverCoords.current = { lat: driverLat!, lng: driverLng! };
    } else {
      if (isAnimation.current) return;

      // If existing driver location, animate between current and new location
      const numDeltas = 20;
      const deltaLng = (driverLng! - driverCoords.current.lng) / numDeltas;
      const deltaLat = (driverLat! - driverCoords.current.lat) / numDeltas;
      let animationIndex = 0;

      function animate(ts: number) {
        if (source?.type !== "geojson") return;
        const newLng = driverCoords.current!.lng + deltaLng * animationIndex;
        const newLat = driverCoords.current!.lat + deltaLat * animationIndex;
        if (animationIndex === numDeltas) {
          isAnimation.current = false;
          driverCoords.current = { lat: driverLat!, lng: driverLng! };
          return;
        }
        animationIndex++;
        source.setData({
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [newLng, newLat],
              },
            },
          ],
        });
        requestAnimationFrame(animate);
      }

      isAnimation.current = true;
      requestAnimationFrame(animate);
    }
  }, [trackingEnabled, driverLat, driverLng, deliveryCompleted]);

  // Resize map if this changes
  React.useEffect(() => {
    if (map.current) {
      map.current.resize();
    }
  }, [mapResize]);

  return mapEl;
}
