import * as React from "react";
import { inject, observer } from "mobx-react";
import { Modal, ModalTitle } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";
import { CustomerOrderHistory } from "../../logic/order-history";

interface Props {}
interface State {}

@inject("store")
@observer
class OrderHistoryModalClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    const c = store.customer.s.item;
    return (
      <Modal
        id="modal-order-history"
        width={420}
        closeButton={true}
        active={store.modal.isVisible("order-history")}
        close={() => store.modal.toggle("order-history")}>
        {c && (
          <>
            <ModalTitle paddinglr={25} paddingtb={20} className="rounded-t">
              <div>
                <h4 className="">Quick Re-Order</h4>
                <p className="text-md">Easily order the items from your previous orders</p>
              </div>
            </ModalTitle>
            <CustomerOrderHistory />
          </>
        )}
      </Modal>
    );
  }
}

export const OrderHistoryModal = OrderHistoryModalClass;
