import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { FaUser } from "react-icons/fa";
import { CustomerOrderHistory } from "../../logic/order-history";
import { styled, Modal, ModalContent, CircleIcon, ModalDropContent } from "@lib/components";

interface Props {}
interface State {}

const ProfilePic = styled("img")`
  width: 48px;
  height: 48px;
  border-radius: 30px;
`;

const DetailRow = styled("div")`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.border};
  padding-bottom: 5px;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
`;

@inject("store")
@observer
class CustomerModalClass extends MobxComponent<Props, State> {
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const { store } = this.injected;
    if (store.modal.isVisible("customer")) {
      store.order_history.getHistory(1);
    }
  }

  render() {
    const { store } = this.injected;
    const c = store.customer.s.item;
    return (
      <Modal
        id="customer-modal"
        width={420}
        closeButton={true}
        active={store.modal.isVisible("customer")}
        close={() => store.modal.toggle("customer")}>
        {c && (
          <div>
            <ModalContent paddingtb={30}>
              <div className="flex flex-col items-center justify-center text-center">
                {c.avatar && <ProfilePic src={c.avatar} />}
                {!c.avatar && <CircleIcon icon={<FaUser />} />}
                <p className="mt-2 text-lg font-medium">{store.customer.displayName}</p>
              </div>

              {/*
              <div className="mt-4 text-md">
                <DetailRow>
                  <p>{t("store.modals.customer.headings.sessions")}</p>
                  <p>{c.stats.sessions}</p>
                </DetailRow>
                <DetailRow>
                  <p>{t("store.modals.customer.headings.orders")}</p>
                  <p>{c.stats.orders}</p>
                </DetailRow>
                <DetailRow>
                  <p>{t("store.modals.customer.headings.spend")}</p>
                  <p>{t("currency", { value: c.stats.sales })}</p>
                </DetailRow>
              </div>
              */}
            </ModalContent>

            <ModalDropContent
              title={<h4>Order History</h4>}
              paddingtb={15}
              cPaddinglr={0}
              cPaddingtb={0}>
              <CustomerOrderHistory />
            </ModalDropContent>
          </div>
        )}
      </Modal>
    );
  }
}

export const CustomerModal = CustomerModalClass;
