import * as React from "react";
import { observer } from "mobx-react";
import { RestaurantMenuCategoryFragment } from "@lib/types/graphql";

interface Props {
  category: RestaurantMenuCategoryFragment;
  restricted: boolean;
  restrictedReason: string;
}

export const CategoryTitle = observer((props: Props) => {
  const { category } = props;
  return (
    <div className="pt-4">
      <h2 className="">{category.display_name || category.name}</h2>
      {props.restricted && props.restrictedReason !== "age" && (
        <p className="text-error text-xs mb-1 font-semibold">
          Un-available with your current order settings
        </p>
      )}
      {category.description && <p className="text-md text-gray-900">{category.description}</p>}
    </div>
  );
});
