import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import darken from "polished/lib/color/darken";
import { styled, Button, Tag, BlockError, OrderItems, OrderTotals } from "@lib/components";
import { RestaurantOrderFullFragment } from "@lib/types/graphql";
import { formatDate } from "@lib/common";

const Item = styled("div")``;

const Header = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => darken(0, props.theme.colors.background)};
`;

const Content = styled("div")<{ active: boolean }>`
  padding: 20px;
  display: ${({ active }) => (active ? "block" : "none")};
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  background: ${(props) => darken(0.0, props.theme.colors.background)};
`;

interface Props {
  i: number;
  o: RestaurantOrderFullFragment;
  active: boolean;
  toggleActive: () => void;
}
interface State {
  invalid: string;
}

@inject("store")
@observer
class OrderHistoryItemClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      invalid: this.injected.store.order_history.orderInvalidCheck(props.o),
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.o.id !== prevProps.o.id) {
      this.setState({ invalid: this.injected.store.order_history.orderInvalidCheck(this.props.o) });
    }
  }

  render() {
    if (!this.state) return null;
    const { store } = this.injected;
    const { o, active, toggleActive, i } = this.props;
    const { invalid } = this.state;
    console.log(invalid);
    return (
      <Item>
        <Header onClick={toggleActive} className="">
          <div className="flex items-center">
            {active ? <FaChevronUp /> : <FaChevronDown />}
            <Tag className="mx-2">#{o.number}</Tag>
            <p className="text-sm">{formatDate(o.created)}</p>
          </div>
          <div className="flex items-center">
            {!invalid && (
              <Button
                size="xxs"
                round={true}
                color={"primary-inverse"}
                onClick={(e) => store.order_history.clickOrder(e, i)}
                className="px-2 mr-1 !text-sm">
                Order
              </Button>
            )}
            <Button
              size="xxs"
              round={true}
              color="primary-inverse"
              onClick={(e) => store.order_history.clickReceipt(e, i)}
              className="px-2 !text-sm">
              Receipt
            </Button>
          </div>
        </Header>
        <Content active={active}>
          {!!invalid && (
            <BlockError
              className="mb-3"
              text="Not eligible for re-order as some of the items ordered have been modified or are no longer available"
            />
          )}
          <OrderItems items={o.dishes} />
          <OrderTotals
            dishes={o.dishes}
            fees={o.fees}
            discounts={o.discounts}
            promoCode={o.promo_old_code}
            promoDiscount={o.promo_old_discount}
            cart={o.payment_cart}
            total={o.payment_total}
            rounded={0}
            tip={o.payment_tip ?? 0}
          />
        </Content>
      </Item>
    );
  }
}

export const OrderHistoryItem = OrderHistoryItemClass;
