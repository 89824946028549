import * as React from "react";
import { observer } from "mobx-react";
import { Button, RotateLoader } from "@lib/components";
import { useStore } from "../../../../mobx/component";
import { formatTime, logger } from "@lib/common";
import { RestaurantServiceTypeEnumEnum } from "@lib/types/graphql";
import { useETA } from "../../../logic/eta/useETA";

function ConfirmedButton(props: { onClick: () => void; disabled: boolean; loading: boolean }) {
  return (
    <Button
      color="primary"
      className="w-40"
      onClick={props.onClick}
      disabled={props.disabled || props.loading}>
      {props.loading ? <RotateLoader size={2} color="white" /> : "Start Order"}
    </Button>
  );
}

const ConfirmLaterOrder = observer(() => {
  const store = useStore();
  const [reserveData, setReserveData] = React.useState({
    loading: false,
    error: "",
  });

  async function confirm() {
    try {
      const oc = store.order_config.s;

      setReserveData({
        loading: true,
        error: "",
      });

      // Expires doesnt matter, it's hardcoded to 20m in set confirmed
      const { error, expires } = await store.api.schedulerReserveTime({
        service: oc.config_service as RestaurantServiceTypeEnumEnum,
        restaurant_id: store.r.id,
        order_id: oc.id,
        ready_at: oc.ready_at,
      });

      if (error) {
        setReserveData({
          error: error,
          loading: false,
        });
      } else {
        setReserveData({
          loading: false,
          error: "",
        });
        store.order_config.setConfirmed();
      }
    } catch (e) {
      logger.captureException(e);
      setReserveData({
        loading: false,
        error: "unknown",
      });
    }
  }

  return (
    <div className="text-center mt-6 children:mb-5 last-mb-0">
      {reserveData.error === "busy" && (
        <p className="text-md text-error max-w-md mx-auto">
          Time selected no longer available as store is busy, choose another time and try again
        </p>
      )}
      {reserveData.error === "late" && (
        <p className="text-md text-error max-w-md mx-auto">
          Selected time unavailable as store will not be able to prepare your order on-time, choose
          another time and try again
        </p>
      )}
      {reserveData.error === "unknown" && (
        <p className="text-md text-error max-w-md mx-auto">
          Something went wrong, please try again
        </p>
      )}
      <ConfirmedButton disabled={false} loading={reserveData.loading} onClick={() => confirm()} />
    </div>
  );
});

const ConfirmNowOrder = observer(() => {
  const store = useStore();
  const isOpen = store.serviceOpen();
  const closesIn = store.serviceClosesIn()?.minutes || null;
  const { eta, etaRefresh } = useETA();

  if (!isOpen) {
    return (
      <div className="text-center mt-6">
        <p className="text-sm text-error">
          Sorry, this service has closed and orders are not being accepted
        </p>
      </div>
    );
  }

  if (eta.loading) {
    return (
      <div className="text-center mt-6 flex items-center">
        <RotateLoader size={3} />
      </div>
    );
  }

  if (!eta.data || eta.error) {
    return (
      <div className="text-center mt-6">
        <p className="text-sm text-error">
          Something went wrong, <a onClick={() => etaRefresh()}>refresh</a>
        </p>
      </div>
    );
  }

  if (eta.data.error) {
    return (
      <div className="text-center mt-6">
        {eta.data.error === "busy" && (
          <p className="text-sm text-error">
            Store is at max capacity and cannot accept anymore orders,{" "}
            <a onClick={() => etaRefresh()}>refresh</a>
          </p>
        )}
        {eta.data.error === "late" && (
          <p className="text-sm text-error">Store closing soon and cannot accept anymore orders</p>
        )}
      </div>
    );
  }

  // ETA greater than 40 mins
  const etaWarning = eta.data.ready_at - Date.now() > 40 * 60 * 1000;

  let etaText: React.ReactNode =
    store.order_config.s.config_service === "delivery"
      ? `Estimated delivery time is approx ${eta.data.eta_minutes} mins`
      : `Order will be ready in approx ${eta.data.eta_minutes} mins`;

  if (store.order_config.s.config_service === "delivery" && eta.data.delivery_at) {
    etaText = (
      <span>
        Estimated delivery between <u>{formatTime(eta.data.delivery_at)}</u> to{" "}
        <u>{formatTime(eta.data.delivery_at + 1000 * 60 * 10)}</u>
      </span>
    );
  }

  let etaWarningText = "";

  if (etaWarning) {
    if (store.managedDeliveryActive) {
      etaWarningText = `Delivery service busy`;
    } else {
      etaWarningText = `Restaurant busy`;
    }
  }

  return (
    <div className="text-center mt-6 children:mb-5 last-mb-0">
      {closesIn !== null && (
        <p className="!mb-3 text-sm text-error">
          Closing soon, orders must be placed within {closesIn} minutes
        </p>
      )}
      <p className="text-md">{etaText}</p>
      {etaWarningText && (
        <div className="-mt-3 flex items-center justify-center">
          <p className="text-xs font-semibold">{etaWarningText}</p>
        </div>
      )}
      <ConfirmedButton
        disabled={!isOpen}
        loading={false}
        onClick={() => store.order_config.setConfirmed()}
      />
    </div>
  );
});

export const OrderConfigConfirmed = observer(() => {
  const store = useStore();

  if (store.order_config.s.config_due === "later") return <ConfirmLaterOrder />;

  return <ConfirmNowOrder />;
});
