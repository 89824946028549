import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import { Button, SelectModal } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { cc, formatDate, Formats, formatTime, logger, momentFromFormat } from "@lib/common";
import moment from "moment-timezone";

interface Props {}
interface State {
  dateModal: boolean;
  timeModal: boolean;
}

const emptyArr: any[] = [];

@inject("store")
@observer
class OrderConfigDatetimeClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      dateModal: false,
      timeModal: false,
    };
  }

  componentDidMount() {
    const od = this.injected.store.order_config.d;
    const { query } = this.injected.store.router.s;
    // If no date and service was not preset to table bookings, show date picker
    if (!od.config_date && query.service !== "table_booking") {
      this.setState({ dateModal: true });
    }
  }

  render() {
    if (!this.state) return null;

    const { store } = this.injected;
    const { dateModal, timeModal } = this.state;
    const oc = this.injected.store.order_config.s;
    const od = this.injected.store.order_config.d;

    const date = od.config_date;

    // const times = (od.config_date && timeModal) ? store.order_config.orderTimes() : [];

    const datePickerTitle =
      oc.config_service === "table_booking" ? "Select Your Booking Date" : "Select Your Order Date";

    const timePickerTitle =
      oc.config_service === "table_booking" ? "Select Your Booking Time" : "Select Your Order Time";

    let dateButtonText = "Select Date";
    let timeButtonText = "Select Time";
    if (date) {
      const parsedDate = momentFromFormat(date, Formats.moment.date);
      dateButtonText = formatDate(parsedDate.valueOf());
    }
    if (oc.config_timestamp) {
      timeButtonText = formatTime(oc.config_timestamp);
      if (oc.config_service === "delivery" && store.r.service_delivery?.time_delivery_between) {
        timeButtonText = store.r.service_delivery.time_delivery_between;
      }
    }

    return (
      <div className="mt-6 mx-auto" style={{ maxWidth: "350px" }}>
        <div className="mx-auto flex justify-center items-center flex-no-wrap">
          <Button
            className={cn("font14 w-40 max-w-0 flex-grow", date && "!rounded-r-none !border-r-0")}
            onClick={() => this.setState({ dateModal: true })}>
            {dateButtonText}
          </Button>
          {date && (
            <Button
              className="font14 w-40 max-w-0 flex-grow !rounded-l-none"
              onClick={() => this.setState({ timeModal: true })}>
              {timeButtonText}
            </Button>
          )}
        </div>

        <SelectModal
          level={2}
          width={420}
          active={dateModal}
          title={datePickerTitle}
          innerId="order-date-select"
          options={emptyArr}
          onSelect={(o) => {
            if (o.value !== date) store.order_config.setDate(o.value);
            this.setState({ dateModal: false, timeModal: true });
          }}
          close={() => this.setState({ dateModal: false })}
          getOptions={async () => {
            try {
              if (oc.config_service === "") throw new Error(`Missing service day picker list`);

              const { days } = await store.api.schedulerListDays({
                service: oc.config_service,
              });

              return days.map((d) => {
                const parsed = moment.tz(d.date, Formats.moment.date, cc.timezone);
                let label = formatDate(parsed.valueOf());
                if (d.isClosed) label += ` (Closed)`;
                return {
                  value: d.date,
                  label: label,
                  sublabel: d.isToday ? "Today" : parsed.format("dddd"),
                  disabled: d.isClosed,
                };
              });
            } catch (e) {
              logger.captureException(e);
              throw e;
            }
          }}
        />

        <SelectModal
          level={2}
          width={420}
          active={timeModal}
          title={timePickerTitle}
          innerId="order-time-select"
          options={emptyArr}
          onSelect={(o) => {
            const value: T.API.StoreSchedulerTimeListRes["times"][0] = o.value;
            store.order_config.setTime(value.ready_at, value.delivery_at);
            this.setState({ timeModal: false });
          }}
          close={() => this.setState({ timeModal: false })}
          getOptions={async () => {
            try {
              if (oc.config_service === "") throw new Error(`Missing service time picker list`);

              if (date === "") throw new Error(`Missing date for time picker list`);

              const { times } = await store.api.schedulerListTimes({
                date: date,
                service: oc.config_service,
                driving_time_seconds: oc.config_driving_time,
              });

              if (times.length === 0) {
                return [
                  {
                    value: "",
                    disabled: true,
                    label: "No times available for this day",
                  },
                ];
              }

              if (times.length === 1 && oc.config_service === "delivery") {
                if (store.r.service_delivery?.time_delivery_between) {
                  return [
                    {
                      value: times[0],
                      disabled: false,
                      label: store.r.service_delivery.time_delivery_between,
                    },
                  ];
                }
              }

              return times.map((d) => {
                return {
                  value: d,
                  disabled: d.isClosed || d.isBusy,
                  label: d.isClosed ? "Closed" : formatTime(d.delivery_at || d.ready_at),
                  sublabel: d.isBusy ? "Busy" : "",
                };
              });
            } catch (e) {
              logger.captureException(e);
              throw e;
            }
          }}>
          {/*times.length === 0 && (
             <ModalContent paddingtb={12}>
               <p className="text-center text-md text-error">{t("store.modals.order.config.datetime.no_times_available")}</p>
             </ModalContent>
           )*/}
        </SelectModal>
      </div>
    );
  }
}

export const OrderConfigDatetime = OrderConfigDatetimeClass;
