export const Social = {
  fb: {
    login(): Promise<fb.StatusResponse> {
      return new Promise((resolve) => {
        FB.login(
          (response) => {
            resolve(response);
          },
          { scope: "email,public_profile" }
        );
      });
    },
    logout(): Promise<void> {
      return new Promise((resolve) => {
        FB.logout(() => {
          resolve();
        });
      });
    },
    getLoginStatus(): Promise<fb.StatusResponse> {
      return new Promise((resolve) => {
        FB.getLoginStatus((res) => resolve(res));
      });
    },
    getUser: (user_id: string): Promise<T.FacebookUser> => {
      return new Promise((resolve, reject) => {
        FB.api(
          `/${user_id}?fields=id,name,picture,email,age_range,gender,verified`,
          (user: any) => {
            if (user && !user.error) {
              user.id = user_id;
              resolve(user);
            } else {
              reject(user);
            }
          }
        );
      });
    },
  },
  google: {
    init(client_id: string): Promise<any> {
      return new Promise((resolve) => {
        gapi.load("auth2", () => {
          resolve(
            gapi.auth2.init({
              client_id: client_id,
              scope: "email profile",
            })
          );
        });
      });
    },
    logout() {
      return new Promise((resolve, reject) => {
        if (gapi && gapi.auth2) {
          const auth = gapi.auth2.getAuthInstance();
          if (auth) {
            auth.signOut();
          }
        }
      });
    },
  },
};
