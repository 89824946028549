import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Button, Modal, ModalContent } from "@lib/components";

interface Props {}

@inject("store")
@observer
export class PaymentPaymarkEftposModal extends MobxComponent<Props> {
  /*
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    const { store } = this.injected;
    if (store.checkout.s.paymark_eftpos_modal) {
      store.checkout.paymark_eftpos_init();
    }
  }

  start = () => {

  }

  stop = () => {

  }
  */

  render() {
    const { store } = this.injected;
    return (
      <Modal
        id="paymark-eftpos-modal"
        width="sm"
        close={() => {}}
        closeButton={false}
        active={store.checkout.s.paymark_eftpos_modal}>
        <ModalContent paddingtb={20} paddinglr={20}>
          <div id="paymark-eftpos-iframe" style={{ minHeight: "354px" }} />
          <p className="mb-4 text-md">
            Do not cancel the payment after it has been processed. Once paid, you will be
            re-directed to your order receipt shortly
          </p>
          <Button
            type="button"
            full={true}
            color="primary-inverse"
            onClick={() => store.checkout.paymark_eftpos_cancel()}>
            Cancel
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}
