import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { OrderTotals } from "@lib/components";

export const OrderCartTotals = observer(() => {
  const { r, cart, order_config } = useStore();
  return (
    <OrderTotals
      dishes={cart.dishes}
      fees={cart.fees}
      discounts={[]}
      promoCode={order_config.s.promo_old_code}
      promoDiscount={cart.discount}
      cart={cart.totalCart}
      total={cart.total}
      tip={order_config.s.payment_tip}
      rounded={0}
    />
  );
});
