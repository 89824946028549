import * as React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { Input, RotateLoader, SearchModal, ModalContent } from "@lib/components";
import { useStore } from "../../../../mobx/component";
import { formatAddressObjectDisplay, logger, OrderUtils } from "@lib/common";
import { AddressFragment } from "@lib/types/graphql";
import { v4 as uuid } from "uuid";
import { MapboxAPI } from "../../../../libs/mapbox";

export interface AddressSelectOption {
  type: "google" | "api" | "alert" | "mapbox";
  label: string;
  disabled?: boolean;
}

function getGoogleComponent(names: string[], components: google.maps.GeocoderAddressComponent[]) {
  let value = "";
  for (const component of components || []) {
    for (const n of names) {
      if (component.types.indexOf(n) !== -1) {
        value = component.long_name;
      }
    }
  }
  return value;
}
function getMapboxComponent(names: string[], place: T.Mapbox.GeocodeAutocompleteResultFeature) {
  let value = "";
  for (const component of place.context) {
    for (const n of names) {
      if (component.id.indexOf(n) !== -1) {
        value = component.text;
      }
    }
  }
  return value;
}

function AdditionalInfoInput(props: {
  name: string;
  longName: string;
  isLast: boolean;
  isFirst: boolean;
  value: string;
  onChange: (value: string) => void;
  maxLength?: number;
  pattern?: string;
}) {
  return (
    <div
      className={cn([
        "flex items-center border",
        !props.isLast && "border-b-0",
        props.isLast && "rounded-b",
        props.isFirst && "border-t-0",
      ])}>
      <div className="px-3 border-r w-24 sm:w-40 flex-shrink-0">
        <p className="text-sm text-gray-900 text-right sm:hidden">{props.name}</p>
        <p className="text-sm text-gray-900 text-right hidden sm:block">{props.longName}</p>
      </div>
      <Input
        type="text"
        placeholder="..."
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={cn("!text-sm !px-4 !rounded-none text-left !border-0")}
        maxLength={props.maxLength}
        pattern={props.pattern}
      />
    </div>
  );
}

export const OrderConfigAddressGoogle = observer(() => {
  const store = useStore();
  const zones = store.serviceDeliveryZones;
  const oc = store.order_config;

  const possibleStates = {
    success: {
      error: "",
      loading: false,
      modal: false,
    },
    loading: {
      loading: true,
      modal: false,
      error: "",
      missingStreetNumber: false,
    },
    blocked: {
      modal: false,
      loading: false,
      error: "We are currently not delivering to this address",
      missingStreetNumber: false,
    },
    invalid: {
      modal: false,
      loading: false,
      error:
        zones.length > 0
          ? "Your address is not within an active delivery zone"
          : "Your address is not in range of our store location",
      missingStreetNumber: false,
    },
    not_found: {
      modal: false,
      loading: false,
      error: "Delivery address not found or valid, try again",
      missingStreetNumber: false,
    },
  };
  const [state, setState] = React.useState({
    address: "",
    error: "",
    loading: false,
    modal: false,
    missingStreetNumber: !!oc.s.config_address && !oc.s.config_address.number,
  });

  function updateState(newState: Partial<typeof state>) {
    setState({
      ...state,
      ...newState,
    });
  }

  const placeholder = "Find Your Delivery Address";
  let buttonText = state.address || placeholder;
  if (oc.s.config_address) {
    buttonText = oc.s.config_address.display;
  }

  const alertEnabled = store.alertTaxisEnabled;
  const adminLevel = store.r.listing!.city || store.r.location?.address.city;
  // const algolia = React.useRef(!alertEnabled ? null : algoliasearch("I8NH9R3IP2", "e46c27a153685f8807fe8b8b5e8fe60f"));
  // const algoliaSearchIndex = React.useRef(algolia.current?.initIndex(`mti_${(adminLevel || "").toLowerCase()}`));

  async function handleAddressSelect(selected: AddressSelectOption) {
    try {
      updateState(possibleStates.loading);

      const address: AddressFragment = {
        id: uuid(),
        source: "google_maps",
        coords: {
          type: "Point",
          coordinates: [0, 0],
        },
        display: "",
        unit: "",
        number: "",
        street: "",
        suburb: "",
        city: "",
        region: "",
        postcode: "",
        notes: "",
        mti_fleet_id: null,
        mti_designation_id: null,
        mti_street_id: null,
        mti_suburb_id: null,
      };

      if (selected.type === "alert") {
        const parsed = selected as T.MTIDispatch.AlgoliaSearchRecord & AddressSelectOption;
        const addressableAddress = (await handleAddressSearchAPI(parsed.label))[0];

        if (!addressableAddress) {
          oc.setService("delivery");
          updateState({
            address: selected.label,
            ...possibleStates.not_found,
          });
          return;
        }

        address.number = parsed.street_number || "";
        address.street = addressableAddress.street || "";
        address.suburb = addressableAddress.locality || "";
        address.city = addressableAddress.city || "";
        address.region = addressableAddress.region || "";
        address.postcode = addressableAddress.postcode || "";
        address.coords.coordinates[0] =
          typeof addressableAddress.lon === "string"
            ? parseFloat(addressableAddress.lon)
            : addressableAddress.lon;
        address.coords.coordinates[1] =
          typeof addressableAddress.lat === "string"
            ? parseFloat(addressableAddress.lat)
            : addressableAddress.lat;
        address.mti_designation_id = parseInt(parsed.designation_id, 10);
        address.mti_street_id = parseInt(parsed.street_id, 10);
        address.mti_suburb_id = parseInt(parsed.suburb_id, 10);
      } else if (selected.type === "google") {
        const parsed = selected as AddressSelectOption & google.maps.places.AutocompletePrediction;
        const place = (await store.googleMapsClient!.geocode({ placeId: parsed.place_id }))[0];
        if (!place || !place.geometry || !place.address_components)
          throw Error("Missing google place");
        console.log(place.address_components);
        address.number = getGoogleComponent(["street_number"], place.address_components);
        address.street = getGoogleComponent(["route"], place.address_components);
        address.suburb = getGoogleComponent(["sublocality"], place.address_components);
        address.city = getGoogleComponent(["locality"], place.address_components);
        address.region = getGoogleComponent(
          ["administrative_area_level_1"],
          place.address_components
        );
        address.postcode = getGoogleComponent(["postal_code"], place.address_components);
        address.coords.coordinates[0] = place.geometry.location.lng();
        address.coords.coordinates[1] = place.geometry.location.lat();
      } else if (selected.type === "api") {
        /*
      else if (selected.type === "mapbox") {
        const place = selected as AddressSelectOption & Omit<T.Mapbox.GeocodeAutocompleteResultFeature, "type">;
        address.number = place.address;
        address.street = place.text;
        address.suburb = getMapboxComponent(["locality"], place);
        address.city = getMapboxComponent(["place"], place);
        address.region = getMapboxComponent(["region"], place);
        address.postcode = getMapboxComponent(["postcode"], place);
        address.coords.coordinates = place.center;
      }
      */
        const parsed = selected as AddressSelectOption & T.Addressable.Record;
        address.number = parsed.street_number || "";
        address.street = parsed.street || "";
        address.suburb = parsed.locality || "";
        address.city = parsed.city || "";
        address.region = parsed.region || "";
        address.postcode = parsed.postcode || "";
        address.coords.coordinates[0] =
          typeof parsed.lon === "string" ? parseFloat(parsed.lon) : parsed.lon;
        address.coords.coordinates[1] =
          typeof parsed.lat === "string" ? parseFloat(parsed.lat) : parsed.lat;
      }

      if (selected.type === "google") {
        address.display = selected.label;
      } else {
        address.display = formatAddressObjectDisplay(address);
      }

      console.log(address);

      const block_addresses = store.r.service_delivery!.block_addresses;
      if (Array.isArray(block_addresses)) {
        const blocked = OrderUtils.checkBlockedAddresses(address.display, block_addresses);
        if (blocked) {
          oc.setService("delivery");
          updateState({
            ...possibleStates.blocked,
            address: address.display,
          });
          return;
        }
      }

      const { valid, distance, duration, zoneMatch } = await oc.validateDeliveryDetails({
        lng: address.coords.coordinates[0],
        lat: address.coords.coordinates[1],
      });

      if (!valid) {
        oc.setService("delivery");
        updateState({
          ...possibleStates.invalid,
          address: address.display,
        });
      } else {
        oc.setDestination({
          address: address,
          distance: distance,
          driving_time: duration,
          zone_id: zoneMatch ? zoneMatch.id : null,
          zone_name: zoneMatch ? zoneMatch.name : "",
        });
        updateState({
          ...possibleStates.success,
          missingStreetNumber: !address.number,
          address: address.display,
        });
      }
    } catch (e) {
      logger.captureException2(e, {
        extra: {
          where: "handle_address_select",
        },
      });
      updateState({
        address: selected.label,
        ...possibleStates.not_found,
      });
    }
  }
  /*
  async function handleAddressSearchAlert(search: string) {

    if (!search) return [];

    const numbersInSearch = search.match(/\d+/g);

    if (!numbersInSearch || !numbersInSearch[0]) {
      return [
        {
          type: "alert" as "alert",
          label: "Enter your street number to start searching",
          disabled: true,
        }
      ];
    }

    const streetNumber = numbersInSearch[0];

    const searchWithoutNumber = search.split(streetNumber)[1];

    const results = await algoliaSearchIndex.current?.search<T.MTIDispatch.AlgoliaSearchRecord>(searchWithoutNumber, {
      hitsPerPage: 6,
    });

    return results?.hits.map((hit) => {
      const label = `${streetNumber} ${hit.display_name}, ${adminLevel}`;
      return {
        type: "alert" as "alert",
        label: label,
        street_number: streetNumber,
        ...hit,
      };
    }) || [];

  }
  */
  async function handleAddressSearchAPI(search: string) {
    if (!search) return [];

    const { results } = await store.api.addressAutocomplete({
      q: search,
    });

    return results.map((result) => ({
      ...result,
      type: "api" as "api",
      label: result.formatted,
    }));
  }
  async function handleAddressSearchGoogle(search: string) {
    if (!search) return [];
    const places = await store.googleMapsClient!.autocomplete_place({
      input: search,
      componentRestrictions: {
        country: ["NZ"],
      },
    });
    return places.map((p) => ({
      ...p,
      type: "google" as "google",
      label: p.description,
    }));
  }
  async function handleAddressSearchMapBox(search: string) {
    if (!search) return [];
    const places = await MapboxAPI.geocode_autocomplete(search, {
      types: "address",
      country: "nz",
    });
    console.log(places);
    return places.features.map((p) => ({
      ...p,
      type: "mapbox" as "mapbox",
      label: p.place_name,
    }));
  }

  const searchFunction = handleAddressSearchAPI; // handleAddressSearchGoogle; // handleAddressSearchAPI;
  /*
  const searchFunction = alertEnabled ?
    handleAddressSearchAlert :
    handleAddressSearchAPI;
  */

  return (
    <div className="mt-6 mx-auto" style={{ maxWidth: "420px" }}>
      <div>
        <div
          className={cn([
            "text-md",
            "border bg-gray-000 hover:bg-gray-100 trans-200",
            "px-4 py-2 cursor-pointer",
            oc.s.config_address ? "rounded-t" : "rounded",
            oc.s.config_address ? "text-center" : "text-center",
          ])}
          onClick={() => updateState({ modal: true })}>
          {state.loading ? <RotateLoader size={2} /> : buttonText}
        </div>
        {!!oc.s.config_address && (
          <div className="">
            {state.missingStreetNumber && (
              <AdditionalInfoInput
                name="Street No."
                longName="Street Number"
                value={oc.s.config_address.number}
                isLast={false}
                isFirst={true}
                pattern={`^[a-zA-Z0-9s +]+$`}
                onChange={(number) => {
                  const isValid = new RegExp(/^[a-zA-Z0-9,.\s]+$/).test(number);
                  if (isValid || number.length < (oc.s.config_address?.number?.length || 0)) {
                    oc.updateAddress({ number });
                  }
                }}
              />
            )}
            <AdditionalInfoInput
              name="Unit No."
              longName="Unit Number"
              value={oc.s.config_address.unit}
              isLast={false}
              isFirst={!state.missingStreetNumber}
              maxLength={15}
              pattern={`^[a-zA-Z0-9s +]+$`}
              onChange={(unit) => {
                const isValid = new RegExp(/^[a-zA-Z0-9,.\s]+$/).test(unit);
                if (isValid || unit.length < (oc.s.config_address?.unit?.length || 0)) {
                  oc.updateAddress({ unit });
                }
              }}
            />
            <AdditionalInfoInput
              name="Notes"
              longName="Delivery Notes"
              value={oc.s.config_address.notes}
              pattern={`^[a-zA-Z0-9s +]+$`}
              maxLength={80}
              isLast={true}
              isFirst={false}
              onChange={(notes) => {
                const isValid = new RegExp(/^[a-zA-Z0-9,.\s]+$/).test(notes);
                if (isValid || notes.length < (oc.s.config_address?.notes?.length || 0)) {
                  oc.updateAddress({ notes });
                }
              }}
            />
          </div>
        )}
      </div>

      {state.error && <p className="mt-4 text-center text-error text-md">{state.error}</p>}

      <SearchModal
        level={2}
        width="sm"
        innerId="address-search-modal"
        title="Search For Your Address"
        active={state.modal}
        close={() => updateState({ modal: false })}
        handleSelect={handleAddressSelect}
        handleSearch={searchFunction}
        attribution={
          <ModalContent paddingtb={12}>
            <img
              className="mx-auto"
              alt="google-attribution"
              src="/attributions/google_powered_by_black.png"
            />
          </ModalContent>
        }
      />
    </div>
  );
});
