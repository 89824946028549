export const SessionStorage = {
  clear() {
    Object.keys(localStorage).forEach((key) => {
      if (key.indexOf("saved-session-") !== -1) {
        localStorage.removeItem(key);
      }
    });
  },

  set(ref: string, data: string) {
    const key = `saved-session-${ref}`;
    localStorage.setItem(key, data);
  },

  get(ref: string) {
    const key = `saved-session-${ref}`;
    const data = localStorage.getItem(key);
    localStorage.removeItem(key);
    if (data) return JSON.parse(data);
    return null;
  },

  remove(ref: string) {
    const key = `saved-session-${ref}`;
    localStorage.removeItem(key);
  },
};
