import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { FaChevronRight } from "react-icons/fa";
import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { ErrorBoxLight } from "../../../components/ErrorBoxLight";
import { CategoryTitle } from "./category-title";
import { CategoryDishes } from "./category-dishes";
import { windowScrollTop } from "../../../../../client/utils";

interface Props {}

@inject("store")
@observer
class MenusListClass extends MobxComponent<Props> {
  lastMenuId: string;
  lastScreenWidth?: number;

  categoryPositions: Array<{ id: string; top: number }> = [];

  constructor(props: Props) {
    super(props);
    this.lastMenuId = this.injected.store.menu?.id || "";
  }

  componentDidMount() {
    this.generateCategoryPositionMap();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
  }
  componentDidUpdate(): void {
    const { store } = this.injected;
    const { menu, view } = store;
    if (!menu) return;
    if (menu.id !== this.lastMenuId || view.s.screen_width !== this.lastScreenWidth) {
      this.lastMenuId = menu.id;
      this.lastScreenWidth = view.s.screen_width;
      this.generateCategoryPositionMap();
    }
  }
  componentWillUnmount(): void {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleResize = debounce(() => {
    this.generateCategoryPositionMap();
  }, 500);
  handleScroll = throttle(() => {
    const { store } = this.injected;

    // Prevent this from triggering if user manually just selected category
    if (store.filters.scrollToCategoryActive) {
      store.filters.scrollToCategoryActive = false;
      return;
    }

    const { top_nav_height } = store.view.s;

    // Add 60 to the top bar height just to ensure that it doesn't accidentally set the previous category as a match
    // const topBarHeight = top_nav_height + 60;
    const scrollTop = windowScrollTop() + top_nav_height;
    for (const [i, { id, top }] of this.categoryPositions.entries()) {
      const nextCat = this.categoryPositions[i + 1];

      if (nextCat) {
        if (scrollTop >= top && scrollTop <= nextCat.top) {
          store.filters.set(["", id]);
          break;
        }
      } else if (scrollTop >= top) {
        store.filters.set(["", id]);
        break;
      }
    }
  }, 200);
  generateCategoryPositionMap = () => {
    const { store } = this.injected;
    const { menu } = store;
    this.categoryPositions = [];
    if (!menu) {
      return;
    }
    setTimeout(() => {
      menu.categories.forEach((c, i) => {
        const el = document.getElementById(`category.${c.id}`);
        if (!el) return;
        const { top_nav_height } = store.view.s;
        const distanceFromTop = windowScrollTop();
        const box = el.getBoundingClientRect();
        this.categoryPositions.push({
          id: c.id,
          top: distanceFromTop + box.top,
        });
      });
    }, 200);
  };

  render() {
    const { store } = this.injected;
    const { menu, menuHasRestrictions, menuAvailability } = store;
    if (!menu) return null;
    return (
      <>
        {menu.categories.map((c, i) => {
          const { isRestricted, reason } = store.menuAvailabilityCheck(c);
          if (isRestricted && c.restrict_hide_unavailable) return null;
          return (
            <div key={c.id} id={`category.${c.id}`} className={"px-4 lg:px-6 xl:px-10 pt-2 pb-6"}>
              {menuHasRestrictions && !menu.restrict_hide_warning && i === 0 && (
                <ErrorBoxLight
                  onClick={() => store.modal.show("menu-restrictions")}
                  className="flex items-center justify-between px-4 py-2 rounded mt-6 cursor-pointer text-error">
                  <p className="text-sm mr-2 flex-grow">
                    {!menuAvailability.isRestricted
                      ? "This menu has order restrictions"
                      : "Menu un-available with current order"}
                  </p>
                  <FaChevronRight className="text-xs" />
                </ErrorBoxLight>
              )}
              <CategoryTitle category={c} restricted={isRestricted} restrictedReason={reason} />
              <CategoryDishes category={c} restricted={isRestricted} restrictedReason={reason} />
            </div>
          );
        })}
      </>
    );
  }
}

export const MenusList = MenusListClass;
