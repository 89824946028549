import * as React from "react";
import cn from "classnames";
import { MobxComponent } from "../../../../mobx/component";
import { DishModalIngredients } from "./ingredients";
import { inject, observer } from "mobx-react";
import { ModalContent, ModalSelectSection } from "@lib/components";
import { computed } from "mobx";
import { menuDish } from "@lib/common";
import { DishModalOptionSet } from "./option-sets";

interface Props {
  choiceId: string;
}

interface State {}

@inject("store")
@observer
export class DishModalChoiceClass extends MobxComponent<Props, State> {
  @computed get menuComboChoice() {
    const { choiceId } = this.props;
    const { store } = this.injected;
    const menuDish = store.dish.s.menuDish!;
    const menuChoice = menuDish.choices.find((c) => c.id === choiceId);
    return menuChoice!;
  }

  @computed get orderDishChoice() {
    const { choiceId } = this.props;
    const { store } = this.injected;
    const orderDish = store.dish.s.orderDish!;
    return orderDish.choices.find((c) => c.choice_id === choiceId);
  }

  @computed get dishChoices() {
    const { choiceId } = this.props;
    const { store } = this.injected;
    const md = store.dish.s.menuDish!;
    return menuDish.choices({
      md: md,
      menus: store.menusAll,
      odChoiceId: choiceId,
      availabilityCheck: store.menuAvailabilityCheck,
    });
  }

  render() {
    const { store } = this.injected;
    const choice = this.menuComboChoice;
    const selected = this.orderDishChoice;
    const error = store.dish.s.error && (store.dish.s.choiceErrors[choice.id] || false);
    return (
      <ModalSelectSection
        key={choice.id}
        id={choice.id}
        title={(() => {
          if (selected) {
            return (
              <div className="flex justify-between content-center items-center flex-grow">
                <div>
                  <p className="text-md font-semibold">{selected.name}</p>
                  <p className="text-md">{choice.name}</p>
                </div>
              </div>
            );
          }

          return <p className={cn("font-semibold", { "text-error": error })}>{choice.name}</p>;
        })()}
        selectTitle="Choose A Dish"
        selected={selected ? selected.id : ""}
        options={this.dishChoices}
        onSelect={(o) => {
          store.dish.setChoice(this.props.choiceId, o.value);
          // onSelect(o.value);
        }}
        level={2}
        paddingtb={15}
        paddinglr={25}>
        {selected && (
          <ModalContent paddinglr={0} paddingtb={0}>
            <DishModalOptionSet selectedChoiceId={selected.id} />
            <DishModalIngredients selectedChoiceId={selected.id} />
          </ModalContent>
        )}
      </ModalSelectSection>
    );
  }
}

export const DishModalChoice = DishModalChoiceClass;
