import * as React from "react";
import { observer, inject } from "mobx-react";
import { FastField, FastFieldProps, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { withTheme } from "styled-components";
import { MobxComponent } from "../../../../mobx/component";
import { logger } from "@lib/common";
import { untrusive } from "../../../../../client/untrusive";
import { Button, Input, RotateLoader, Checkbox, FormGroup } from "@lib/components";
import isEmail from "validator/lib/isEmail";
import { v4 as uuid } from "uuid";

interface Props {}
interface FormValues {
  name: string;
  phone: string;
  email: string;
  number_of_people: string | number;
  notes: string;
  accept_terms: boolean;
  accept_marketing: boolean;
}

const errors: { [key: string]: string } = {
  not_found: "Booking doesn't exist. It was possibly deleted",
  generic: "Something went wrong, refresh the page or contact us",
  something_went_wrong: "Something went wrong",
  accept_terms: "Please accept the terms & conditions",
  required_phone: "Please enter your phone number",
  required_email: "Please enter your email",
  required_name: "Please enter your name",
  invalid_email: "Please enter a valid e-mail address",
  invalid_phone: "Please enter a valid phone number",
  invalid_people: "Invalid number of people",
};

@inject("store")
@observer
class BookingConfigClass extends MobxComponent<Props, { error: string }> {
  constructor(props: Props) {
    super(props);
    this.state = { error: "" };
  }

  setError = (error: string) => this.setState({ error });

  submit = async (v: FormValues, form: FormikHelpers<FormValues>) => {
    try {
      const { store } = this.injected;
      const { max_people, min_people } = store.r.service_table_booking!;

      let error = "";
      if (!v.name) {
        error = "required_name";
      }
      if (!v.email) {
        error = "required_email";
      }
      if (!isEmail(v.email, { require_tld: true })) {
        error = "invalid_email";
      }
      if (!v.phone) {
        error = "required_phone";
      }
      if (max_people || min_people) {
        if (
          max_people &&
          min_people &&
          (v.number_of_people > max_people || v.number_of_people < min_people)
        ) {
          error = "invalid_people";
        } else if (max_people && !min_people && v.number_of_people > max_people) {
          error = "invalid_people";
        } else if (!max_people && min_people && v.number_of_people < min_people) {
          error = "invalid_people";
        }
      }
      if (!v.accept_terms) {
        error = "accept_terms";
      }

      if (error) {
        form.setSubmitting(false);
        this.setError(error);
        return;
      }

      const oc = store.order_config.s;

      this.setError("");
      untrusive.start();
      form.setSubmitting(true);

      // const timestamp = moment.tz(`${oc.date} ${oc.time}`, Formats.moment.datetime, tz).valueOf();

      // If customer has enabled marketing or it is toggled in checkout
      const acceptMarketing = store.customer.s.item?.accept_marketing || v.accept_marketing;

      const { booking } = await store.api.booking_create({
        booking: {
          id: uuid(),
          notes: v.notes,
          customer_id: store.customer.s.item?.id || null,
          customer_name: v.name,
          customer_phone: v.phone,
          customer_email: v.email,
          config_timestamp: oc.config_timestamp,
          config_num_people: v.number_of_people as number,
        },
        acceptMarketing: acceptMarketing,
      });

      store.booking.update({
        loading: false,
        error: "",
        item: booking,
      });

      // Update customer profile if accepted marketing
      if (acceptMarketing) {
        store.customer.updateItem({ accept_marketing: true });
      }

      // CLOSE MODAL
      store.modal.update({ active: "", last: "" });

      // CLEAR CART AND ORDER SETUP
      store.order_config.setService("");

      untrusive.stop();

      // TAKE TO ORDER
      store.router.push(`/booking/${booking.id}`);

      fbq("track", "Schedule");
    } catch (e) {
      logger.captureException(e);
      this.setError("something_went_wrong");
      form.setSubmitting(false);
      untrusive.stop();
    }
  };

  getError = (form: FormikProps<FormValues>, field: keyof FormValues) => {
    if (form.submitCount > 0 && form.touched[field] && form.errors[field]) {
      return form.errors[field];
    }
    return null;
  };

  render() {
    const { store } = this.injected;

    const { max_people, min_people, notice_vaccine_entry } = store.r.service_table_booking!;

    const customer = store.customer.s.item;
    const showAcceptMarketing = false; // !customer?.accept_marketing;

    let no_people_placeholder = "Number Of People";
    if (max_people && min_people) {
      no_people_placeholder = `Number Of People (Between ${min_people} to ${max_people})`;
    } else if (max_people && !min_people) {
      no_people_placeholder = `Number Of People (${max_people} Max)`;
    } else if (!max_people && min_people) {
      no_people_placeholder = `Number Of People (${min_people} Min)`;
    }

    return (
      <div className="mt-6 mx-auto" style={{ maxWidth: "420px" }}>
        <Formik<FormValues>
          initialValues={{
            name: customer?.name || "",
            email: customer?.email || "",
            phone: customer?.phone || "",
            number_of_people: "",
            notes: "",
            accept_terms: false,
            accept_marketing: false,
          }}
          onSubmit={this.submit}>
          {(form) => {
            const { isSubmitting, submitCount, values } = form;
            const showFormError = submitCount > 0 && this.state.error;
            return (
              <Form>
                <FastField name="name">
                  {({ field }: FastFieldProps<FormValues["name"]>) => (
                    <FormGroup no_border={true} className="">
                      <Input {...field} type="text" placeholder="Full Name" required={true} />
                    </FormGroup>
                  )}
                </FastField>

                <FastField name="email">
                  {({ field }: FastFieldProps<FormValues["email"]>) => (
                    <FormGroup no_border={true} className="">
                      <Input {...field} type="email" placeholder="E-Mail Address" required={true} />
                    </FormGroup>
                  )}
                </FastField>

                <FastField name="phone">
                  {({ field }: FastFieldProps<FormValues["phone"]>) => (
                    <FormGroup no_border={true} className="">
                      <Input {...field} type="text" placeholder="Phone Number" required={true} />
                    </FormGroup>
                  )}
                </FastField>

                <FastField name="number_of_people">
                  {({ field }: FastFieldProps<FormValues["number_of_people"]>) => (
                    <FormGroup no_border={true} className="">
                      <Input
                        type="number"
                        max={max_people ? max_people : 100000}
                        min={min_people ? min_people : 1}
                        placeholder={no_people_placeholder}
                        required={true}
                        {...field}
                      />
                    </FormGroup>
                  )}
                </FastField>

                <FastField name="notes">
                  {({ field }: FastFieldProps<FormValues["notes"]>) => (
                    <FormGroup no_border={true} className="">
                      <Input {...field} type="text" placeholder="Notes / Special Requests" />
                    </FormGroup>
                  )}
                </FastField>

                {notice_vaccine_entry && (
                  <div className="bg-gray-100 py-4 px-6 mb-4 rounded">
                    <p className="mb-1 text-lg font-semibold">Vaccine Verification</p>
                    <p className="text-md">
                      Under public health orders by the Government, dining-in requires that all
                      members of your group over the age of 12 are double vaccinated. All guests
                      will have their vaccine passports scanned before entry. By accepting the terms
                      below, you are agreeing to this
                    </p>
                  </div>
                )}

                <FormGroup no_border={true} contentClassName="px-4 py-2 text-gray-1000">
                  {showAcceptMarketing && (
                    <div
                      className="flex cursor-pointer mb-3"
                      onClick={() =>
                        form.setFieldValue("accept_marketing", !values.accept_marketing)
                      }>
                      <div style={{ marginTop: "3px" }}>
                        <Checkbox
                          id="accept-marketing-booking"
                          name="accept_marketing"
                          checked={values.accept_marketing}
                        />
                      </div>
                      <p className="ml-3 text-md leading-relaxed">
                        Keep me up to date on new promos, deals and updates via e-mail or SMS{" "}
                        <span className="text-sm">(Optional)</span>
                      </p>
                    </div>
                  )}
                  <div
                    className={
                      showAcceptMarketing
                        ? "flex cursor-pointer"
                        : "flex justify-center cursor-pointer"
                    }
                    onClick={() => form.setFieldValue("accept_terms", !values.accept_terms)}>
                    <div style={{ marginTop: "3px" }}>
                      <Checkbox
                        id="accept-terms-booking"
                        name="accept_terms"
                        checked={values.accept_terms}
                      />
                    </div>
                    <p className="ml-3 text-md leading-relaxed">
                      I accept the terms & conditions -{" "}
                      <a className="link" onClick={() => store.modal.show("terms")}>
                        View
                      </a>
                    </p>
                  </div>
                </FormGroup>

                {showFormError && <FormGroup no_border={true} error={errors[this.state.error]} />}

                <Button full={true} color="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting && (
                    <RotateLoader size={2} color={this.injected.theme.colors.primary_text} />
                  )}
                  {!isSubmitting && "Submit Booking Request"}
                </Button>

                <p className="mt-4 text-center text-md">
                  You will receive an e-mail notification when your booking is accepted or denied
                </p>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }
}

// @ts-ignore
export const BookingConfig = withTheme(BookingConfigClass);
