import { observable, action, computed } from "mobx";
import { RootStore } from "../store";
import { BookingFragment } from "@lib/types/graphql";

export interface BookingState {
  item: BookingFragment | null;
  loading: boolean;
  error: string;
}

export class BookingStore {
  @observable s: BookingState;

  store: RootStore;

  constructor(store: RootStore, initialState?: BookingState) {
    this.store = store;
    this.s = {
      item: null,
      loading: true,
      error: "",
      ...(initialState || {}),
    };
  }

  @computed get routeId() {
    const path = this.store.router.s.path;
    return path.indexOf("/booking/") !== -1 ? path.split("/booking/")[1] : null;
  }

  @action update = (data: Partial<BookingState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof BookingState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof BookingState] = value;
        }
      }
    }
  };

  @action updateItem(data: Partial<BookingFragment>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof BookingFragment];
        if (value !== undefined && this.s && this.s.item) {
          // @ts-ignore
          this.s.item[key as keyof BookingFragment] = value;
        }
      }
    }
  }
}
