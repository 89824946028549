import React from "react";
import Measure, { ContentRect } from "react-measure";
import {
  TabSelectComponent,
  TabSelectHighlightComponent,
  TabSelectContentComponent,
  TabSelectOptionsComponent,
  TabSelectOptionComponent,
} from "./components";
import { withTheme } from "styled-components";
import throttle from "lodash/throttle";

interface TabValue {
  label: string;
  value: string;
}
interface Props {
  id: string;
  value: string;
  values: TabValue[];
  onChange: (val: TabValue) => void;
  style?: React.CSSProperties;
  className?: string;
  noControls?: boolean;
  noScroll?: boolean;
  hasBorder?: boolean;
  onWidthChange?: (data: { tabWidths: number[]; totalTabsWidth: number }) => void;
  background?: string;
  theme?: T.ThemeInterface;
}
interface State {
  tabWidths: number[];
  totalTabsWidth: number;
  containerWidth: number;
  scrollPosition: number;
  overrideScrollPosition: number;
}

class TabSelectClass extends React.Component<Props, State> {
  contentId: string;
  contentEl: HTMLElement | null;

  constructor(props: Props) {
    super(props);
    this.state = {
      tabWidths: [],
      totalTabsWidth: 0,
      containerWidth: 0,
      scrollPosition: 0,
      overrideScrollPosition: 0,
    };
    this.contentId = `${props.id}-content`;
    this.contentEl = null;
  }

  // LIFECYCLE
  componentDidMount() {
    this.contentEl = document.getElementById(this.contentId);
    if (this.contentEl) {
      this.contentEl.addEventListener("scroll", this.onScroll);
    }
  }

  componentWillUnmount() {
    if (this.contentEl) {
      this.contentEl.removeEventListener("scroll", this.onScroll);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      const initialScrollPosition = this.calculateScrollPosition();
      const scrollPosition = this.sanitizeScrollValue(initialScrollPosition);
      this.setScrollPosition(scrollPosition);
    }
  }

  // ACTIONS
  setScrollPosition = (pos: number) => {
    if (this.contentEl) {
      this.contentEl.scrollTo({ left: pos, behavior: "smooth" });
      this.setState({ scrollPosition: pos });
    }
  };

  // CALCULATIONS
  calculateHighlightWidth = () => {
    const { tabWidths } = this.state;
    return tabWidths[this.getSelectedIndex()];
  };
  calculateHighlightStartPosition = () => {
    const { tabWidths } = this.state;
    const activeKey = this.getSelectedIndex();
    return tabWidths.reduce((a, v, i) => {
      if (i < activeKey) {
        return a + v;
      }
      return a;
    }, 0);
  };
  calculateScrollPosition = () => {
    const { containerWidth } = this.state;
    const highlightStartPosition = this.calculateHighlightStartPosition();
    const bufferModifier = containerWidth > 600 ? 400 : 250;
    const bufferValue = Math.max(containerWidth - bufferModifier, 0);
    return Math.max(highlightStartPosition - bufferValue, 0);
  };
  sanitizeScrollValue = (val: number) => {
    // DONT LET IT GO BELOW 0 OR PAST TEH MAX CONTENT WIDTH
    if (val < -5) return 0;
    const { totalTabsWidth, containerWidth } = this.state;
    if (totalTabsWidth <= containerWidth) {
      // IF TOTAL TABS LESS THAN CONTAINER SIZE, NO SCROLL
      return 0;
    } else {
      // TOTAL TABS GREATER THAN CONTAINER, THERE IS A SCROLL
      const maxScroll = totalTabsWidth - containerWidth;
      // IF THE SCROLL VALUE IS GREATER THAN THE LENGTH OF THE CONTENT ACCOUNTING FOR SCREEN SIZE
      if (val > maxScroll) return maxScroll;
      return val;
    }
  };
  getSelectedIndex = () => {
    const { value, values } = this.props;
    return values.findIndex((v) => v.value === value);
  };

  // EVENTS
  onScroll = throttle((e: HTMLElementEventMap["scroll"]) => {
    if (this.contentEl) {
      this.setState({ scrollPosition: this.contentEl.scrollLeft });
    }
  }, 300);

  onContentResize = (contentRect: ContentRect) => {
    const { bounds } = contentRect;
    if (bounds && this && this.setState) {
      this.setState({ containerWidth: bounds.width });
    }
  };
  onOptionResize = (contentRect: ContentRect, i: number) => {
    const { tabWidths } = this.state;
    const { bounds } = contentRect;
    if (bounds) {
      const { width } = bounds;
      const tw = [...tabWidths];
      tw[i] = width;
      const totalTabsWidth = tw.reduce((a, val) => a + val, 0);
      this.setState({ tabWidths: tw, totalTabsWidth: totalTabsWidth });
      if (this.props.onWidthChange) {
        this.props.onWidthChange({ tabWidths: tw, totalTabsWidth: totalTabsWidth });
      }
    }
  };
  onOptionSelect = (v: TabValue) => {
    this.props.onChange(v);
  };

  // PARTS
  highlight = () => {
    const width = this.calculateHighlightWidth();
    const startPosition = this.calculateHighlightStartPosition();
    return <TabSelectHighlightComponent startPosition={startPosition} width={width} />;
  };
  options = () => {
    const { values } = this.props;
    return (
      <TabSelectOptionsComponent>
        {values.map((v, i) => {
          return (
            <Measure
              key={i}
              bounds={true}
              onResize={(contentRect) => this.onOptionResize(contentRect, i)}>
              {({ measureRef }) => (
                <div ref={measureRef}>
                  <TabSelectOptionComponent hidden={false} onClick={() => this.onOptionSelect(v)}>
                    {v.label}
                  </TabSelectOptionComponent>
                </div>
              )}
            </Measure>
          );
        })}
      </TabSelectOptionsComponent>
    );
  };

  render() {
    const background = this.props.background || this.props.theme!.colors.background;
    const { style, className, noControls, hasBorder } = this.props;
    return (
      <TabSelectComponent
        id={this.props.id}
        style={style}
        className={className}
        hasBorder={hasBorder}
        background={background}>
        <Measure bounds={true} onResize={this.onContentResize}>
          {({ measureRef }) => (
            <div ref={measureRef}>
              <TabSelectContentComponent id={this.contentId}>
                {this.options()}
                {this.highlight()}
              </TabSelectContentComponent>
            </div>
          )}
        </Measure>
      </TabSelectComponent>
    );
  }
}

export const TabSelect = withTheme((props: Props) => <TabSelectClass {...props} />);
