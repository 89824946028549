import * as React from "react";
import lighten from "polished/lib/color/lighten";
import { styled, Button } from "@lib/components";

const SocialButton = styled(Button)`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  .text {
    flex-grow: 1;
    text-align: center;
  }
`;

export const FacebookButton = styled(SocialButton)`
  background: #4b70ab;
  &:hover {
    background: ${lighten(0.08, "#4b70ab")};
  }
`;

export const GoogleButton = styled(SocialButton)`
  background: #dd4b39;
  &:hover {
    background: ${lighten(0.08, "#dd4b39")};
  }
`;
