import * as React from "react";
import { FaHandPaper } from "react-icons/fa";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Checkbox, Input } from "@lib/components";

interface Props {}
interface State {}

@inject("store")
@observer
class CheckoutCustomerClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    const oc = store.order_config.s;
    const { logged_in_only } = store.r.setting!;

    const isDineIn = oc.config_service === "dine_in";

    const customer_name_optional =
      store.view.s.is_kiosk || (isDineIn && store.r.service_dine_in!.customer_name_optional);

    const customer_email_optional =
      store.view.s.is_kiosk || (isDineIn && store.r.service_dine_in!.customer_email_optional);

    const customer_phone_optional =
      store.view.s.is_kiosk || (isDineIn && store.r.service_dine_in!.customer_phone_optional);

    const delivery = store.r.service_delivery!;

    return (
      <div className="children:mb-3 last-mb-0">
        <Input
          type="text"
          id="address_field_imp"
          name="address_field_imp"
          style={{
            height: "0 !important",
            visibility: "hidden",
            position: "absolute",
          }}
        />
        <Input
          type="text"
          name="full_name"
          placeholder={customer_name_optional ? "Name (Optional)" : "Full Name"}
          onChange={(e) => store.order_config.update({ customer_name: e.target.value })}
          value={oc.customer_name}
        />
        <Input
          type="email"
          name="email"
          placeholder={customer_email_optional ? "E-Mail (Optional)" : "E-mail Address"}
          onChange={(e) => store.order_config.update({ customer_email: e.target.value })}
          value={oc.customer_email}
          disabled={logged_in_only || store.customer.isLoggedIn}
          readOnly={logged_in_only || store.customer.isLoggedIn}
        />
        <Input
          type="tel"
          name="phone"
          placeholder={customer_phone_optional ? "Phone (Optional)" : "Phone Number"}
          onChange={(e) => store.order_config.update({ customer_phone: e.target.value })}
          value={oc.customer_phone}
        />
        <Input
          type="text"
          name="notes"
          placeholder={isDineIn || store.view.s.is_kiosk ? "Order Notes (Optional)" : "Order Notes"}
          onChange={(e) => store.order_config.update({ notes: e.target.value })}
          value={oc.notes}
        />
        {oc.config_service === "delivery" && (
          <>
            <Input
              type="text"
              name="delivery_notes"
              placeholder="Delivery Notes"
              value={oc.config_address?.notes || ""}
              // maxLength={80}
              // pattern={`^[a-zA-Z0-9s +]+$`}
              onChange={(e) => {
                const notes = e.target.value;
                const isValid = new RegExp(/^[a-zA-Z0-9,.\s]+$/).test(notes);
                if (
                  isValid ||
                  notes.length < (store.order_config.s.config_address?.notes?.length || 0)
                ) {
                  store.order_config.updateAddress({ notes });
                }
              }}
            />
            {!!delivery.notice_no_contact && delivery.notice_no_contact !== "hidden" && (
              <div
                onClick={() => {
                  if (delivery.notice_no_contact === "optional") {
                    store.checkout.update({
                      no_contact_delivery: !store.checkout.s.no_contact_delivery,
                    });
                  }
                }}
                className="flex items-center justify-between mt-2 p-4 bg-gray-000 rounded cursor-pointer">
                <FaHandPaper className="text-gray-300 text-3xl mr-4" />
                <div className="mr-3 text-gray-1000">
                  {delivery.notice_no_contact === "always" && (
                    <p className="font-semibold text-md">No Contact Delivery</p>
                  )}
                  {delivery.notice_no_contact === "optional" && (
                    <p className="font-semibold text-md">Request No Contact Delivery</p>
                  )}
                  <p className="text-sm">
                    Driver will place your food at a safe location and notify you upon arrival
                  </p>
                </div>
                <Checkbox
                  id="no-contact-delivery-checkbox"
                  name="no-contact-delivery-checkbox"
                  checked={store.checkout.s.no_contact_delivery}
                  onChange={(e) => {
                    // store.checkout.update({ no_contact_delivery: e.target.checked });
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export const CheckoutCustomer = CheckoutCustomerClass;
