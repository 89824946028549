import * as React from "react";
import Transition from "react-transition-group/Transition";
import { inject, observer } from "mobx-react";
import { styled, RotateLoader } from "@lib/components";
import { MobxComponent } from "../../mobx/component";

const duration = 300;
const transitionStyles: T.CSSTransitionStates = {
  entering: {
    opacity: 0,
    display: "flex",
  },
  entered: {
    opacity: 1,
    display: "flex",
  },
  exiting: {
    opacity: 0,
    display: "flex",
  },
  exited: {
    opacity: 0,
    display: "none",
  },
};

interface Props {}
interface State {}

const Component = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: none;
  opacity: 0;
  transition: 0.3s all ease-in-out;
  background: ${({ theme }) => theme.colors.background};
  &.active {
    display: flex;
    opacity: 1;
  }
  .cover-loader-background {
    z-index: 50;
    background: ${({ theme }) => theme.colors.background};
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.3s all ease-in-out;
  }
  .cover-loader-content {
    z-index: 55;
    text-align: center;
    margin-bottom: 60px;
  }
`;

@inject("store")
@observer
export class CoverLoader extends MobxComponent<Props, State> {
  render() {
    const { active, opacity, title, message } = this.injected.store.loader.s;
    return (
      <Transition in={active} timeout={duration}>
        {(transitionState: string) => {
          const currentStyle = transitionStyles[transitionState];
          return (
            <Component style={currentStyle}>
              <div className="cover-loader-background" style={{ opacity: opacity || 1 }} />
              <div className="cover-loader-content">
                <RotateLoader style={{ marginBottom: "20px" }} size={3} />
                <h2 className="mb-1">{title}</h2>
                <p className="text-lg">{message}</p>
              </div>
            </Component>
          );
        }}
      </Transition>
    );
  }
}
