import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { Modal, ModalContent } from "@lib/components";
import { FaCheckCircle } from "react-icons/fa";

interface Props {}

const ReceiptKioskModalClass = observer((props: Props) => {
  const store = useStore();

  const active = store.modal.s.active === "order-receipt-kiosk";

  React.useEffect(() => {
    if (active) {
      setTimeout(() => {
        if (active) {
          store.modal.close();
        }
      }, 8000);
    }
  }, [active]);

  return (
    <Modal
      id="order-receipt-kiosk-modal"
      active={active}
      closeButton={true}
      width="sm"
      close={() => store.modal.close()}>
      <ModalContent paddingtb={60} paddinglr={60} className="text-center">
        <FaCheckCircle className="text-5xl mb-4 mx-auto text-success" />
        <h2>Thank you for ordering</h2>
        <p className="text-xl mt-2">
          Please collect your receipt and pay for your order at counter
        </p>
      </ModalContent>
    </Modal>
  );
});

export const ReceiptKioskModal = ReceiptKioskModalClass;
