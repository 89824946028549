import { observable, action, autorun } from "mobx";
import { RootStore } from "../store";
import autobind from "autobind-decorator";
import { logger, restaurantGetService, serviceDeliveryValidateCoords } from "@lib/common";
import {
  AddressFragment,
  RestaurantServiceTimeEnumEnum,
  RestaurantServiceTypeEnumEnum,
} from "@lib/types/graphql";
import { v4 as uuid } from "uuid";

export interface OrderConfigDisposable {
  config_date: string;
  table_password: string;
  confirmed: boolean;
  promo_form_code: string;
  promo_form_error: string;
}

@autobind
export class OrderConfigStore {
  @observable s: T.Order.ConfigState;
  @observable d: OrderConfigDisposable;
  store: RootStore;
  order_expired_timer: any;

  constructor(
    store: RootStore,
    initialState?: Partial<T.Order.ConfigState>,
    initialStateD?: OrderConfigDisposable
  ) {
    this.store = store;

    this.s = observable(this.initialState(initialState));

    this.d = observable({
      ...this.initialDisposableSate(),
      ...(initialStateD || {}),
    });

    if (typeof window !== "undefined") {
      // Check for duplicate order ID and set new one if necessary
      autorun(() => {
        const order_id = this.s.id;
        (async () => {
          try {
            const result = await this.store.api.order_validate_id({ order_id });
            if (result.order_id) {
              logger.captureWarning(`Duplicate order ID detected: ${order_id}`);
              this.setOrderId(result.order_id);
            }
          } catch (e) {
            logger.captureException2(e, {
              extra: {
                where: "order_validate_id",
              },
            });
          }
        })();
      });
    }

    if (
      typeof window !== "undefined" &&
      initialState &&
      initialState.config_table_id === "closed"
    ) {
      (async () => {
        this.setService("");
        alert(
          "The store is currently closed and a dine-in order due immediately cannot be placed. You can try order for a later date and time instead"
        );
      })();
    }

    console.log("ID", this.s.id);
  }

  initialState(overrides?: Partial<T.Order.ConfigState>): T.Order.ConfigState {
    console.log(">> GET INITIAL STATE CONFIG");

    return {
      id: uuid(),
      ready_at: 0,
      delivery_at: 0,
      notes: "",
      origin: null,
      customer_name: "",
      customer_phone: "",
      customer_email: "",
      config_service: "",
      config_due: "",
      config_timestamp: 0,
      config_address: null,
      config_zone_id: null,
      config_zone_name: "",
      config_driving_time: 0,
      config_distance: 0,
      config_table_id: null,
      config_table_name: "",
      config_num_people: "",
      payment_tip: 0,
      payment_type: "",
      payment_type_sub: undefined,
      payment_custom_id: undefined,
      promo_old_id: null,
      promo_old_code: null,
      dishes: [],
      ...overrides,
    };
  }
  initialDisposableSate(): OrderConfigDisposable {
    return {
      config_date: "",
      table_password: "",
      confirmed: false,
      promo_form_code: "",
      promo_form_error: "",
    };
  }

  clearTimers = () => {
    clearTimeout(this.order_expired_timer);
  };

  // Update config
  @action setService = (service: RestaurantServiceTypeEnumEnum | "") => {
    if (this.store.cart) {
      this.store.cart.clear();
    }
    this.clearTimers();
    this.updateD({
      ...this.initialDisposableSate(),
    });
    this.update(
      this.initialState({
        id: this.s.id,
        customer_name: this.s.customer_name,
        customer_phone: this.s.customer_phone,
        customer_email: this.s.customer_email,
        origin: this.s.origin,
        config_service: service,
      })
    );
    // this.store.cart.clear();
  };
  @action setDestination = (data: {
    address: AddressFragment;
    zone_id: string | null;
    zone_name: string;
    distance: number;
    driving_time: number;
  }) => {
    this.updateD({
      ...this.initialDisposableSate(),
    });
    this.update({
      ready_at: 0,
      delivery_at: 0,
      config_address: data.address,
      config_zone_id: data.zone_id,
      config_zone_name: data.zone_name,
      config_driving_time: data.driving_time,
      config_distance: data.distance,
    });
    // this.store.cart.clear();
  };
  @action setDue = (value: RestaurantServiceTimeEnumEnum) => {
    this.d.config_date = "";
    this.d.confirmed = false;
    this.update({
      ready_at: 0,
      delivery_at: 0,
      config_due: value,
      config_timestamp: 0,
      config_table_id: null,
      config_table_name: "",
      config_num_people: "",
    });
    // this.store.cart.clear();
  };
  @action setDate = (value: string) => {
    this.d.config_date = value;
    this.d.confirmed = false;
    this.update({
      ready_at: 0,
      delivery_at: 0,
      config_timestamp: 0,
      config_table_id: null,
      config_table_name: "",
      config_num_people: "",
    });
    // this.store.cart.clear();
  };
  @action setTime = (ready_at: number, delivery_at?: number) => {
    this.d.confirmed = false;
    this.update({
      ready_at: ready_at,
      delivery_at: delivery_at,
      config_timestamp: delivery_at || ready_at,
      config_table_id: null,
      config_table_name: "",
      config_num_people: "",
    });
    // this.store.cart.clear();
  };
  @action setTable = (_id: string, name: string) => {
    this.d.confirmed = false;
    this.update({
      config_table_id: _id,
      config_table_name: name,
      config_num_people: "",
    });
    // this.store.cart.clear();
  };
  @action setTablePassword = (value: string) => {
    this.d.table_password = value;
    this.d.confirmed = false;
  };
  @action setNumberOfPeople = (value: number | "") => {
    this.update({
      config_num_people: value,
    });
  };

  @action setConfirmed = (expires?: number) => {
    const { store } = this;
    const r = this.store.r;
    const oc = this.s;

    if (!oc.config_service) {
      throw new Error(`Missing service for set confirmed: ${oc.config_service}`);
    }

    const service = restaurantGetService(r, oc.config_service);

    if (service.default_menu) {
      if (store.menusAll.findIndex((m) => m.id === service.default_menu) !== -1) {
        this.store.filters.set([service.default_menu]);
      }
    }
    // if multi menu, set next available menu
    else if (store.menusAll.length > 1) {
      const currentMenu = store.menuAvailabilityCheck(store.menu!, true);
      if (currentMenu.isRestricted) {
        for (const menu of store.menusAll) {
          if (menu.id === this.store.menu?.id) continue;
          const isRestricted = this.store.menuAvailabilityCheck(menu, true).isRestricted;
          if (!isRestricted) {
            this.store.filters.set([menu.id]);
            setTimeout(() => {
              this.store.filters.scrollToCategory();
            }, 50);
            break;
          }
        }
      }
    }

    // Set expiry timer for later orders
    this.clearTimers();
    if (oc.config_due === "later") {
      // if (oc.config_due === "later" && expiry) {

      // Changed to hardcoded expiry as it doesnt really matter
      const minutes20 = Math.round(19.85 * 60 * 1000);
      // const expiryIn = expires - Date.now();

      console.log("EXPIRY", minutes20);

      this.order_expired_timer = setTimeout(() => {
        this.store.modal.show("order-expired-warning");
        this.updateD({ confirmed: false });
        this.setDate("");
        this.store.cart.clear();
      }, minutes20);
    }

    // In case a customer changes to now after reserving a time for later
    if (oc.config_due === "now") {
      this.store.api
        .schedulerUnreserveTime({
          restaurant_id: r.id,
          order_id: oc.id,
        })
        .catch(logger.captureException);
    }

    this.d.confirmed = true;
    this.store.cart.clear();

    // Show previously clicked dish
    const presetDishShown = this.store.dish.togglePreset();
    if (
      !presetDishShown &&
      this.store.customer.s.item &&
      this.store.order_history.s.items.length > 0
    ) {
      this.store.modal.show("order-history");
    } else {
      this.store.modal.hide("order-config");
    }

    // AUTO APPLY PROMOS TO CART
    const promos = r.promos_old;
    for (const promo of promos) {
      if (promo.auto_apply) {
        this.store.cart.promoAdd(promo.code, true);
        if (this.store.cart.promo) {
          break;
        }
      }
    }
  };

  @action setOrderId = (id: string) => {
    this.s.id = id;
  };
  /*
  @action resetOrderId = async () => {
    while (true) {
      try {
        const { id } = await this.store.api.gen_id({ table: "restaurant_order" });
        this.s.id = id;
        break;
      } catch (e) {
        logger.captureException(e);
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
  };
  */

  // Generic Updaters
  @action update = (data: Partial<T.Order.ConfigState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof T.Order.ConfigState];
        if (value !== undefined) {
          // @ts-ignore
          this.s[key as keyof OrderConfigState] = value;
        }
      }
    }
  };
  @action updateD = (data: Partial<OrderConfigDisposable>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof OrderConfigDisposable];
        if (value !== undefined) {
          // @ts-ignore
          this.d[key as keyof OrderConfigDisposable] = value;
        }
      }
    }
  };
  @action updateAddress = (data: Partial<AddressFragment>) => {
    if (!this.s.config_address) return;
    for (const key of Object.keys(data)) {
      const value = data[key as keyof AddressFragment];
      if (value !== undefined) {
        // @ts-ignore
        this.s.config_address[key] = value;

        // set address label
        const a = this.s.config_address;

        let display = `${a.street}, ${a.suburb}, ${a.city}`;

        if (a.number) {
          display = `${a.number} ${display}`;
        }
        if (a.unit) {
          display = `${a.unit} - ${display}`;
        }

        this.s.config_address.display = display;
      }
    }
  };

  validateDeliveryDetails = async (destinationCords: { lat: number; lng: number }) => {
    const { r, googleMapsClient } = this.store;
    const { coordinates } = r.location!.address.coords;
    const service = r.service_delivery!;
    return serviceDeliveryValidateCoords({
      googleMapsClient: googleMapsClient!,
      lat: destinationCords.lat,
      lng: destinationCords.lng,
      fromLat: coordinates[1],
      fromLng: coordinates[0],
      zones: this.store.serviceDeliveryZones,
      avoid_highways: service.avoid_highways,
      avoid_tolls: service.avoid_tolls,
      max_distance_km: service.max_distance,
      max_driving_time_min: service.max_driving_time,
    });
  };
}
