import { observable, action } from "mobx";
import { RootStore } from "../store";

export interface ModalState {
  active: string;
  last: string;
}

export class ModalStore {
  @observable s: ModalState;
  store: RootStore;

  constructor(store: RootStore, initialState?: ModalState) {
    this.store = store;
    this.s = {
      active: "",
      last: "",
      ...(initialState || {}),
    };
  }

  isVisible = (id: string) => {
    return this.s.active === id;
  };

  @action close = () => {
    this.s.last = this.s.active;
    this.s.active = "";
  };

  @action toggle = (id: string) => {
    this.s.last = this.s.active;
    if (this.s.active === id) this.s.active = "";
    else {
      this.s.active = id;
    }
  };

  @action show = (id: string) => {
    if (this.s.active !== id) {
      this.s.last = this.s.active;
      this.s.active = id;
    }
  };

  @action hide = (id: string) => {
    if (this.s.active === id) {
      this.s.last = this.s.active;
      this.s.active = "";
    }
  };

  @action back = (onlyIfActive?: string, onlyIfLast?: string) => {
    if (onlyIfActive && this.s.active !== onlyIfActive) return;
    if (onlyIfLast && this.s.last !== onlyIfLast) return;
    this.s.active = this.s.last || "";
    this.s.last = "";
  };

  @action update(data: Partial<ModalState>) {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof ModalState];
        if (value !== undefined && this.s) {
          this.s[key as keyof ModalState] = value;
        }
      }
    }
  }
}
