import * as React from "react";
import { observer, inject } from "mobx-react";
import { InfoServices } from "./services";
import { InfoOpeningHours } from "./opening_hours";
import { InfoLocation } from "./location";
import { shadeAlternate, styled } from "@lib/components";
import { useStore } from "../../../mobx/component";

const Wrapper = styled("div")`
  position: relative;
  background: ${({ theme }) => shadeAlternate(0.06, theme.colors.background)};
  padding: 60px 20px;
`;

const Content = styled("div")`
  max-width: ${({ theme }) => theme.breakpoints.xl}px;
  width: 100%;
  // transform: translateY(-100px);
  margin: -180px auto 0 auto;
`;

const Box = styled.div`
  background: ${({ theme }) => shadeAlternate(0, theme.colors.background)};
  height: 100%;
  margin: 0 auto;
  max-width: 400px;
`;

const CircleWrapper = styled("div")`
  background: ${({ theme }) => shadeAlternate(0.06, theme.colors.background)};
`;

const Circle = styled("div")`
  position: relative;
  height: 230px;
  width: 100%;
  overflow: hidden;
  :before {
    content: "";
    position: absolute;
    height: 230px;
    width: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    bottom: 0;
    background: ${({ theme }) => shadeAlternate(0.02, theme.colors.background)};
  }
`;

export const Info = observer(() => {
  const { r } = useStore();
  return (
    <>
      <CircleWrapper>
        <Circle />
      </CircleWrapper>
      <Wrapper>
        <Content className="flex flex-wrap -m-3">
          <div className="p-3 w-full lg:w-1/3">
            <Box className="shadow-md rounded p-8">
              <InfoServices />
            </Box>
          </div>
          <div className="p-3 w-full lg:w-1/3">
            <Box className="shadow-md rounded p-8">
              <InfoOpeningHours />
            </Box>
          </div>
          <div className="p-3 w-full lg:w-1/3">
            <Box className="shadow-md rounded p-8">
              <InfoLocation />
            </Box>
          </div>
        </Content>
      </Wrapper>
    </>
  );
});
