import * as React from "react";
import { MobxComponent } from "../../../../mobx/component";
import { inject, observer } from "mobx-react";
import { FaStickyNote } from "react-icons/fa";
import { styled, Input, shadeAlternate } from "@lib/components";

interface Props {}
interface State {}

const Wrapper = styled.div`
  position: relative;
`;

const Icon = styled.span`
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  left: 25px;
  color: ${({ theme }) => shadeAlternate(0.3, theme.colors.text)};
  line-height: 0;
  font-size: 14px;
`;

export const DishNotesInput = styled(Input)`
  padding: 23px 25px 23px 50px;
  border-radius: 0;
  font-size: 16px;
  border-top: none;
  &:focus {
    border-top: none;
    border-left: none !important;
    border-right: none !important;
  }
`;

@inject("store")
@observer
class DishModalNotesClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    if (store.dish.s.disableDishNotes) {
      return null;
    }
    return (
      <Wrapper>
        <Icon>
          <FaStickyNote />
        </Icon>
        <DishNotesInput
          onChange={(e) => store.dish.update({ notes: e.target.value })}
          value={store.dish.s.orderDish!.notes}
          placeholder="Special instructions..."
        />
      </Wrapper>
    );
  }
}

export const DishModalNotes = DishModalNotesClass;
