import { action, computed, observable, toJS } from "mobx";
import { FiltersState, FiltersStore } from "./state/filters";
import { ViewState, ViewStore } from "./state/view";
import { CustomerState, CustomerStore } from "./state/customer";
import { ModalState, ModalStore } from "./state/modal";
import { OrderConfigDisposable, OrderConfigStore } from "./state/order-config";
import { DishStore, DishState } from "./state/dish";
import { CartStore } from "./state/cart";
import { CheckoutState, CheckoutStore } from "./state/checkout";
import { APIStore } from "./api";
import stringify from "fast-safe-stringify";
import autobind from "autobind-decorator";
import {
  cc,
  logger,
  RestaurantUtils,
  GoogleServiceLib,
  menuRestrictedForOrder,
  menuRestrictionsEnabled,
  restaurantGetService,
  MenuRestrictedForOrderResult,
  momentNow,
  menuUtility,
  cloneDeepSafe,
} from "@lib/common";
import { OrderState, OrderStore } from "./state/order";
import { SessionStorage } from "../libs/session-storage";
import { LoaderState, LoaderStore } from "./state/loader";
import { BookingState, BookingStore } from "./state/booking";
import { OrderHistoryState, OrderHistoryStore } from "./state/order-history";
import moment from "moment-timezone";
import {
  AddressSourceEnumEnum,
  RestaurantMenuCategoryFragment,
  RestaurantMenuFragment,
  RestaurantMenuFullFragment,
  RestaurantStoreFragment,
} from "@lib/types/graphql";
import Push, { PushNotificationParams } from "push.js";
import { RouterState, RouterStore } from "./state/router";

interface InitialStates {
  restaurant: RestaurantStoreFragment;
  customer?: CustomerState;
  order_config?: Partial<T.Order.ConfigState>;
  order_config_d?: OrderConfigDisposable;
  order_history?: OrderHistoryState;
  filters?: FiltersState;
  dish?: DishState;
  checkout?: CheckoutState;
  view?: ViewState;
  modal?: ModalState;
  order?: OrderState;
  booking?: BookingState;
  loader?: LoaderState;
  router?: RouterState;
}

@autobind
export class RootStore {
  pushInitialized = false;

  r: RestaurantStoreFragment;
  @observable rMenus: RestaurantStoreFragment["menus"];
  @observable rOptionSets: RestaurantStoreFragment["option_sets"];
  api: APIStore;

  // intl: IntlStore;
  customer: CustomerStore;
  order_config: OrderConfigStore;
  order_history: OrderHistoryStore;
  filters: FiltersStore;
  dish: DishStore;
  cart: CartStore;
  checkout: CheckoutStore;
  view: ViewStore;
  modal: ModalStore;
  order: OrderStore;
  booking: BookingStore;
  loader: LoaderStore;
  router: RouterStore;

  // googleMapsClient: null | ReturnType<typeof GoogleServiceLib> = null;

  // simply pass a parsed serialized state
  constructor(initialStates: InitialStates) {
    this.r = initialStates.restaurant;
    console.time(`Set menus - ${this.r.name}`);
    this.rMenus = this.r.menus;
    this.rOptionSets = this.r.option_sets;
    console.timeEnd(`Set menus - ${this.r.name}`);

    this.api = new APIStore(this);

    this.router = new RouterStore(initialStates.router);
    this.order_config = new OrderConfigStore(
      this,
      initialStates.order_config,
      initialStates.order_config_d
    );
    this.order_history = new OrderHistoryStore(this, initialStates.order_history);
    this.filters = new FiltersStore(this, initialStates.filters);
    this.customer = new CustomerStore(this, initialStates.customer);
    this.cart = new CartStore(this);
    this.checkout = new CheckoutStore(this, initialStates.checkout);
    this.dish = new DishStore(this, initialStates.dish);
    this.view = new ViewStore(this, initialStates.view);
    this.modal = new ModalStore(this, initialStates.modal);
    this.order = new OrderStore(this, initialStates.order);
    this.booking = new BookingStore(this, initialStates.booking);
    this.loader = new LoaderStore(this, initialStates.loader);

    if (typeof window !== "undefined") {
      // Remove time reservation if user leaves site
      window.addEventListener("beforeunload", (e) => {
        if (
          this.order_config.d.confirmed &&
          this.order_config.s.payment_type !== "poli" &&
          this.order_config.s.payment_type !== "windcave" &&
          this.order_config.s.payment_type !== "paymark_eftpos"
        ) {
          this.api
            .schedulerUnreserveTime({
              restaurant_id: this.r.id,
              order_id: this.order_config.s.id,
            })
            .catch(logger.captureException);
        }

        if (
          !this.view.s.is_web_view &&
          this.cart.dishes.length > 0 &&
          this.order_config.s.payment_type !== "poli" &&
          this.order_config.s.payment_type !== "windcave" &&
          this.order_config.s.payment_type !== "paymark_eftpos"
        ) {
          // Prevent closing page if items in cart
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = "";
        }
      });

      /*
      if (this.mapType === "google_maps") {
        try {
          this.googleMapsClient = GoogleServiceLib(cc.google.apiKey);
        } catch (e) {
          logger.captureException(e)
        }
      }
      */
    }
  }

  @computed get mapType(): AddressSourceEnumEnum {
    const r = this.r;
    return "google_maps"; // r.location.map_data.type;
  }

  @computed get googleMapsClient() {
    try {
      if (typeof window === "undefined" || this.mapType !== "google_maps") {
        return null;
      }
      return GoogleServiceLib(cc.google.apiKey);
    } catch (e) {
      logger.captureException(e);
    }
  }

  @computed get menusAll(): Array<
    RestaurantMenuFullFragment & {
      categories: Array<
        RestaurantMenuFullFragment["categories"][0] & {
          dishes: Array<
            RestaurantMenuFullFragment["categories"][0]["dishes"][0] & {
              surcharge_pct?: number;
            }
          >;
        }
      >;
    }
  > {
    console.time(`Compute menus - ${this.r.name}`);
    const managed_delivery = this.r.service_delivery?.managed_enabled;
    const service = this.order_config.s.config_service;
    const menus = this.rMenus.map((m) => {
      return {
        ...m,
        categories: m.categories.map((c) => {
          const pct_pickup = c.surcharge_pct_pickup || m.surcharge_pct_pickup || 0;
          const pct_delivery = c.surcharge_pct_delivery || m.surcharge_pct_delivery || 0;
          const pct_delivery_managed =
            c.surcharge_pct_delivery_managed || m.surcharge_pct_delivery_managed || 0;
          const pct_dine_in = c.surcharge_pct_dine_in || m.surcharge_pct_dine_in || 0;
          const surcharge_pct =
            service === "pickup"
              ? pct_pickup
              : managed_delivery && service === "delivery"
              ? pct_delivery_managed
              : !managed_delivery && service === "delivery"
              ? pct_delivery
              : service === "dine_in"
              ? pct_dine_in
              : Math.min(pct_pickup, pct_delivery, pct_delivery_managed, pct_dine_in);
          if (surcharge_pct <= 0) {
            return c;
            /*
            return {
              ...c,
              dishes: c.dishes.map((d) => {
                return {
                  ...d,
                  surcharge_pct: 0,
                };
              }),
            };
            */
          }
          return {
            ...c,
            dishes: c.dishes.map((di) => {
              const d = cloneDeepSafe(di);
              if (di.type === "combo") {
                d.choices = d.choices.map((choice) => {
                  return {
                    ...choice,
                    dishes: choice.dishes.map((choiceDish) => {
                      return {
                        ...choiceDish,
                        price:
                          menuUtility.surcharge({
                            price: choiceDish.price,
                            surchargePct: surcharge_pct,
                          }) || 0,
                      };
                    }),
                  };
                });
              }
              return {
                ...d,
                price:
                  menuUtility.surcharge({
                    price: d.price,
                    surchargePct: surcharge_pct,
                  }) || 0,
                price_pickup: menuUtility.surcharge({
                  price: d.price_pickup,
                  surchargePct: surcharge_pct,
                }),
                price_delivery: menuUtility.surcharge({
                  price: d.price_delivery,
                  surchargePct: surcharge_pct,
                }),
                price_dine_in: menuUtility.surcharge({
                  price: d.price_dine_in,
                  surchargePct: surcharge_pct,
                }),
                surcharge_pct: surcharge_pct,
              };
            }),
          };
        }),
      };
    });
    console.timeEnd(`Compute menus - ${this.r.name}`);
    return menus;
  }
  @computed get menusAvailability() {
    return this.menusAll.map((m) => {
      return {
        menu: m,
        isRestricted: !m.restrict_hide_unavailable
          ? false
          : this.menuAvailabilityCheck(m).isRestricted,
      };
    });
  }
  @computed get menus() {
    return this.menusAvailability
      .filter((m) => {
        return !m.isRestricted;
      })
      .map((m) => m.menu);
  }
  @computed get menu() {
    const { filters } = this;
    return this.menusAll.find((m) => m.id === filters.s.menu);
  }

  @computed get menuHasRestrictions() {
    if (!this.menu) return false;
    return menuRestrictionsEnabled({
      item: this.menu,
    });
  }
  @computed get menuAvailability() {
    const menu = this.menu;
    if (!menu) {
      return {
        isRestricted: true,
        reason: "services",
      };
    }

    return this.menuAvailabilityCheck(menu);
  }
  @computed get menusContainAgeRestrictedProducts() {
    let ageRestrictedItemsInMenus = false;
    for (const m of this.menusAll) {
      if (m.restrict_age) {
        ageRestrictedItemsInMenus = true;
        break;
      }
      for (const c of m.categories) {
        if (c.restrict_age) {
          ageRestrictedItemsInMenus = true;
          break;
        }
      }
    }
    return ageRestrictedItemsInMenus;
  }

  menuAvailabilityCheck = (
    item: RestaurantMenuFragment | RestaurantMenuCategoryFragment,
    ignoreUnconfirmed?: boolean
  ): MenuRestrictedForOrderResult => {
    const oc = this.order_config.s;

    if (!ignoreUnconfirmed && !this.order_config.d.confirmed) {
      return {
        isRestricted: false,
        reason: "",
      };
    }

    return menuRestrictedForOrder({
      item: item,
      orderDue: oc.config_due,
      orderService: oc.config_service,
      orderDueTimestamp: oc.config_due === "later" ? oc.config_timestamp : Date.now(),
      customerAgeVerified: false,
      customerAgeVerifiedPrompt: this.view.s.age_confirmed,
      requireId: false, // this.restaurant.settings.business.age_verification.require_id,
    });
  };

  @computed get service() {
    const oc = this.order_config.s;
    if (!oc.config_service) {
      throw new Error(`Calling orderService when no service selected`);
    }
    return restaurantGetService(this.r, oc.config_service);
  }
  @computed get serviceSafe() {
    const oc = this.order_config.s;
    if (!oc.config_service) {
      return null;
    }
    return restaurantGetService(this.r, oc.config_service);
  }

  @computed get serviceOrderingEnabled() {
    const { r } = this;
    return r.service_pickup!.enabled || r.service_delivery!.enabled || r.service_dine_in!.enabled;
  }

  @computed get serviceDeliveryEnabled() {
    return this.r.service_delivery!.enabled;
  }

  @computed get serviceBookingEnabled() {
    return this.r.service_table_booking!.enabled;
  }

  @computed get serviceDeliveryZones() {
    return (this.r.service_delivery_zones || []).filter((zone) => !zone.disabled);
  }

  @computed get orderingDisabled() {
    return this.r.setting!.disable_ordering || false;
  }

  @computed get servicesAllDisabled(): boolean {
    const r = this.r;
    const now = momentNow();
    logger.info(`MOMENT: ${now.format("DD/MM/YYYY hh:mma")} - ${now.valueOf()}`);
    const services = [
      r.service_pickup!,
      r.service_delivery!,
      r.service_dine_in!,
      r.service_table_booking!,
    ];
    for (const service of services) {
      if (service.enabled) {
        if (service.order_later) {
          return false;
        }
        const { time_first_order_offset, time_last_order_offset } = service;
        const hours: T.Business.Hours =
          service.hours.length > 0 ? service.hours : r.location!.opening_hours;
        const specialHours: T.Business.SpecialHours =
          (service.special_hours || []).length > 0
            ? service.special_hours
            : r.location!.special_hours;
        const open = RestaurantUtils.opening_hours.isTimeWithin({
          dt: now.clone(),
          timezone: cc.timezone,
          hours: hours,
          special_hours: specialHours,
          first_offset: time_first_order_offset || 0,
          last_offset: time_last_order_offset || 0,
        }).isWithin;
        if (open) {
          return false;
        }
      }
    }
    return true;
  }

  @computed get servicesEnabledCount(): number {
    const r = this.r;
    let count = 0;
    if (r.service_pickup!.enabled) count++;
    else if (r.service_delivery!.enabled) count++;
    else if (r.service_dine_in!.enabled) count++;
    else if (r.service_table_booking!.enabled) count++;
    return count;
  }

  @computed get storeOpen(): boolean {
    const r = this.r;
    return (
      !r.setting!.disable_ordering &&
      RestaurantUtils.opening_hours.isTimeWithin({
        dt: moment.tz(Date.now(), cc.timezone),
        hours: r.location!.opening_hours,
        timezone: cc.timezone,
        last_offset: 0,
        first_offset: 0,
      }).isWithin
    );
  }

  @computed get managedDeliveryEnabled() {
    return !!this.r.service_delivery?.managed_enabled;
  }
  @computed get managedDeliveryActive() {
    return this.order_config.s.config_service === "delivery" && this.managedDeliveryEnabled;
  }

  @action syncMenus = async () => {
    try {
      const data = await this.api.restaurant_menus({});
      this.rMenus = data.menus;
      this.rOptionSets = data.option_sets;
    } catch (e) {
      logger.captureException(e);
    }
  };

  // DOES NOT AFFECT ORDERS FOR A LATER DATE
  serviceOpen = (): boolean => {
    const r = this.r;

    const service = this.service;

    if (!service) return true;

    const { time_first_order_offset, time_last_order_offset } = service;

    const hours: T.Business.Hours =
      service.hours.length > 0 ? service.hours : r.location!.opening_hours;
    const specialHours: T.Business.SpecialHours =
      (service.special_hours || []).length > 0 ? service.special_hours : r.location!.special_hours;

    return RestaurantUtils.opening_hours.isTimeWithin({
      dt: momentNow(),
      timezone: cc.timezone,
      hours: hours,
      special_hours: specialHours,
      first_offset: time_first_order_offset || 0,
      last_offset: time_last_order_offset || 0,
    }).isWithin;
  };

  // DOES NOT AFFECT ORDERS FOR A LATER DATE
  serviceClosesIn = (): null | { minutes: number; millis: number } => {
    const r = this.r;
    const oc = this.order_config.s;
    const service = this.service;
    if (!service || !oc.config_due) return null;

    const { time_first_order_offset, time_last_order_offset } = service;
    const hours: T.Business.Hours =
      service.hours.length > 0 ? service.hours : r.location!.opening_hours;
    const specialHours: T.Business.SpecialHours =
      (service.special_hours || []).length > 0 ? service.special_hours : r.location!.special_hours;
    return RestaurantUtils.opening_hours.closesIn({
      dt: momentNow(),
      timezone: cc.timezone,
      hours: hours,
      special_hours: specialHours,
      first_offset: time_first_order_offset || 0,
      last_offset: time_last_order_offset || 0,
    });
  };

  get alertTaxisEnabled() {
    return this.r.integration_mti_dispatch!.enabled;
  }

  // Notifications
  initPushNotification = () => {
    try {
      if (!Push.Permission.has() && !this.pushInitialized) {
        Push.Permission.request(
          () => {},
          () => {}
        );
        this.pushInitialized = true;
      }
    } catch (e) {
      logger.captureException(e);
    }
  };
  sendPushNotification = async (title: string, opts: PushNotificationParams) => {
    try {
      if (Push.Permission.has()) {
        const n = await Push.create(title, {
          vibrate: true,
          icon: "/store-notification-icon.png",
          link: this.router.s.path,
          onClick: () => {
            n.close();
            window.focus();
          },
          ...opts,
        });
      }
    } catch (e) {
      logger.captureException(e);
    }
  };

  // Sessions & Serialization
  serializeForSSR = (): string => {
    const state: InitialStates = {
      checkout: toJS(this.checkout.s),
      customer: toJS(this.customer.s),
      dish: toJS(this.dish.s),
      filters: toJS(this.filters.s),
      loader: toJS(this.loader.s),
      modal: toJS(this.modal.s),
      order_config: toJS(this.order_config.s),
      order_config_d: toJS(this.order_config.d),
      router: toJS(this.router.s),
      restaurant: this.r,
      view: toJS(this.view.s),
    };
    return stringify(state);
  };
  sessionSave = (ref: string, meta: T.ObjectAny = {}) => {
    SessionStorage.set(
      ref,
      stringify({
        checkout: toJS(this.checkout.s),
        customer: toJS(this.customer.s),
        dish: toJS(this.dish.s),
        filters: toJS(this.filters.s),
        loader: toJS(this.loader.s),
        modal: toJS(this.modal.s),
        order_config: toJS(this.order_config.s),
        order_config_d: toJS(this.order_config.d),
        view: toJS(this.view.s),
        // router: toJS(this.router.s),
        // restaurant: this.r,
        created: Date.now(),
        meta: meta,
      })
    );
  };
  sessionRestore = (ref: string, expiry?: number) => {
    const data = SessionStorage.get(ref);
    if (data) {
      if (data.created && Date.now() > data.created + expiry) {
        return false;
      }

      this.checkout.update(data.checkout);
      this.customer.update(data.customer);
      this.dish.update(data.dish);
      this.filters.update(data.filters);
      this.loader.update(data.loader);
      this.order_config.update(data.order_config);
      this.order_config.updateD(data.order_config_d);
      this.view.update(data.view);

      if (this.router.s.path === "/") {
        this.modal.update(data.modal);
      }

      // this.router.update(data.router);

      return data.meta;
    }
    return false;
  };
}
