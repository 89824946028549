import axios, { AxiosError } from "axios";
import { RootStore } from "./store";
import { ApiError, cc, logger } from "@lib/common";

export class APIStore {
  store: RootStore;

  constructor(store: RootStore) {
    this.store = store;
  }

  async request(args: T.API.RequestArgs): Promise<any> {
    const { public_key } = this.store.r;
    const { path, data, params, headers } = args;
    try {
      const res = await axios({
        baseURL: cc.api.url + "/",
        url: path,
        method: "post",
        headers: { "Authorization-Store": public_key, ...(headers || {}) },
        params: params || {},
        data: data || {},
        withCredentials: true,
      });
      return res.data;
    } catch (e) {
      if (e.response && e.response.data) {
        if (e.response.data.message === "Invalid Authentication") {
        }
        throw e.response.data;
      } else {
        throw e;
      }
    }
  }

  async requestV2(args: T.API.RequestArgs): Promise<any> {
    const { public_key } = this.store.r;
    const { path, data, params, headers } = args;
    try {
      const res = await axios({
        baseURL: cc.api.url + "/",
        url: path,
        method: "post",
        headers: { "Authorization-Store": public_key, ...(headers || {}) },
        params: params || {},
        data: data || {},
        withCredentials: true,
      });
      return res.data;
    } catch (e) {
      if (e.isAxiosError) {
        const error: AxiosError = e;
        const responseData = error.response?.data;
        const defaultMessage = `Something went wrong, try again`;
        const message =
          typeof data === "string"
            ? defaultMessage
            : responseData
            ? responseData.message
              ? responseData.message
              : defaultMessage
            : defaultMessage;
        throw ApiError.create({
          url: `${cc.api.url}/${path}`,
          code: error.response?.status || 0,
          message: message,
          response: responseData,
        });
      }
      throw e;
    }
  }

  async addressAutocomplete(
    data: T.API.ServiceAddressAutocompleteReq
  ): Promise<T.API.ServiceAddressAutocompleteRes> {
    return this.requestV2({
      path: "/services/address/autocomplete",
      data: data,
    });
  }

  async customerLoginInit(data: T.API.CustomerLoginInitReq): Promise<T.API.CustomerLoginInitRes> {
    return this.requestV2({
      path: "/customers/login/init",
      data: data,
    });
  }
  async customerLogin(data: T.API.CustomerLoginReq): Promise<T.API.CustomerLoginRes> {
    return this.requestV2({
      path: "/customers/login",
      data: data,
    });
  }
  async customerLoginFacebook(
    data: T.API.CustomerLoginFacebookReq
  ): Promise<T.API.CustomerLoginFacebookRes> {
    return this.requestV2({
      path: "/customers/login/facebook",
      data: data,
    });
  }
  async customerLoginRefresh(
    data: T.API.CustomerTokenRefreshReq
  ): Promise<T.API.CustomerTokenRefreshRes> {
    return this.requestV2({
      path: "/customers/login/refresh",
      data: data,
    });
  }
  async customerLogout(): Promise<void> {
    return this.requestV2({
      path: "/customers/logout",
      data: {},
    });
  }
  async customerOrders(data: T.API.CustomerOrdersReq): Promise<T.API.CustomerOrdersRes> {
    return this.requestV2({
      path: "/customers/orders",
      data: data,
    });
  }
  async customerRestaurantFavouriteToggle(
    data: T.API.CustomerFavouritesToggleReq
  ): Promise<T.API.CustomerFavouritesToggleRes> {
    return this.requestV2({
      path: "/customers/restaurant-favourite/toggle",
      data: data,
    });
  }

  async schedulerListDays(
    data: T.API.StoreSchedulerDayListReq
  ): Promise<T.API.StoreSchedulerDayListRes> {
    return this.requestV2({
      path: "/stores/scheduler/list/days",
      data: data,
    });
  }
  async schedulerListTimes(
    data: T.API.StoreSchedulerTimeListReq
  ): Promise<T.API.StoreSchedulerTimeListRes> {
    return this.requestV2({
      path: "/stores/scheduler/list/times",
      data: data,
    });
  }
  async schedulerNextTime(
    data: T.API.StoreSchedulerNextTimeReq
  ): Promise<T.API.StoreSchedulerNextTimeRes> {
    return this.requestV2({
      path: "/stores/scheduler/next",
      data: data,
    });
  }

  async schedulerReserveTime(
    data: T.API.StoreSchedulerReserveTimeReq
  ): Promise<T.API.StoreSchedulerReserveTimeRes> {
    return this.requestV2({
      path: "/stores/scheduler/reserve-time",
      data: data,
    });
  }
  async schedulerUnreserveTime(
    data: T.API.StoreSchedulerUnReserveTimeReq
  ): Promise<T.API.StoreSchedulerUnReserveTimeRes> {
    return this.requestV2({
      path: "/stores/scheduler/unreserve-time",
      data: data,
    });
  }

  async restaurant_menus(
    data: T.API.StoresRestaurantFindMenusReq
  ): Promise<T.API.StoresRestaurantFindMenusRes> {
    return this.request({
      path: "/stores/restaurant/find/menus",
      data: data,
    });
  }

  async order_validate(
    data: T.API.StoresOrderCreateValidateRequest
  ): Promise<T.API.StoresOrderCreateValidateResponse> {
    return this.request({
      path: "/stores/order/create/validate",
      data: data,
    });
  }
  async order_validate_id(
    data: T.API.StoresOrderCreateValidateIDReq
  ): Promise<T.API.StoresOrderCreateValidateIDRes> {
    return this.request({
      path: "/stores/order/create/validate/id",
      data: data,
    });
  }
  async order_validate_stock(
    data: T.API.StoresOrderCreateValidateStockReq
  ): Promise<T.API.StoresOrderCreateValidateStockRes> {
    return this.request({
      path: "/stores/order/create/validate/stock",
      data: data,
    });
  }

  async order_create(data: T.API.StoresOrderCreateReq): Promise<T.API.StoresOrderCreateRes> {
    return this.request({
      path: "/stores/order/create",
      data: data,
    });
  }
  async order_create_poli(
    data: T.API.StoresOrderCreatePoliReq
  ): Promise<T.API.StoresOrderCreatePoliRes> {
    return this.request({
      path: "/stores/order/create/poli",
      data: data,
    });
  }
  async order_create_windcave(
    data: T.API.StoresOrderCreateWindcaveReq
  ): Promise<T.API.StoresOrderCreateWindcaveRes> {
    return this.request({
      path: "/stores/order/create/windcave",
      data: data,
    });
  }
  async order_create_paymark_eftpos(
    data: T.API.StoresOrderCreatePaymarkEftposReq
  ): Promise<T.API.StoresOrderCreatePaymarkEftposRes> {
    return this.request({
      path: "/stores/order/create/paymark-eftpos",
      data: data,
    });
  }
  async order_create_paymark_eftpos_check(
    data: T.API.StoresOrderCreatePaymarkEftposCheckReq
  ): Promise<T.API.StoresOrderCreatePaymarkEftposCheckRes> {
    return this.request({
      path: "/stores/order/create/paymark-eftpos-check",
      data: data,
    });
  }
  async order_create_paymark_eftpos_cancel(
    data: T.API.StoresOrderCreatePaymarkEftposCancelReq
  ): Promise<T.API.StoresOrderCreatePaymarkEftposCancelRes> {
    return this.request({
      path: "/stores/order/create/paymark-eftpos-cancel",
      data: data,
    });
  }
  async order_find(data: T.API.StoresOrderFindRequest): Promise<T.API.StoresOrderFindResponse> {
    return this.requestV2({
      path: "/stores/order/find",
      data: data,
    });
  }

  async booking_create(
    data: T.API.StoresBookingCreateRequest
  ): Promise<T.API.StoresBookingCreateResponse> {
    return this.requestV2({
      path: "/stores/booking/create",
      data: data,
    });
  }
  async booking_find(
    data: T.API.StoresBookingFindRequest
  ): Promise<T.API.StoresBookingFindResponse> {
    return this.requestV2({
      path: "/stores/booking/find",
      data: data,
    });
  }

  async custom_payment(
    data: T.API.StoresCustomPaymentRequest
  ): Promise<T.API.StoresCustomPaymentResponse> {
    return this.request({
      path: "/stores/custom-payment",
      data: data,
    });
  }

  async gen_id(data: T.API.ServiceUUIDGenerateReq): Promise<T.API.ServiceUUIDGenerateRes> {
    return this.requestV2({
      path: "/services/uuid/generate",
      data: data,
    });
  }
}
