import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent } from "@lib/components";
import { OrderConfigServices } from "./stages/services";
import { OrderConfigAddressGoogle } from "./stages/address.google";
import { OrderConfigDue } from "./stages/due";
import { OrderConfigDatetime } from "./stages/datetime";
import { OrderConfigTables } from "./stages/table";
import { OrderConfigNumberOfPeople } from "./stages/number_of_people";
import { OrderConfigConfirmed } from "./stages/confirmed";
import { BookingConfig } from "./stages/booking";
import { MobxComponent } from "../../../mobx/component";

interface Props {}
interface State {}

@inject("store")
@observer
class OrderConfigModalClass extends MobxComponent<Props, State> {
  stages = () => {
    const r = this.injected.store.r;
    const oc = this.injected.store.order_config.s;
    const od = this.injected.store.order_config.d;

    const stages = {
      address: false,
      booking: false,
      when: false,
      datetime: false,
      table: false,
      number_of_people: false,
      confirmed: false,
    };

    if (oc.config_service === "pickup") {
      stages.when = true;
      if (oc.config_due === "now") {
        stages.confirmed = true;
      } else if (oc.config_due === "later") {
        stages.datetime = true;
        if (oc.config_timestamp) {
          stages.confirmed = true;
        }
      }
    } else if (oc.config_service === "delivery") {
      stages.address = true;
      if (oc.config_address && oc.config_address.number !== "") {
        stages.when = true;
        if (oc.config_due === "now") {
          stages.confirmed = true;
        } else if (oc.config_due === "later") {
          stages.datetime = true;
          if (oc.config_timestamp) {
            stages.confirmed = true;
          }
        }
      }
    } else if (oc.config_service === "dine_in") {
      stages.when = true;
      if (oc.config_due === "now") {
        stages.table = true;
        if (oc.config_table_id) {
          const table = r.service_dine_in_tables.find((t) => {
            return oc.config_table_id === t.id || oc.config_table_id === t.short_id;
          });
          if (table && (!table.password || table.password === od.table_password)) {
            stages.confirmed = true;
          }
        }
      } else if (oc.config_due === "later") {
        stages.datetime = true;
        if (oc.config_timestamp) {
          stages.number_of_people = true;
          if (oc.config_num_people) {
            stages.confirmed = true;
          }
        }
      }
    } else if (oc.config_service === "table_booking") {
      stages.datetime = true;
      if (oc.config_timestamp) {
        stages.booking = true;
      }
    }

    return stages;
  };

  render() {
    const { modal, orderingDisabled, servicesAllDisabled } = this.injected.store;

    const stages = this.stages();

    return (
      <Modal
        width="md"
        id="modal-order-config"
        closeButton={true}
        active={modal.isVisible("order-config")}
        close={() => modal.hide("order-config")}>
        {(servicesAllDisabled || orderingDisabled) && (
          <ModalContent paddingtb={50} paddinglr={15} className="">
            <p className="text-center text-error mt-1">
              {servicesAllDisabled &&
                !orderingDisabled &&
                "Sorry, we are currently closed / not accepting any orders"}
              {!servicesAllDisabled &&
                orderingDisabled &&
                "Sorry, we are currently not accepting any orders"}
            </p>
          </ModalContent>
        )}

        {!servicesAllDisabled && !orderingDisabled && (
          <ModalContent paddingtb={50} paddinglr={15} className="">
            <OrderConfigServices />
            {stages.address && <OrderConfigAddressGoogle />}
            {stages.when && <OrderConfigDue />}
            {stages.datetime && <OrderConfigDatetime />}
            {stages.table && <OrderConfigTables />}
            {stages.number_of_people && <OrderConfigNumberOfPeople />}
            {stages.confirmed && <OrderConfigConfirmed />}
            {stages.booking && <BookingConfig />}
          </ModalContent>
        )}
      </Modal>
    );
  }
}

export const OrderConfigModal = OrderConfigModalClass;
