import * as React from "react";
import { styled } from "@lib/components";

export const TabSelectComponent = styled("div")<{ background: string; hasBorder?: boolean }>`
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background};
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  height: ${({ hasBorder }) => (hasBorder ? 44 : 42)}px;
  z-index: 5;
`;

export const TabSelectHighlightComponent = styled("div")<{ startPosition: number; width: number }>`
  position: absolute;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary};
  transition: all 0.3s ease;
  top: 40px;
  transform: translateX(${({ startPosition }) => startPosition}px);
  width: ${({ width }) => width}px;
`;

export const TabSelectContentComponent = styled("div")`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  line-height: 0;
  overflow-x: auto;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &::-webkit-scrollbar {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-button {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-track-piece {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-thumb {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
  &::-webkit-scrollbar-corner {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
`;

export const TabSelectOptionsComponent = styled("div")`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  transition: 0.3s all;
`;

export const TabSelectOptionComponent = styled("div")<{ hidden?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 0 12px;
  height: 39px;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};
`;
