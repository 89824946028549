import * as React from "react";
import Measure, { ContentRect } from "react-measure";
import { observer, inject } from "mobx-react";
import { NavigationMain } from "./main";
import { NavigationMenus } from "./menus";
import { styled } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";

interface Props {}

const Wrapper = styled("menu")`
  position: sticky;
  z-index: 10;
  display: block;
  top: 0;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  transition: 0.14s all;
`;

@inject("store")
@observer
export class Navigation extends MobxComponent<Props> {
  // lastScroll: number = 0;
  // topHiddenAt: number = 0;

  onComponentResize = (contentRect: ContentRect) => {
    if (contentRect.bounds) {
      this.injected.store.view.update({
        top_nav_height: contentRect.bounds.height,
      });
    }
  };

  render() {
    const { store } = this.injected;
    const hideTop = false;
    // const { scroll_top, screen_width } = store.view.s;
    /*
    if (screen_width < CoreUtils.ui.breakpoints.lg && scroll_top > 0) {
      if (scroll_top > this.lastScroll) {
        hideTop = true;
        this.topHiddenAt = scroll_top;
      }
      else if ((this.topHiddenAt !== 0) && scroll_top > (this.topHiddenAt - 100)) {
        hideTop = true;
      }
      this.lastScroll = scroll_top;
    }
    */
    const { isLoggedIn } = store.customer;
    const { logged_in_only } = store.r.setting!;
    const blur = logged_in_only && !isLoggedIn;

    return (
      <Measure bounds={true} onResize={this.onComponentResize}>
        {({ measureRef }) => (
          <Wrapper
            id="top-navigation"
            ref={measureRef as any}
            className="shadow"
            style={{
              transform: `translateY(${hideTop ? "-28" : "0"}px)`,
              ...(!blur
                ? null
                : {
                    filter: "blur(5px)",
                    transform: "scale(1.01)",
                  }),
            }}>
            {/*<NavigationTop/>*/}
            <NavigationMain />
            <NavigationMenus />
          </Wrapper>
        )}
      </Measure>
    );
  }
}
