import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { FaFacebook, FaUser } from "react-icons/fa";
import {
  RotateLoader,
  FormGroup,
  Modal,
  ModalContent,
  CircleIcon,
  Input,
  Button,
} from "@lib/components";
import { Field, FieldProps, Form, Formik } from "formik";
import { FacebookButton } from "./components";
import { ApiError, logger } from "@lib/common";

interface Props {}
interface State {
  mounted: boolean;
  loginEmail: string;
}

@inject("store")
@observer
class AuthModalClass extends MobxComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mounted: false,
      loginEmail: "",
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  close = () => {
    const { store } = this.injected;
    store.modal.back();
    store.router.push("/");
  };

  render() {
    const { mounted, loginEmail } = this.state;
    const { store } = this.injected;
    const r = store.r;
    const path = store.router.s.path;

    const { fb_loading, fb_error } = store.customer.s;

    const { isLoggedIn } = store.customer;
    const { logged_in_only } = r.setting!;

    let active = logged_in_only && !isLoggedIn;

    if (!active) {
      active = path === "/login" || path === "/register" || store.modal.isVisible("auth");
    }

    if (active && isLoggedIn) {
      active = false;
    }

    return (
      <Modal
        width="xs"
        id="modal-auth"
        active={active}
        closeButton={!logged_in_only}
        persistChildren={true}
        close={this.close}>
        {!mounted && (
          <ModalContent paddinglr={20} paddingtb={100} className="rounded-sm">
            <RotateLoader size={3} />
          </ModalContent>
        )}

        {mounted && (
          <ModalContent paddingtb={30} paddinglr={30} className="children:mb-4 last-mb-0">
            <div className="-mt-12">
              <CircleIcon icon={<FaUser />} />
            </div>

            <div className="text-center">
              <h3 className="">Login / Signup</h3>
            </div>

            {loginEmail === "" && (
              <Formik
                initialValues={{ email: "" }}
                onSubmit={async (values, form) => {
                  try {
                    await store.api.customerLoginInit({
                      email: values.email,
                    });
                    this.setState({ loginEmail: values.email });
                  } catch (e) {
                    let error = "Something went wrong";
                    if (e instanceof ApiError) error = e.message;
                    else logger.captureException(e);
                    form.setFieldError("email", error);
                  }
                }}>
                {({ isSubmitting, errors }) => {
                  return (
                    <Form className="children:mb-4 last-mb-0">
                      <p className="text-md text-center -mt-2">Save your orders & details</p>

                      <Field name="email">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            type="email"
                            required={true}
                            placeholder="Your E-mail"
                            className="text-center"
                          />
                        )}
                      </Field>

                      {errors.email && (
                        <FormGroup error={errors.email} no_border={true} className="-mt-2" />
                      )}

                      <Button full={true} color="primary" disabled={isSubmitting}>
                        {!isSubmitting && "Continue"}
                        {isSubmitting && <RotateLoader color="white" size={2} />}
                      </Button>

                      {!store.view.s.is_web_view && store.r.setting!.facebook_app_id && (
                        <FacebookButton type="button" onClick={store.customer.loginFacebook}>
                          <FaFacebook />
                          {!fb_loading && <span className="text">Login With Facebook</span>}
                          {fb_loading && <RotateLoader color="white" size={2} />}
                        </FacebookButton>
                      )}

                      {!!fb_error && (
                        <FormGroup no_border={true} className="-mt-1" error={fb_error} />
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}

            {loginEmail !== "" && (
              <Formik
                initialValues={{
                  code: "",
                }}
                onSubmit={async (values, form) => {
                  try {
                    const { customer } = await store.api.customerLogin({
                      email: loginEmail,
                      code: values.code,
                    });
                    store.customer.login(customer);
                    this.setState({ loginEmail: "" });
                  } catch (e) {
                    let error = "Something went wrong";
                    if (e instanceof ApiError) error = e.message;
                    else logger.captureException(e);
                    form.setFieldError("code", error);
                  }
                }}>
                {({ isSubmitting, errors }) => {
                  return (
                    <Form className="children:mb-4 last-mb-0">
                      <Field name="code">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            type="text"
                            required={true}
                            placeholder="Login OTP"
                            className="text-center"
                            autoComplete="false"
                          />
                        )}
                      </Field>
                      <p className="text-sm text-center">
                        Check your e-mail ({loginEmail}) for your 6 digit OTP.{" "}
                        <a className="link" onClick={() => this.setState({ loginEmail: "" })}>
                          Start over
                        </a>
                      </p>
                      {errors.code && (
                        <FormGroup error={errors.code} no_border={true} className="-mt-1" />
                      )}
                      <Button full={true} color="primary" disabled={isSubmitting}>
                        {!isSubmitting && "Complete Login"}
                        {isSubmitting && <RotateLoader color="white" size={2} />}
                      </Button>
                    </Form>
                  );
                }}
              </Formik>
            )}

            <p className="text-center text-sm px-6">
              By proceeding, you are accepting our{" "}
              <a className="link" onClick={() => store.modal.show("terms")}>
                terms & conditions
              </a>
            </p>
          </ModalContent>
        )}
      </Modal>
    );
  }
}

export const AuthModal = AuthModalClass;
