import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { styled, shadeAlternate, Select } from "@lib/components";

interface Props {
  className?: string;
}
interface State {}

const Wrapper = styled("div")`
  width: 100%;
  border-bottom: 1px dashed ${({ theme }) => shadeAlternate(0.15, theme.colors.background)};
  @media (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: auto;
    border-bottom: none;
  }
`;

const SelectSVGArrow = `data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>`;

const SelectInput = styled(Select)`
  font-size: 14px;
  background: ${({ theme }) => theme.colors.background};
  color: var(--top-nav-text_color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("${SelectSVGArrow}");
  background-position: center right;
  background-repeat: no-repeat;
  padding-right: 20px;
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding-left: 0;
  }
  border: none !important;
  border-radius: 0;
  &:focus {
    border: none !important;
  }
  &::-ms-expand {
    display: none;
  }
`;

@inject("store")
@observer
class MenusDropdownClass extends MobxComponent<Props, State> {
  onMenuChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    this.injected.store.filters.set([value]);
    this.injected.store.filters.scrollToCategory();
  };

  render() {
    const { store } = this.injected;
    const { filters } = this.injected.store;
    if (store.menusAvailability.length === 1) return null;
    return (
      <Wrapper className={this.props.className}>
        <SelectInput
          className=""
          value={filters.s.menu}
          onChange={this.onMenuChange}
          options={store.menusAvailability.map((m) => ({
            label: m.isRestricted
              ? `${m.menu.display_name || m.menu.name} (N/A)`
              : m.menu.display_name || m.menu.name,
            value: m.menu.id,
            disabled: m.isRestricted,
          }))}
        />
      </Wrapper>
    );
  }
}

export const MenusDropdown = MenusDropdownClass;
