import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { List, Modal, ModalContent, RestaurantOpeningHours, FormGroup } from "@lib/components";
import { RestaurantServiceTimeEnumEnum, RestaurantServiceTypeEnumEnum } from "@lib/types/graphql";
import { formatTimeFromTime } from "@lib/common";

interface Props {}

@inject("store")
@observer
class MenuRestrictionsModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { modal } = store;
    const menu = store.menu;
    if (!menu) return null;
    // const r = store.r;
    // const c = store.customer.s.item;
    // const customerAgeVerified = (c && c.age_verification.status === "approved");
    if (!store.menuHasRestrictions) return null;
    const times: RestaurantServiceTimeEnumEnum[] = menu.restrict_times || [];
    const services: RestaurantServiceTypeEnumEnum[] = menu.restrict_services || [];
    const hours: T.Business.Hours = menu.restrict_hours || [];
    return (
      <Modal
        id="modal-menu-restrictions"
        width={360}
        closeButton={true}
        active={modal.isVisible("menu-restrictions")}
        close={() => modal.hide("menu-restrictions")}>
        <ModalContent paddingtb={25} paddinglr={25} className="text-md">
          {menu.restrict_age && (
            <FormGroup title="Age Restricted">
              <p>
                This menu / category contains age-restricted products and can only be purchased by
                adults age 18+
              </p>
              {/*r.settings.business.age_verification.require_id ? (
                <>
                  {customerAgeVerified && <p className="">Your account is verified and you can order from this menu</p>}
                  {!customerAgeVerified && <p className="">Requires age verification to order. Create an account and upload your ID</p>}
                </>
              ) : (
                <p>This menu / category contains age-restricted products and can only be purchased by adults age 18+</p>
              )*/}
            </FormGroup>
          )}

          {(services.length > 0 || times.length === 1) && (
            <FormGroup title="Order Requirements">
              <List className="children:mb-1 last-mb-0">
                {times.length === 1 && (
                  <li>{times[0] === "now" ? "No pre-orders" : "Pre-orders only"}</li>
                )}
                {services.map((s, i) => (
                  <li key={i}>
                    {
                      {
                        pickup: "Pickups",
                        delivery: "Deliveries",
                        dine_in: "Dine-Ins",
                        table_booking: "Table Bookings",
                      }[s]
                    }
                  </li>
                ))}
              </List>
            </FormGroup>
          )}

          {menu.restrict_pre_order && (
            <FormGroup title="Pre-Order Requirements">
              <List className="children:mb-1 last-mb-0">
                <li>Must order {menu.restrict_pre_order_days_advance} days in advance</li>
                <li>
                  Last day cut-off time at {formatTimeFromTime(menu.restrict_pre_order_cutoff)}
                </li>
                {(menu.restrict_pre_order_services || []).length > 0 && (
                  <li>Only applies to {menu.restrict_pre_order_services.join(", ")}</li>
                )}
              </List>
            </FormGroup>
          )}

          {hours.length > 0 && (
            <FormGroup title="Available Hours" contentClassName="text-md">
              <RestaurantOpeningHours hours={hours} />
            </FormGroup>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

export const MenuRestrictionsModal = MenuRestrictionsModalClass;
