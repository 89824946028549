import * as React from "react";
import { observer, inject } from "mobx-react";
import { FaChevronUp, FaChevronDown, FaShoppingBag } from "react-icons/fa";
import { styled, Button, CircleIcon, shadeAlternate } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";
import { action, observable } from "mobx";
import { formatCurrency } from "@lib/common";

interface Props {}
interface State {
  active: number | null;
}

const ServiceInfoWrapper = styled("div")``;

const ServiceInfoHeader = styled("div")`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px dashed ${({ theme }) => shadeAlternate(0.2, theme.colors.background)};
  padding: 0.5rem 0;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    color: ${({ theme }) => shadeAlternate(0.5, theme.colors.background)};
  }
`;

const ServiceInfoContent = styled("div")`
  padding: 20px 0 15px 0;
`;

export interface ServiceInfoProps {
  title: string;
  content?: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
}

export const ServiceInfo = (props: ServiceInfoProps) => (
  <ServiceInfoWrapper>
    <ServiceInfoHeader onClick={props.onClick}>
      <span className="text-md">{props.title}</span>
      {!!props.content && (props.active ? <FaChevronUp /> : <FaChevronDown />)}
    </ServiceInfoHeader>
    {props.content && props.active && <ServiceInfoContent>{props.content}</ServiceInfoContent>}
  </ServiceInfoWrapper>
);

@inject("store")
@observer
class InfoServicesClass extends MobxComponent<Props, State> {
  @observable active: number | null = null;

  @action setActive = (i: number | null) => {
    this.active = this.active === i ? null : i;
  };

  render() {
    const r = this.injected.store.r;
    const modal = this.injected.store.modal;
    const { active } = this;
    const pickup = r.service_pickup!;
    const delivery = r.service_delivery!;
    const dine_in = r.service_dine_in!;
    const table_booking = r.service_table_booking!;
    const fee_ranges: T.Models.Restaurant.Services.DeliveryFeeRange[] = delivery.fee_ranges || [];
    const zones = this.injected.store.serviceDeliveryZones;
    const deliveryZonesEnabled = zones.length > 0;
    return (
      <>
        <CircleIcon icon={<FaShoppingBag />} />

        <h3 className="text-center mb-4 mt-4 font-normal">Services</h3>

        <div className="">
          {pickup.enabled && (
            <ServiceInfo
              title="Pickup"
              active={active === 0}
              onClick={() => this.setActive(0)}
              content={
                !pickup.notes ? null : (
                  <div>
                    <p className="font-semibold">Notes</p>
                    <p className="">{pickup.notes}</p>
                  </div>
                )
              }
            />
          )}

          {delivery.enabled && (
            <ServiceInfo
              title="Delivery"
              active={active === 1}
              onClick={() => this.setActive(1)}
              content={
                <div>
                  {!!delivery.notes && (
                    <div className="mb-4">
                      <p className="font-bold">Notes</p>
                      <p className="">{delivery.notes}</p>
                    </div>
                  )}

                  {!deliveryZonesEnabled && delivery.fee_type === "range" && (
                    <div className="mb-4">
                      <p className="font-bold">Delivery Ranges</p>
                      {fee_ranges.map((range, i) => (
                        <div
                          className="flex justify-between content-center items-center mt-2"
                          key={i}>
                          <p>
                            {i === 0 ? 0 : fee_ranges[i - 1].up_to}km - {range.up_to}km
                          </p>
                          <p>{range.price === 0 ? "FREE" : formatCurrency(range.price)}</p>
                        </div>
                      ))}
                    </div>
                  )}

                  {!deliveryZonesEnabled && delivery.fee_type === "formula" && (
                    <div className="mb-4">
                      <p className="font-bold">Delivery Fee</p>
                      <p className="">Varies, shown in cart</p>
                    </div>
                  )}

                  {!deliveryZonesEnabled && delivery.fee_type === "fixed" && (
                    <div className="mb-4">
                      <p className="font-bold">Delivery Fee</p>
                      <p className="">
                        {!delivery.fee_fixed_cost
                          ? "Free"
                          : formatCurrency(delivery.fee_fixed_cost)}
                      </p>
                    </div>
                  )}

                  {deliveryZonesEnabled && (
                    <div className="mb-4">
                      <p className="font-bold">Delivery Zones</p>
                      {zones.map((zone, i) => (
                        <div
                          className="flex justify-between content-center items-center mt-2"
                          key={i}>
                          <p>{zone.name}</p>
                          <p>{formatCurrency(zone.price)}</p>
                        </div>
                      ))}
                      <Button
                        size="xxs"
                        color="primary-inverse"
                        className="mt-2"
                        onClick={() => modal.toggle("map")}>
                        View Zones On Map
                      </Button>
                    </div>
                  )}

                  <div className="mb-4">
                    <p className="font-bold">Minimum Order</p>
                    <p className="">
                      {!delivery.min_order
                        ? "No Minimum Order"
                        : formatCurrency(delivery.min_order)}
                    </p>
                  </div>

                  {!!delivery.free_delivery_min && (
                    <div className="mb-4">
                      <p className="font-bold">Free Delivery Amount</p>
                      <p className="">{formatCurrency(delivery.free_delivery_min)}</p>
                    </div>
                  )}
                </div>
              }
            />
          )}

          {dine_in.enabled && (
            <ServiceInfo
              title="Dine-In"
              active={active === 2}
              onClick={() => this.setActive(2)}
              content={
                !dine_in.notes ? null : (
                  <div>
                    <p className="font-semibold">Notes</p>
                    <p className="">{dine_in.notes}</p>
                  </div>
                )
              }
            />
          )}

          {table_booking.enabled && (
            <ServiceInfo
              title="Table Booking"
              active={active === 3}
              onClick={() => this.setActive(3)}
              content={
                !table_booking.notes ? null : (
                  <div>
                    <p className="font-semibold">Notes</p>
                    <p className="">{table_booking.notes}</p>
                  </div>
                )
              }
            />
          )}
        </div>
      </>
    );
  }
}

export const InfoServices = InfoServicesClass;
