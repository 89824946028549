require("./init");
import "react-hot-loader";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { RootStore } from "../common/mobx/store";
import App from "../common/ui/index";
import { untrusive } from "./untrusive";
import lighten from "polished/lib/color/lighten";
import * as Sentry from "@sentry/browser";
import { cc, logger } from "@lib/common";

const preloadedTheme = window.__INITIAL_THEME__;
const preloadedState = window.__INITIAL_STATE__;
const store = new RootStore(preloadedState);

function loadStripe() {
  const PLATFORM_STD_PUB_KEY = cc.production
    ? "pk_live_K3SpxwjkQRncXA0zk3CZqNTZ"
    : "pk_test_Kt8S9YoWGDaUrQ7l0gTSDohO";
  const PLATFORM_EXP_PUB_KEY = cc.production
    ? "pk_live_51JwdqpHUowwO1oBhIjC7p7InaWKAX83dvqDK1iz2oWcEEqmlroKPkBnrAFRHVYv0yCTr4jT7ldjB8MxFhiZNDpzV001O0hkTRm"
    : "pk_test_51JwdqpHUowwO1oBhRiosTLJO0e8QtZp8i391p8cHMtOw3EDWNP8tetwM3r3SsNTMWdBHGevr3HFadVXOtPy0eo3000QtQjFmTQ";
  const stripe = store.r.payment_stripe!;

  let stripeTimer: any;

  function initStripe() {
    if (!!stripe.acc_id && !!stripe.acc_active) {
      logger.info("STRIPE LOADED EXP");
      window.stripe = window.Stripe(PLATFORM_EXP_PUB_KEY, {
        // stripeAccount: stripe.acc_id,
      });
    } else {
      logger.info("STRIPE LOADED");
      const pubKey = stripe.stripe_user_id ? PLATFORM_STD_PUB_KEY : stripe.publishable_key;
      window.stripe = window.Stripe(pubKey, {
        stripeAccount: stripe.stripe_user_id || undefined,
      });
    }
    window.stripeElements = window.stripe!.elements({ locale: "en-NZ" });
    clearInterval(stripeTimer);
  }

  // If the Stripe.js script is loaded, init it
  if (window.Stripe && !window.stripe) {
    initStripe();
  } else {
    stripeTimer = setInterval(() => {
      console.log("CHECK STRIPE");
      if (window.Stripe && !window.stripe) {
        initStripe();
      }
    }, 100);
  }
}

function render() {
  const root = document.getElementById("root");
  const { logged_in_only } = store.r.setting!;
  if (logged_in_only) {
    // IF LOGGED IN ONLY, USE RENDER TO RERENDER EVERYTHING TO UPDATE LOGIN STATE
    ReactDOM.render(<App store={store} theme={preloadedTheme} />, root);
  } else {
    ReactDOM.hydrate(<App store={store} theme={preloadedTheme} />, root);
  }
}

Sentry.configureScope((scope) => {
  scope.setExtra("restaurant_id", store.r.id);
  scope.setExtra("restaurant_name", store.r.name);
  scope.setExtra("host", window.location.host);
});

untrusive.init({
  bgColor: lighten(0.2, store.r.website!.color_primary),
  barColor: store.r.website!.color_primary,
});

loadStripe();
render();
store.checkout.check_order_callback();
