import * as React from "react";
import cn from "classnames";
import { cc, formatCurrency, formatPaymentMethodName } from "@lib/common";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { StripeCardForm } from "./methods/stripe";
import { BamboraApacForm } from "./methods/bambora_apac";
import {
  PaymentButtonWrapper,
  PaymentButton,
  PaymentImage,
  PaymentImageWrapper,
} from "./components";

// TODO ADD CDN TO PAYMENT LOGOS

interface Props {}

@inject("store")
@observer
class CheckoutPaymentClass extends MobxComponent<Props, { country: string }> {
  state = { country: "nz" };

  componentDidMount() {
    setTimeout(() => {
      try {
        this.setState({
          country: geotargetly_country_code().toLowerCase(),
        });
      } catch (e) {}
    }, 50);
  }

  render() {
    const { store } = this.injected;
    const { country } = this.state;
    const oc = store.order_config.s;
    const fees = store.cart.fees;
    const methods = store.checkout.paymentMethods.filter((m) => {
      if (cc.production && country !== "nz" && (m.type === "cash" || m.type === "card")) {
        return false;
      }
      return true;
    });

    const paymentFees = fees.filter((f) => f.is_payment_fee);

    const paymentImages = {
      stripe: ["/stripe-dark.png", "/mastercard.png", "/visa.png", "/american-express.png"],
      windcave: ["/mastercard.png", "/visa.png", "/american-express.png"],
      bambora: ["/mastercard.png", "/visa.png"],
      paymark_eftpos: ["/paymark-eftpos.png"],
      poli: ["/poli.png"],
    };

    if (methods.findIndex((m) => m.type === "stripe") !== -1) {
      paymentImages.stripe.splice(0, 1);
    }

    const imageBase = !cc.production ? `http://localhost:3000/payment-logos` : `/payment-logos`;
    const images = [];
    for (const m of methods) {
      const imgs = paymentImages[m.type as keyof typeof paymentImages];
      if (imgs) {
        for (const i of imgs) {
          if (images.indexOf(i) === -1) {
            images.push(i);
          }
        }
      }
    }

    return (
      <div>
        <PaymentButtonWrapper>
          {methods.map((m, i) => {
            let label = m.label || formatPaymentMethodName(m.type);
            if (m.label_delivery && oc.config_service === "delivery") {
              label = m.label_delivery;
            }
            let active = false;
            if (oc.payment_type === "custom") {
              if (oc.payment_type === m.type) {
                active = oc.payment_custom_id === m.custom_id;
              }
            } else {
              active = !m.subtype
                ? oc.payment_type === m.type
                : oc.payment_type === m.type && oc.payment_type_sub === m.subtype;
            }
            return (
              <PaymentButton
                key={i}
                title={label}
                active={active}
                onClick={() =>
                  store.order_config.update({
                    payment_type: m.type,
                    payment_type_sub: m.subtype,
                    payment_custom_id: m.custom_id,
                  })
                }
              />
            );
          })}
        </PaymentButtonWrapper>

        {images.length > 0 && (
          <PaymentImageWrapper>
            {images.map((i, k) => (
              <PaymentImage key={k}>
                <img src={`${imageBase}${i}`} alt="" />
              </PaymentImage>
            ))}
          </PaymentImageWrapper>
        )}

        {oc.payment_type === "stripe" && (
          <div className="pt-5 tally">
            <StripeCardForm />
          </div>
        )}

        {oc.payment_type === "bambora" && <BamboraApacForm />}

        {paymentFees.length > 0 &&
          paymentFees.map(({ name, value }, i) => (
            <p
              key={i}
              className={cn(
                "bg-warning text-white p-1 rounded text-center text-sm",
                i === 0 ? "mt-3" : "mt-2"
              )}>
              {name}
              <span className="font-semibold"> - {formatCurrency(value)}</span>
            </p>
          ))}
      </div>
    );
  }
}

export const CheckoutPayment = CheckoutPaymentClass;
