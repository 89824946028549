import * as React from "react";
import { observer, inject } from "mobx-react";
import { withTheme } from "@lib/components";
import { MobxComponent } from "../../../../mobx/component";
import { TabSelect } from "./tab-select";

@inject("store")
@observer
class MenusTabsClass extends MobxComponent {
  onCategoryChange = (selected: { label: string; value: string }) => {
    this.injected.store.filters.set(["", selected.value]);
    this.injected.store.filters.scrollToCategory();
  };

  render() {
    const theme = this.injected.theme;
    const { filters, menusAll } = this.injected.store;
    const menu = menusAll.find((m) => m.id === filters.s.menu);
    if (!menu) return null;
    const tabs = menu.categories.map((c) => ({
      value: c.id,
      label: c.display_name || c.name,
    }));
    return (
      <div className="flex-grow">
        <TabSelect
          id="menu-tab-select"
          value={filters.s.category}
          values={tabs}
          onChange={this.onCategoryChange}
          background={theme.colors.background}
        />
      </div>
    );
  }
}

// @ts-ignore
export const MenusTabs = withTheme(MenusTabsClass);
