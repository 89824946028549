import * as React from "react";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Modal } from "@lib/components";
import { OrderCart } from "../../logic/cart";

interface Props {}
interface State {}

@inject("store")
@observer
class CartModalClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    return (
      <Modal
        id="modal-cart"
        width={420}
        closeButton={true}
        active={store.view.s.screen_width < 1024 && store.modal.isVisible("cart")}
        close={() => store.modal.toggle("cart")}>
        <div className="p-6 sm:p-8">
          <OrderCart showChangeDetails={true} />
        </div>
      </Modal>
    );
  }
}

export const CartModal = CartModalClass;
