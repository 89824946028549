import { cc } from "@lib/common";
import moment from "moment-timezone";

export function orderReceiptFormatDeliveryTime(ts: number) {
  const m = moment.tz(ts, cc.timezone);
  const mEnd = m.clone().add(10, "minutes");

  const start = m.format("hh:mm");
  const end = mEnd.format("hh:mm");
  const amPm = mEnd.format("a");
  const isToday = mEnd.isSame(Date.now(), "day");

  if (isToday) return `${start} to ${end} ${amPm}`;

  const date = mEnd.format("DD/MM");

  return `${date}, ${start} to ${end} ${amPm}`;
}
