import { useMediaQuery } from "react-responsive";

// Taken for global tailwind breakpoints
const screens = {
  xs: "360px",
  sm: "480px",
  md: "720px",
  mmd: "890px",
  lg: "1024px",
  xl: "1140px",
  xxl: "1366px",
};

export function useScreenXs() {
  return useMediaQuery({
    query: `(max-width: ${screens.xs})`,
  });
}
export function useScreenSm() {
  return useMediaQuery({
    query: `(max-width: ${screens.sm})`,
  });
}
export function useScreenMd() {
  return useMediaQuery({
    query: `(max-width: ${screens.md})`,
  });
}
export function useScreenMmd() {
  return useMediaQuery({
    query: `(max-width: ${screens.mmd})`,
  });
}
export function useScreenLg() {
  return useMediaQuery({
    query: `(max-width: ${screens.lg})`,
  });
}
export function useScreenXl() {
  return useMediaQuery({
    query: `(max-width: ${screens.xl})`,
  });
}
export function useScreenXxl() {
  return useMediaQuery({
    query: `(max-width: ${screens.xxl})`,
  });
}
