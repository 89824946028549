import * as React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { styled } from "@lib/components";
import { ContentCSS } from "./components";

export const Wrapper = styled("div")`
  padding-left: 10px;
  padding-right: 10px;
`;

export const Content = styled("div")`
  ${ContentCSS};
  justify-content: center;
`;

export const NavigationAlert = observer(() => {
  const { r } = useStore();
  const w = r.website!;
  if (!w.alert_text) return null;
  const wrapperClass = cn("py-2", {
    "bg-warning-alt": w.alert_type === "warning",
    "bg-gray-100": w.alert_type === "default",
  });
  return (
    <Wrapper className={wrapperClass}>
      <Content>
        <span className={cn("text-sm text-center", w.alert_bold && "font-semibold")}>
          {w.alert_text}
        </span>
      </Content>
    </Wrapper>
  );
});
