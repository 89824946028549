import * as React from "react";
import { observer, inject } from "mobx-react";
import { ContentCSS } from "./components";
import { MobxComponent } from "../../../mobx/component";
import { FaChevronLeft, FaPowerOff, FaUserCircle, FaRegHeart, FaHeart } from "react-icons/fa";
import { cloneDeepSafe, CoreUtils, GN, logger } from "@lib/common";
import { styled } from "@lib/components";
import { untrusive } from "../../../../client/untrusive";

interface Props {}

const Wrapper = styled.div`
  padding: 10px 10px;
`;
const Content = styled("div")`
  ${ContentCSS};
`;
const UserThumbnail = styled("img")`
  vertical-align: middle;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 4px;
`;

@inject("store")
@observer
class NavigationMainClass extends MobxComponent<Props> {
  logout = () => {
    const { store } = this.injected;
    if (store.cart.dishes.length > 0) {
      store.modal.show("logout-warning");
    } else {
      store.customer.logout();
    }
  };

  favouriteToggle = async () => {
    const { store } = this.injected;
    const customer = store.customer.s.item;
    const favourites = customer?.restaurant_favourites || [];
    try {
      if (!customer) {
        store.modal.show("auth");
        return;
      }
      untrusive.start();
      await store.api.customerRestaurantFavouriteToggle({
        restaurant_id: store.r.id,
      });
      const existingIndex = favourites.findIndex((fav) => fav.restaurant_id === store.r.id);
      const newFavourites = cloneDeepSafe(favourites);
      if (existingIndex !== -1) {
        newFavourites.splice(existingIndex, 1);
        GN.add({
          type: "success",
          duration: 3000,
          message: "Removed from favourites",
        });
      } else {
        newFavourites.push({ restaurant_id: store.r.id });
        GN.add({
          type: "success",
          duration: 3000,
          message: "Added to favourites",
        });
      }
      store.customer.updateItem({ restaurant_favourites: newFavourites });
    } catch (e) {
      logger.captureException(e);
      GN.add({
        type: "error",
        duration: 3000,
        message: "Something went wrong, try again",
      });
    } finally {
      untrusive.stop();
    }
  };

  render() {
    const { store } = this.injected;
    const { modal } = store;

    const customer = store.customer.s.item;
    const showBackButton =
      (store.view.s.is_web_view || store.view.s.is_pwa) && !!store.view.s.reseller_portal_url;
    const { disable_signups } = store.r.setting!;

    const r = store.r;
    const w = r.website!;

    const imageUrl = CoreUtils.image.uc(w.top_nav_logo_image, { h: 50, fit: "clip", fm: "png" })!;

    function LogoWrapper(props: { children: React.ReactNode }) {
      if (w.top_nav_logo_image_link && !store.view.s.is_pwa && !store.view.s.is_web_view) {
        return (
          <a href={w.top_nav_logo_image_link} className="flex items-center no-underline">
            {props.children}
          </a>
        );
      }
      return <div className="flex items-center">{props.children}</div>;
    }

    const favourites = customer?.restaurant_favourites || [];
    const isFavourite = favourites.findIndex((fav) => fav.restaurant_id === r.id) !== -1;

    return (
      <Wrapper>
        <Content>
          <div className="flex items-center">
            {showBackButton && (
              <div
                className="pr-3 h-8 flex items-center"
                onClick={() => store.modal.toggle("reseller-portal-back")}>
                <FaChevronLeft />
              </div>
            )}
            <LogoWrapper>
              {w.top_nav_logo_image && (
                <img
                  src={imageUrl}
                  alt="Business logo"
                  style={{ maxHeight: "32px" }}
                  className="mr-3 rounded h-auto trans-200"
                />
              )}
              <h1 className="text-base md:text-lg">
                {w.top_nav_logo_text === " " ? "" : w.top_nav_logo_text || r.name}
              </h1>
            </LogoWrapper>
          </div>

          {!store.view.s.is_kiosk && (
            <div className="flex children:mr-4 last-mr-0 text-sm">
              <div className="flex items-center cursor-pointer" onClick={this.favouriteToggle}>
                <div className="hidden md:block mr-2">Favourite</div>
                {!isFavourite && <FaRegHeart className="text-lg" />}
                {isFavourite && <FaHeart className="text-lg" style={{ color: "#f52c2a" }} />}
              </div>

              {customer && (
                <>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => modal.show("customer")}>
                    <div className="hidden md:block mr-2">{store.customer.displayName}</div>
                    {customer.avatar && <UserThumbnail src={customer.avatar} />}
                    {!customer.avatar && <FaUserCircle className="text-lg" />}
                  </div>
                  <div className="flex items-center cursor-pointer" onClick={this.logout}>
                    <div className="hidden md:block mr-2">Logout</div>
                    <FaPowerOff className="text-lg" />
                  </div>
                </>
              )}

              {!customer && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => modal.show("auth")}>
                  <div className="hidden md:block mr-2">
                    {disable_signups ? "Login" : "Login / Sign Up"}
                  </div>
                  <FaUserCircle className="text-lg" />
                </div>
              )}
            </div>
          )}
        </Content>
      </Wrapper>
    );
  }
}

export const NavigationMain = NavigationMainClass;
