import { observable, action } from "mobx";
import { RootStore } from "../store";
import { windowScrollTop } from "../../../client/utils";

export interface FiltersState {
  menu: string;
  category: string;
}

export class FiltersStore {
  store: RootStore;
  scrollToCategoryActive = false;
  @observable s: FiltersState;

  constructor(store: RootStore, initialState?: FiltersState) {
    this.store = store;
    this.s = {
      menu: "",
      category: "",
      ...(initialState || {}),
    };
  }

  @action set = (data: string[]) => {
    // data is either [menu_id] or ["", category_id]

    const menu_id = data[0];
    const category_id = data[1];

    if (category_id) {
      this.s.category = category_id;
    } else if (menu_id && this.s.menu !== menu_id) {
      // multi menu, change menu
      const menu = this.store.menusAll.find((m) => m.id === data[0]);
      this.s.menu = data[0];
      this.s.category = menu?.categories[0]?.id || "";
    }

    /*
      if (menusAll.length === 1) {
        // if single menu, only one arg, category changes
        this.s.category = data[0];
      }
      else if (data[1]) {
        // multi menu, change category
        this.s.category = data[1];
      }
      else {
        // multi menu, change menu
        const menu = menusAll.find((m) => m._id === data[0]);
        this.s.menu = data[0];
        this.s.category = menu?.categories[0]?._id || "";
      }
    */
  };

  scrollToCategory = () => {
    setTimeout(() => {
      const category = this.store.filters.s.category;
      const el = document.getElementById(`category.${category}`);
      if (el) {
        this.scrollToCategoryActive = true;
        const { top_nav_height } = this.store.view.s;
        // The absolute number of pixels from the top of the page (always > 0)
        const pixelsFromPageTop = windowScrollTop();
        // The element distance from the top of the current view port (hidden by fixed top bar)
        const elPixelsFromViewportTop = el.getBoundingClientRect().top;
        // The element distance from top of viewport accounting for top bar height
        const elPixelsFromTopNavBar = elPixelsFromViewportTop - top_nav_height;
        // The absolute position on the page to scroll to
        const scrollToY = pixelsFromPageTop + elPixelsFromTopNavBar;
        const scrollToYClean = Math.max(scrollToY, 0);
        window.scroll({ left: 0, top: scrollToYClean });
        /*
        console.log(
          `top_nav_height :: ${top_nav_height}\n`
          + `pixelsFromPageTop :: ${pixelsFromPageTop}\n`
          + `elPixelsFromViewportTop :: ${elPixelsFromViewportTop}\n`
          + `elPixelsFromTopNavBar :: ${elPixelsFromTopNavBar}\n`
          + `scrollToY :: ${scrollToY}\n`
        );
        */
      }
    }, 0);
  };

  @action update = (data: Partial<FiltersState>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof FiltersState];
        if (value !== undefined) {
          this.s[key as keyof FiltersState] = value;
        }
      }
    }
  };
}
