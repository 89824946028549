import { styled, css, shadeAlternate } from "@lib/components";
import { lighten } from "polished";

interface WrapperProps {
  background: string;
  color: string;
  isWebView: boolean;
}

export const Wrapper = styled("div")<WrapperProps>`
  position: relative;
  background: ${({ background }) => background};
  border-bottom: 1px ${({ isWebView }) => (isWebView ? "dashed" : "solid")}
    ${({ background }) => lighten(0.2, background)};
  color: ${({ color }) => color};
`;

export const Content = styled("div")`
  text-align: center;
`;

export const Divider = styled("div")`
  width: 100%;
  max-width: 200px;
  margin: 25px auto;
  height: 1px;
  background: ${({ theme }) => shadeAlternate(0.8, theme.colors.text)};
`;

export const SocialIcons = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  > * {
    margin: 0 10px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: inline-block;
    color: white;
    font-size: 26px;
  }
`;
