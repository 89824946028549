import * as React from "react";
import { observer, inject } from "mobx-react";
import { Modal, ModalContent, ModalTitle } from "@lib/components";
import { MobxComponent } from "../../../mobx/component";

interface Props {}

@inject("store")
@observer
class TermsModalClass extends MobxComponent<Props> {
  render() {
    const { modal, r } = this.injected.store;
    return (
      <Modal
        id="modal-terms-and-conditions"
        width="md"
        closeButton={true}
        active={modal.isVisible("terms")}
        close={() => modal.back()}>
        <ModalTitle className="rounded-t" paddingtb={15} paddinglr={30}>
          <h2>Terms & Conditions</h2>
        </ModalTitle>
        <ModalContent className="children:mb-4 last-mb-0 text-md" paddingtb={30} paddinglr={30}>
          <h3>Disclaimer</h3>
          <p>
            The terms 'we' or 'our' makes reference to Book N Order Ltd and/or {r.name}. Our terms
            and conditions are subject to change without notice.
          </p>
          <p>
            By using our site or placing on order, you are agreeing to the terms below. To the
            extent permitted by law, we disclaim liability from any issues caused from usage of our
            site and system.
          </p>

          <div className="border-t" />

          <h3>Vaccination Requirements</h3>
          <p>
            Under public health orders by the Government, entry into our premises requires that all
            members of your group over the age of 12 are double vaccinated. All guests will have
            their vaccine passports scanned upon arrival
          </p>

          <div className="border-t" />

          <h3>Order Changes</h3>
          <p>
            Orders that are already made can no longer be modified or changed. If an order is not
            made or it has been place at a later date, we will allow changes. Please contact us
            immediately to make any changes.
          </p>

          <div className="border-t" />

          <h3>Order Cancellation</h3>
          <p>
            We reserve the right to cancel an order without providing reason but may be due to
            unavailability or an error in the pricing with our products.
          </p>
          <p>
            We will always inform you of any changes to your order and provide a refund if an order
            is cancelled by us. We will not be held liable for any damages by exercising this right.
          </p>
          <p>
            If you wish to cancel your order, please notify us immediately. Order cancellation is at
            our sole discretion. If your food has not been made yet, we will generally allow you to
            cancel and provide a refund. Once your order is made, you will no longer be able to
            cancel your order.
          </p>

          <div className="border-t" />

          <h3>Deliveries</h3>
          <h4>Address</h4>
          <p>
            You are responsible for ensuring that the delivery address for their order is correct
            and unambiguous. We cannot guarantee a successful delivery if this condition is not met.
            If further explanation is needed, it should be provided in the 'notes' section
          </p>
          <h4>Delivery Time</h4>
          <p>
            Our drivers will strive to deliver your order in the appointed time. However, unexpected
            delays may occur. Please be aware that restaurants can occasionally be delayed in their
            preparation, which we are not liable for. Additionally, drivers may get delayed due to
            bad weather or traffic conditions. We will do our best to notify you of any delays.
          </p>
          <h4>Service Availability</h4>
          <p>
            We reserve the right to disable our delivery service due to factors out of our control,
            like weather conditions, driver availability and other
          </p>
          <h4>Receiving your order</h4>
          <p>
            Upon arrival, the driver will attempt to make contact with you by ringing the doorbell
            or calling the provided phone number. If the call is not answered or the provided number
            is not available, and if contact cannot be made within 5 minutes, the driver will place
            the order in a safe place. For verification purposes, you may be asked in person to
            confirm details about your order.
          </p>
          <h4>Delivery Food Quality</h4>
          <p>
            We are not liable for the quality, quantity or packaging of the food you ordered. If
            something is missing or the restaurant got your order wrong, kindly contact them to
            rectify it
          </p>

          <div className="border-t" />

          <h3>Refunds</h3>
          <p>
            Book n Order is committed to customer satisfaction. If something about your order was
            wrong, you may be issued a complete or partial refund depending on the circumstances. If
            you believe that your order was not as described on the ordering page, contact us to
            help resolve the issue. All refund requests will be confirmed with the respective
            restaurants and delivery drivers before proceeding. Providing false information to our
            staff in order to sway the outcome of a refund request may result in being banned from
            our platform.
          </p>
          <p>
            Refunds will not be given for making the wrong order or choice. If you believe your
            order was not as advertised, please contact us and we will help resolve the issue. If a
            refund is issued, we will do it via the method you made payment.
          </p>
          <p>
            If payment is made via credit-card or EFTPOS, refunds can take up to 2 weeks to process
            but will generally take 3-5 business days.
          </p>

          <div className="border-t" />

          {/*
          <h3>Email Marketing</h3>
          <p>You may choose to opt-in and receive promotions, deals and updates via e-mail or SMS. By doing so, you give Book N Order and our merchants permissions to contact you with such content. You may opt-out at any time by using the unsubscribe link at the bottom of our emails.</p>
          <div className="border-t"/>
          */}

          <h3>Copyright</h3>
          <p>
            All images and content on our site is either copyright to {r.name} or our 3rd party
            lincesors and must not be distributed, modified, reproduced or used for any purpose
            without our prior consent which can be refused at our discretion. This site may be
            printed but cannot be modified or used in any way for any commercial purpose.
          </p>

          <div className="border-t" />

          <h3>Privacy</h3>
          <p>
            Your privacy is critical to us. We will never ask for information unless we need it, we
            will never share your information with anyone except to comply with the law or further
            develop our product or business and protect our rights.
          </p>
          <p>
            Usage of this site does require that you accept usage of third party tracking libraries
            such as Google Analytics to enable site improvements. This data will never be correlated
            to any personal information of any kind.
          </p>

          <div className="border-t" />

          <h3>Misc.</h3>
          <p>Please advise of any dietary requirements or allergies.</p>
          <p>All prices are subject to change without notice.</p>

          <div className="border-t" />

          <h3>Website</h3>
          <p>
            By using this website, you are agreeing to our terms and conditions. We will do our best
            to ensure that all information on our website is accurate. We will not be liable for any
            damages that arise from incorrect or missing information and performance issues. Our
            site makes use of third party content, we do not give any representation or endorsement
            of these brands or products.
          </p>
        </ModalContent>
      </Modal>
    );
  }
}

export const TermsModal = TermsModalClass;
