import { action, computed, observable, toJS } from "mobx";
import { RootStore } from "../store";
import autobind from "autobind-decorator";
import {
  logger,
  RestaurantUtils,
  cloneDeepSafe,
  percentOfCents,
  calculateGST,
  orderTotals,
  menuDish,
  orderDish,
  menuOptionSets,
  orderOptions,
} from "@lib/common";
import {
  OrderDishFullFragment,
  OrderDishOptionFragment,
  OrderFeeFragment,
  RestaurantPromoOldFragment,
} from "@lib/types/graphql";
import { v4 as uuid } from "uuid";

function freeQtyFromBuyXWithYFree(buyX: number, freeY: number, receivedQty: number) {
  let freeQty = 0;
  let purchasedBlockQty = 0;
  let claimedBlockQty = 0;
  for (let i = 0; i < receivedQty; i++) {
    if (purchasedBlockQty < buyX) {
      purchasedBlockQty++;
    } else if (claimedBlockQty < freeY) {
      claimedBlockQty++;
      freeQty++;
    } else if (claimedBlockQty === freeY) {
      purchasedBlockQty = 0;
      claimedBlockQty = 0;
      i--;
    }
  }
  return freeQty;
}

@autobind
export class CartStore {
  store: RootStore;
  added_free_dish: string = "";
  @observable noStock = {
    dishes: [] as string[],
    options: [] as string[],
    loading: false,
  };

  constructor(store: RootStore) {
    this.store = store;
    // CHECK TO REMOVE PROMO THAT WAS ADDED SERVER SIDE
    if (typeof window !== "undefined") {
      this.check_promo();
    }
  }

  get oc() {
    return this.store.order_config.s;
  }

  get od() {
    return this.store.order_config.d;
  }

  get dishes() {
    return this.oc.dishes;
  }

  check_promo = () => {
    if (this.promo && this.promo.once_per_customer) {
      const oncePerCustomerInvalid = !!localStorage.getItem(this.promo.id);
      if (oncePerCustomerInvalid) {
        this.promoRemove();
      }
    }
  };

  @action checkStock = async () => {
    try {
      this.noStock.loading = true;
      const data = await this.store.api.order_validate_stock({ items: this.dishes });
      this.noStock.dishes = data.no_stock_dishes;
      this.noStock.options = data.no_stock_options;
      if (this.noStock.dishes.length > 0 || this.noStock.options.length > 0) {
        this.store.syncMenus();
      }
    } catch (e) {
      logger.captureException(e);
    }
    this.noStock.loading = false;
    return this.noStock;
  };

  @computed get itemsInCart() {
    return this.dishes.reduce((a, v) => a + v.quantity, 0);
  }

  @computed get ageRestrictedItemsInCart() {
    if (!this.store.menusContainAgeRestrictedProducts) {
      return false;
    }

    let ageRestrictedItemsInCart = false;
    for (const d of this.dishes) {
      const find = RestaurantUtils.menu.findDish(this.store.menusAll, d.id);
      if (find?.menu.restrict_age || find?.category.restrict_age) {
        ageRestrictedItemsInCart = true;
        break;
      }
      if (d.type === "combo") {
        for (const comboChoice of d.choices) {
          if (comboChoice.dish_id) {
            const findComboDish = RestaurantUtils.menu.findDish(
              this.store.menusAll,
              comboChoice.dish_id
            );
            if (findComboDish?.menu.restrict_age || findComboDish?.category.restrict_age) {
              ageRestrictedItemsInCart = true;
              break;
            }
          }
        }
      }
    }

    return ageRestrictedItemsInCart;
  }

  @computed get totalCart() {
    return orderTotals.cart({ dishes: this.dishes });
  }

  @computed get totalCartWithDiscount() {
    const discounted = this.totalCart - this.discount;
    return discounted > 0 ? discounted : 0; // IN CASE IT'S LESS THAN 0
  }

  @computed get totalCartWithFees() {
    return orderTotals.total({
      cart: this.totalCartWithDiscount,
      fees: this.fees,
      discounts: [],
    });
  }

  @computed get total() {
    const total = this.totalCartWithFees;
    return total + this.oc.payment_tip;
  }

  @computed get promo(): RestaurantPromoOldFragment | null {
    const { promo_old_id } = this.store.order_config.s;
    if (!promo_old_id) return null;
    return this.store.r.promos_old.find((p) => p.id === promo_old_id) || null;
  }

  @computed get discount() {
    const promo = this.promo;

    if (!promo) return 0;

    const total = this.totalCart;
    const cartItems = cloneDeepSafe(this.dishes);
    const {
      fixed_discount,
      percent_discount,
      min_order,
      max_order,
      limit_to_dishes,
      free_dishes,
      free_same_only,
    } = promo;

    const free_qty = promo.free_qty || 1;
    const free_required_purchase_qty = promo.free_required_purchase_qty || 1;

    // 1. If min order not met no discount
    if (min_order && total < min_order) {
      return 0;
    }
    if (max_order && total > max_order) {
      return 0;
    }

    let discount = 0;

    if (!this.added_free_dish && free_dishes && free_dishes.length > 0) {
      const cartItemsNoDuplicates: Array<{
        id: string;
        qty: number;
        dishes: OrderDishFullFragment[];
      }> = [];
      for (const item of cartItems) {
        const index = cartItemsNoDuplicates.findIndex((dish) => dish.id === item.dish_id);
        if (index !== -1) {
          cartItemsNoDuplicates[index].qty += item.quantity;
          cartItemsNoDuplicates[index].dishes.push(item);
        } else {
          cartItemsNoDuplicates.push({
            id: item.dish_id!,
            qty: item.quantity,
            dishes: [item],
          });
        }
      }

      if (free_same_only) {
        for (const item of cartItemsNoDuplicates) {
          if (free_dishes.indexOf(item.id) !== -1) {
            if (item.qty >= free_required_purchase_qty) {
              const actualFreeQty = freeQtyFromBuyXWithYFree(
                free_required_purchase_qty,
                free_qty,
                item.qty
              );

              const basePrices = [];

              let maxDiscountPrice = 0;

              for (const d of item.dishes) {
                for (let i = 0; i < d.quantity; i++) {
                  basePrices.push(d.price);
                }
                if (d.price > maxDiscountPrice) {
                  maxDiscountPrice = d.price;
                }
              }

              basePrices.sort((a, b) => a - b);

              for (let i = 0; i < actualFreeQty; i++) {
                if (basePrices[i]) {
                  const actualDiscountPrice =
                    basePrices[i] > maxDiscountPrice ? maxDiscountPrice : basePrices[i];
                  discount = discount + actualDiscountPrice;
                }
              }
            }
          }
        }
      } else {
        let totalFreeItemsQty = 0;
        const cartItemsInFreeList: Array<{
          id: string;
          qty: number;
          dishes: OrderDishFullFragment[];
        }> = [];
        for (const item of cartItemsNoDuplicates) {
          if (free_dishes.indexOf(item.id) !== -1) {
            totalFreeItemsQty += item.qty;
            cartItemsInFreeList.push(item);
          }
        }

        if (totalFreeItemsQty >= free_required_purchase_qty) {
          const cartItemsInFreeListByPriceSingleQty: Array<{ id: string; basePrice: number }> = [];

          for (const item of cartItemsInFreeList) {
            for (const d of item.dishes) {
              let baseDishPrice = d.price;
              if (d.type === "standard" && d.dish_id) {
                const found = RestaurantUtils.menu.findDish(this.store.menusAll, d.dish_id);
                if (found) {
                  for (const { option } of d.options) {
                    const os = this.store.rOptionSets.find(
                      (option_set) => option_set.id === option.option_set_id
                    );
                    if (os && os.inc_price_free_qty_promo) {
                      baseDishPrice += option.price * (option.quantity - option.quantity_free);
                    }
                  }
                }
              }

              for (let i = 0; i < d.quantity; i++) {
                cartItemsInFreeListByPriceSingleQty.push({
                  id: d.dish_id!,
                  basePrice: baseDishPrice,
                });
              }
            }

            /*
            const menuFindResult = RestaurantUtils.menu.findDish(this.store.restaurant, item._id);
            if (menuFindResult) {
              for (let i = 0; i < item.qty; i++) {
                cartItemsInFreeListByPriceSingleQty.push({
                  _id: item._id,
                  basePrice: menuFindResult.dish.price,
                });
              }
            }
            */
          }

          cartItemsInFreeListByPriceSingleQty.sort((a, b) => {
            return a.basePrice - b.basePrice;
            /*
              if (a.basePrice > b.basePrice) return 1;
              else if (a.basePrice < b.basePrice) return -1;
              else return 0;
            */
          });

          const freeQtyAvailable = freeQtyFromBuyXWithYFree(
            free_required_purchase_qty,
            free_qty,
            totalFreeItemsQty
          );

          // console.log(free_required_purchase_qty, free_qty, totalFreeItemsQty);
          // console.log(cartItemsInFreeListByPriceSingleQty, freeQtyAvailable);

          for (let i = 0; i < freeQtyAvailable; i++) {
            if (cartItemsInFreeListByPriceSingleQty[i]) {
              discount = discount + cartItemsInFreeListByPriceSingleQty[i].basePrice;
            }
          }
        }
      }
    }

    if (free_dishes.length === 1 || (limit_to_dishes && limit_to_dishes.length > 0)) {
      // Not needed anymore as cart total takes into consideration the dish discounts
      // for (const dish of cartItems) {
      //   discount = discount + (dish.discount * dish.quantity);
      // }
    } else {
      const actualPercentDiscount = percentOfCents(total, percent_discount);
      const actualDiscount = actualPercentDiscount + fixed_discount;
      discount = discount + actualDiscount;
    }

    return discount;
  }

  @computed get fees(): OrderFeeFragment[] {
    const { store } = this;
    const r = store.r;
    if (!this.od.confirmed) return [];
    return orderTotals.fees({
      order: this.store.order_config.s,
      orderPaymentType: this.store.order_config.s.payment_type,
      orderPaymentTypeSub: this.store.order_config.s.payment_type_sub,
      orderPromo: this.promo,
      orderPromoValid: this.promoValid,
      totalCartWithDiscount: this.totalCartWithDiscount,
      serviceDelivery: r.service_delivery!,
      serviceDeliveryZones: r.service_delivery_zones,
      restaurantFees: r.fees,
      isLoggedIn: this.store.customer.isLoggedIn,
    });
  }

  @computed get tax(): number {
    const total = this.totalCartWithFees;
    const { priceGstValue } = calculateGST(total);
    return priceGstValue;
  }

  @computed get minOrderDelivery(): number | null {
    const r = this.store.r;
    const oc = this.store.order_config.s;
    if (oc.config_service === "delivery") {
      return r.service_delivery!.min_order;
    }
    return null;
  }

  @computed get minOrderDeliveryValid() {
    const min = this.minOrderDelivery;
    if (!min) return true;
    return this.totalCart >= min;
  }

  @computed get minOrderPromo(): number | null {
    const promo = this.promo;
    if (promo && promo.min_order) {
      return promo.min_order;
    }
    return null;
  }
  @computed get minOrderPromoValid() {
    const min = this.minOrderPromo;
    if (min === null) return true;
    return this.totalCart >= min;
  }

  @computed get maxOrderPromo(): number | null {
    const promo = this.promo;
    if (promo && promo.max_order) {
      return promo.max_order;
    }
    return null;
  }
  @computed get maxOrderPromoValid() {
    const max = this.maxOrderPromo;
    if (max === null) return true;
    return this.totalCart <= max;
  }

  @action clear = () => {
    this.store.order_config.update({
      dishes: [],
      payment_tip: 0,
      promo_old_id: null,
      promo_old_code: null,
    });
  };
  @action push = (dish: OrderDishFullFragment, index?: number) => {
    dish = cloneDeepSafe(dish);
    if (index !== undefined && index !== -1) {
      // IS EDIT
      this.dishes[index] = dish;
    } else {
      this.dishes.push(dish);
    }
    this.promoUpdateDishDiscount();
  };
  @action edit = (index: number) => {
    const dish = this.dishes[index];
    this.store.modal.close();
    this.store.dish.setFromCart(toJS(dish), index);
    this.promoUpdateDishDiscount();
  };
  @action remove = (index: number) => {
    this.dishes.splice(index, 1);
    this.promoUpdateDishDiscount();
  };

  @computed get promoValid() {
    if (!this.promo) {
      return false;
    }

    // Min order and limit to dishes are not checked before promo is applied, so need to recheck it here
    return this.promoLimitToDishesValid && this.minOrderPromoValid && this.maxOrderPromoValid;
  }

  @computed get promoLimitToDishesValid() {
    const promo = this.promo;

    if (!promo) {
      return false;
    }

    if (!promo.limit_to_dishes || promo.limit_to_dishes.length === 0) {
      return true;
    }

    let valid = false;
    const items = this.dishes;
    for (const dish of items) {
      if (promo.limit_to_dishes.indexOf(dish.dish_id) !== -1) {
        valid = true;
        break;
      }
    }

    return valid;
  }

  @action promoAdd = (promoCode: string, hideError: boolean = false) => {
    const code = promoCode;
    if (!code || this.promo) return;

    try {
      const now = Date.now();
      const oc = this.store.order_config.s;
      const c = this.store.customer.s.item;
      const r = this.store.r;
      const index = r.promos_old.findIndex((p) => p.code.toLowerCase() === code.toLowerCase());

      if (index === -1) {
        if (!hideError) {
          this.store.order_config.updateD({
            promo_form_error: "not_found",
          });
        }
        return;
      }

      const promo = r.promos_old[index];

      if (promo.disabled) {
        if (!hideError) {
          this.store.order_config.updateD({
            promo_form_error: "disabled",
          });
        }
        return;
      }

      if (promo.logged_in_only && !c) {
        if (!hideError) {
          this.store.order_config.updateD({
            promo_form_error: "logged_in_only",
          });
        }
        return;
      }

      if (promo.once_per_customer && typeof window !== "undefined") {
        const oncePerCustomerInvalid = !!localStorage.getItem(promo.id);

        if (oncePerCustomerInvalid) {
          if (!hideError) {
            this.store.order_config.updateD({
              promo_form_error: "already_used",
            });
          }
          return;
        }
      }

      if (!!promo.max_uses && promo.times_used > promo.max_uses) {
        if (!hideError) {
          this.store.order_config.updateD({
            promo_form_error: "invalid",
          });
        }
        return;
      }

      if (promo.services && promo.services.length > 0) {
        if (promo.services.indexOf(oc.config_service) === -1) {
          if (!hideError) {
            this.store.order_config.updateD({
              promo_form_error: "invalid_service",
            });
          }
          return;
        }
      }

      if (promo.times && promo.times.length > 0) {
        if (promo.times.indexOf(oc.config_due) === -1) {
          if (!hideError) {
            if (oc.config_due === "now") {
              this.store.order_config.updateD({
                promo_form_error: "later_only",
              });
            } else {
              this.store.order_config.updateD({
                promo_form_error: "now_only",
              });
            }
          }
          return;
        }
      }

      if (promo.valid_times.length > 0) {
        let oneTimeValid = false;
        const startTime = oc.config_due === "now" ? now : oc.config_timestamp;

        console.log("CHECK VALID TIMES", toJS(promo.valid_times));
        console.log("START TIME", startTime, oc.config_timestamp);

        for (const time of promo.valid_times) {
          if (startTime >= time.start && startTime <= time.end) {
            oneTimeValid = true;
            break;
          }
        }

        if (!oneTimeValid) {
          console.log("TIMING INVALID");
          this.store.order_config.updateD({
            promo_form_error: "not_available",
          });
          return;
        }
      }

      this.store.order_config.updateD({
        promo_form_code: "",
        promo_form_error: "",
      });

      this.store.order_config.update({
        promo_old_id: promo.id,
        promo_old_code: promo.code,
      });

      this.promoUpdateDishDiscount();
    } catch (e) {
      logger.captureException(e);
      if (!hideError) {
        this.store.order_config.updateD({
          promo_form_error: "generic",
        });
      }
    }
  };
  @action promoRemove = () => {
    this.store.order_config.update({
      promo_old_id: null,
      promo_old_code: null,
    });
    this.promoUpdateDishDiscount();
    this.added_free_dish = "";
  };

  @action promoUpdateDishDiscount = () => {
    const promo = this.promo;
    if (!promo) {
      for (const [index] of this.dishes.entries()) {
        this.updateCartItem(index, { discount: 0 });
      }
      return;
    }

    console.log("promoUpdateDishDiscount");

    // Get free dish price
    let freeDishPrice = 0;
    let freeDishIndex = -1;
    if (this.added_free_dish) {
      freeDishIndex = this.dishes.findIndex((o) => o.id === this.added_free_dish);
      if (freeDishIndex !== -1) {
        freeDishPrice = orderDish.total(this.dishes[freeDishIndex]).priceSingle;
      }
    }

    // If min order not met, remove free dish
    if (
      (promo.min_order && this.totalCart - freeDishPrice < promo.min_order) ||
      !this.promoLimitToDishesValid
    ) {
      if (freeDishIndex !== -1) {
        this.remove(freeDishIndex);
        this.added_free_dish = "";
      }
      return;
    }

    // Add free dish to cart
    if (!this.added_free_dish && promo.free_dishes.length === 1) {
      const freeDish = promo.free_dishes[0] as string;
      const found = RestaurantUtils.menu.findDish(this.store.menusAll, freeDish);
      if (found && this.promoLimitToDishesValid) {
        this.added_free_dish = uuid();
        // const orderDishOptions: Array<{ option: OrderDishOptionFragment }> = [];
        const optionSets = menuOptionSets.forDish({
          md: found.dish,
          r: {
            menus: this.store.menusAll,
            option_sets: this.store.rOptionSets,
          },
          platform: "online",
          service: this.store.order_config.s.config_service,
          odChoiceDishId: null,
          // surchargePct: found.dish.surcharge_pct || 0,
        });
        const od: OrderDishFullFragment = {
          id: this.added_free_dish,
          dish_id: found.dish.id,
          type: found.dish.type,
          name: found.dish.display_name || found.dish.name,
          print_name: found.dish.print_name,
          subtitle: found.dish.subtitle,
          notes: "",
          price: menuDish.price({
            dish: found.dish,
            service: this.store.order_config.s.config_service || null,
          }),
          discount: menuDish.price({
            dish: found.dish,
            service: this.store.order_config.s.config_service || null,
          }),
          quantity: 1,
          position: this.store.cart.dishes.length,
          ingredients: [],
          options: [],
          choices: [],
        };
        for (const os of optionSets) {
          if (os.is_required) {
            const defaultOption = os.options.find((d) => d.quantity === 1) || os.options[0];
            if (defaultOption) {
              const price = orderOptions.price({
                od: od,
                option: defaultOption,
                service: this.store.order_config.s.config_service,
                odChoiceId: null,
                surchargePct: null,
              });
              od.options.push({
                option: {
                  id: uuid(),
                  name: defaultOption.name,
                  set_name: os.display_name || os.name,
                  option_id: defaultOption.id,
                  option_set_id: os.id,
                  position: 0,
                  price: price,
                  quantity: 1,
                  quantity_free: 0,
                },
              });
            }
          }
        }

        this.push(od);
      }
    }

    const items = this.dishes;

    let stupidCalimeroPizzaDealAppliedOnce = false;

    // Handle dish level discounts when no free dish
    if (!this.added_free_dish && promo.limit_to_dishes && promo.limit_to_dishes.length > 0) {
      for (const [index, dish] of items.entries()) {
        if (promo.limit_to_dishes.indexOf(dish.dish_id) !== -1) {
          // Check if required options are met
          let meetsRequiredOptions = promo.required_dish_options.length === 0;
          if (!meetsRequiredOptions) {
            for (const o of dish.options) {
              if (promo.required_dish_options.indexOf(o.option.option_id) !== -1) {
                meetsRequiredOptions = true;
                break;
              }
            }
          }

          // Don't process if option requirements are not met
          if (!meetsRequiredOptions) {
            this.updateCartItem(index, { discount: 0 });
            continue;
          }

          if (promo.required_dish_options.length > 0 && stupidCalimeroPizzaDealAppliedOnce) {
            this.updateCartItem(index, { discount: 0 });
            continue;
          }

          const { basePriceSingle } = orderDish.total(dish);

          let discount = 0;

          if (promo.percent_discount) {
            discount = percentOfCents(basePriceSingle, promo.percent_discount);
          }

          if (promo.fixed_discount) {
            discount = discount + promo.fixed_discount;
          }

          this.updateCartItem(index, {
            discount: discount,
          });

          stupidCalimeroPizzaDealAppliedOnce = true;
        }
      }
    }
  };

  @action updateCartItem = (index: number, data: Partial<OrderDishFullFragment>) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key as keyof OrderDishFullFragment];
        if (value !== undefined) {
          // @ts-ignore
          this.dishes[index][key as keyof OrderDishFullFragment] = value;
        } else {
          delete this.dishes[index][key as keyof OrderDishFullFragment];
        }
      }
    }
  };
}
