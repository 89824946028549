import qs from "querystring";
import * as UrlPattern from "url-pattern";
import {
  History,
  createBrowserHistory,
  createMemoryHistory,
  UnregisterCallback,
  LocationListener,
} from "history";
import { observable, action } from "mobx";

export type Route = {
  path: string;
  pattern: UrlPattern;
  title: string;
  meta?: string;
  auth?: boolean;
  area?: string;
  restriction_keys?: string[];
  changefreq: string;
  priority: number;
  sitemap?: boolean;
};
export type RouterState = {
  path: string;
  search: string | null;
  hash: string | null;
  query: { [key: string]: string };
};
export type RouterOnChange = (s: RouterState) => void;
export type Routes<RouteKeys> = Map<RouteKeys, Route>;
export type RouteMatch<RouteKeys> = {
  key: RouteKeys;
  route: Route;
  match: { [key: string]: string | undefined };
};

export class RouterStore {
  @observable s: RouterState;
  @observable is404: boolean = false;

  history: History;
  unlisten: UnregisterCallback;
  onChange?: RouterOnChange;

  constructor(initialState?: RouterState, onChange?: RouterOnChange) {
    this.onChange = onChange;

    this.history = typeof window !== "undefined" ? createBrowserHistory() : createMemoryHistory();

    this.unlisten = this.history.listen(this.listen);

    this.s = observable({
      path: this.history.location.pathname,
      search: this.history.location.search,
      hash: this.history.location.hash,
      query: qs.parse(this.cleanQueryString(this.history.location.search)) as RouterState["query"],
      ...(initialState || {}),
    });
  }

  getOrigin = () => {
    let origin = window.location.origin;
    if (!origin) {
      origin =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "");
    }
    return origin;
  };

  cleanQueryString = (s: string) => {
    if (s[0] === "?") return s.split("?")[1];
    return s;
  };

  @action set404 = (is404: boolean) => (this.is404 = is404);
  @action update = (data: Partial<RouterState>) => {
    this.s = { ...this.s, ...data };
  };
  @action push = (path: string) => {
    this.history.push(path);
  };
  @action listen: LocationListener = (location) => {
    this.s = {
      path: location.pathname,
      search: location.search,
      hash: location.hash,
      query: qs.parse(this.cleanQueryString(location.search)) as RouterState["query"],
    };
    if (this.onChange) {
      this.onChange(this.s);
    }
  };
}

export function matchRouteConstructor<RouteKeys>(routes: Routes<RouteKeys>) {
  return (path: string): null | RouteMatch<RouteKeys> => {
    for (const [key, route] of routes) {
      const isMatch = route.pattern.match(path);
      if (isMatch !== null) {
        const match = isMatch as { [key: string]: string | undefined };
        return { key, route, match };
      }
    }
    return null;
  };
}
