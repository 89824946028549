import * as React from "react";
import cn from "classnames";
import { observer, inject } from "mobx-react";
import {
  FaShoppingBag,
  FaMotorcycle,
  FaUtensils,
  FaCalendarAlt,
  FaInfoCircle,
} from "react-icons/fa";
import { Choice, ChoiceIcon } from "../components";
import { MobxComponent } from "../../../../mobx/component";

interface Props {}

@inject("store")
@observer
class OrderConfigServicesClass extends MobxComponent<Props> {
  setDelivery = async () => {
    const { store } = this.injected;
    const oc = store.order_config;
    oc.setService("delivery");
    // TODO: Need to set first customer address or allow them to choose from existing ones
    /*
    try {

      // TODO: Need to set first customer address or allow them to choose from existing ones
      const customer = store.customer.s.item;

      if (customer) {
        const d = customer.delivery;
        if (d && d.destination) {
          const blocked = OrderUtils.checkBlockedAddresses(d.destination, r.settings.services.delivery.block_addresses);
          if (!blocked && d.lat && d.lng) {
            oc.updateD({ delivery_loading: true });
            const { valid, distance, driving_time, zoneMatch } = await oc.validate_delivery_details({
              lat: d.lat,
              lng: d.lng,
            });
            if (valid) {
              oc.setDestination({
                destination: d.destination,
                destination_misc: d.destination_misc || "",
                destination_mti: d.destination_mti || null,
                lat: d.lat,
                lng: d.lng,
                distance: distance,
                driving_time: driving_time,
                zone: zoneMatch ? zoneMatch.name : "",
              });
            }
          }
        }
      }

    }
    catch (e) {
      logger.captureException(e);
    }
    finally {
      oc.updateD({ delivery_loading: false });
    }
    */
  };

  render() {
    const { store } = this.injected;
    const { r } = this.injected.store;
    const oc = this.injected.store.order_config.s;
    let notes = "";
    if (oc.config_service !== "") {
      notes = store.service.notes || "";
    }

    const choiceClass = `w-1/2 sm:w-1/4 p-2`;

    return (
      <div className="mb-6">
        <h2 className="font-regular text-center mb-6">Choose A Service</h2>
        <div
          className={cn("flex flex-wrap justify-center mx-auto -m-2 w-64 sm:w-auto")}
          style={{ maxWidth: "512px" }}>
          {r.service_pickup!.enabled && (
            <div className={choiceClass}>
              <Choice
                hidden={!r.service_pickup!.enabled}
                active={oc.config_service === "pickup"}
                onClick={() => store.order_config.setService("pickup")}>
                <ChoiceIcon>
                  <FaShoppingBag />
                </ChoiceIcon>
                <p>Pickup</p>
              </Choice>
            </div>
          )}
          {r.service_delivery!.enabled && (
            <div className={choiceClass}>
              <Choice
                hidden={!r.service_delivery!.enabled}
                active={oc.config_service === "delivery"}
                onClick={this.setDelivery}>
                <ChoiceIcon>
                  <FaMotorcycle />
                </ChoiceIcon>
                <p>Delivery</p>
              </Choice>
            </div>
          )}
          {r.service_dine_in!.enabled && (
            <div className={choiceClass}>
              <Choice
                hidden={!r.service_dine_in!.enabled}
                active={oc.config_service === "dine_in"}
                onClick={() => store.order_config.setService("dine_in")}>
                <ChoiceIcon>
                  <FaUtensils />
                </ChoiceIcon>
                <p>Dine-In</p>
              </Choice>
            </div>
          )}
          {r.service_table_booking!.enabled && !store.view.s.is_kiosk && (
            <div className={choiceClass}>
              <Choice
                hidden={!r.service_table_booking!.enabled}
                active={oc.config_service === "table_booking"}
                onClick={() => store.order_config.setService("table_booking")}>
                <ChoiceIcon>
                  <FaCalendarAlt />
                </ChoiceIcon>
                <p>Table Booking</p>
              </Choice>
            </div>
          )}
        </div>
        {notes && (
          <div
            className="flex justify-center items-center mx-auto mt-8 p-3 rounded bg-gray-000"
            style={{ maxWidth: "420px" }}>
            <div className="text-md mr-3">
              <FaInfoCircle />
            </div>
            <p className="text-sm">{notes}</p>
          </div>
        )}
      </div>
    );
  }
}

export const OrderConfigServices = OrderConfigServicesClass;
