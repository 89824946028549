import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Button, Modal, ModalContent } from "@lib/components";

interface Props {}

@inject("store")
@observer
class OrderRestartModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { modal } = store;
    return (
      <Modal
        id="modal-order-restart"
        width="sm"
        closeButton={true}
        active={modal.isVisible("order-restart")}
        close={() => modal.hide("order-restart")}>
        <ModalContent>
          <p className="text-error text-md">Warning</p>
          <h4>Change Order Details</h4>
        </ModalContent>
        <ModalContent>
          <p className="">
            Changing your order details will remove the items in your cart as certain dishes may
            become unavailable
          </p>
        </ModalContent>
        <ModalContent className="text-right" paddingtb={15}>
          <Button className="mr-2" onClick={() => modal.hide("order-restart")}>
            <p>Cancel</p>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              modal.hide("order-restart");
              modal.show("order-config");
            }}>
            <p>Proceed</p>
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const OrderRestartModal = OrderRestartModalClass;
