import * as React from "react";
import { observer } from "mobx-react";
import { formatDateSimple, formatDateTimeSimple, formatOrderNumber } from "@lib/common";
import { ConnectionIndicatorCircle, ModalContent, OrderNumber } from "@lib/components";
import { RestaurantOrderFullFragment, RestaurantStoreFragment } from "@lib/types/graphql";
import { orderReceiptFormatDeliveryTime } from "./utils";

type Props = {
  r: RestaurantStoreFragment;
  o: RestaurantOrderFullFragment;
  connected: boolean;
};

export const OrderReceiptHeader = observer((props: Props) => {
  const { r, o, connected } = props;
  let deliveryTiming = null;
  if (o.config_service === "delivery" && o.delivery_at) {
    if (o.config_due === "later" && r.service_delivery?.time_delivery_between) {
      deliveryTiming = (
        <p className="text-md">
          Est. Delivery -{" "}
          <span className="font-semibold">
            {formatDateSimple(o.config_timestamp)} {r.service_delivery.time_delivery_between}
          </span>
        </p>
      );
    } else {
      deliveryTiming = (
        <p className="text-md">
          Est. Delivery -{" "}
          <span className="font-semibold">{orderReceiptFormatDeliveryTime(o.delivery_at)}</span>
        </p>
      );
    }
  }
  return (
    <ModalContent
      paddinglr={20}
      paddingtb={25}
      className="flex justify-between content-center items-center !border-0">
      <OrderNumber>#{formatOrderNumber(o)}</OrderNumber>
      <div className="text-right ml-2">
        {o.config_service !== "delivery" && o.ready_at && (
          <div>
            <p className="text-md">
              Est. Ready Time -{" "}
              <span className="font-semibold">{formatDateTimeSimple(o.ready_at)}</span>
            </p>
          </div>
        )}
        {deliveryTiming}
        <p className="text-sm">
          {connected ? "Real-time updates" : "Real-time updates disconnected"}
          <ConnectionIndicatorCircle
            className="ml-1"
            status={connected ? "connected" : "disconnected"}
          />
        </p>
      </div>
    </ModalContent>
  );
});
