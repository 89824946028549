import * as React from "react";
import cn from "classnames";
import { inject, observer } from "mobx-react";
import { MobxComponent } from "../../../../mobx/component";
import { Button, styled } from "@lib/components";
import { MainActionsRenderer } from "../../../logic/main-actions";
import { OrderCart } from "../../../logic/cart";
import { SideColumnWrapper } from "../components";
import { MdRestaurantMenu } from "react-icons/md";

interface Props {}
interface State {}

const Wrapper = styled(SideColumnWrapper)`
  direction: rtl;
  > * {
    direction: ltr;
  }
  &::-webkit-scrollbar {
    opacity: 1;
    display: initial;
    visibility: visible;
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    opacity: 1;
    display: initial;
    visibility: visible;
    background: var(--gray-200);
  }
  &::-webkit-scrollbar-thumb {
    opacity: 1;
    display: initial;
    visibility: visible;
    background: var(--gray-400);
  }
`;

const boxBackgroundStyle = { background: `rgba(0, 0, 0, 0.04)` };

@inject("store")
@observer
class MenusCartClass extends MobxComponent<Props, State> {
  render() {
    const { store } = this.injected;
    const {
      orderingDisabled,
      serviceBookingEnabled,
      serviceOrderingEnabled,
      serviceDeliveryEnabled,
      view,
    } = store;
    let startText = "Book Table / Order Online";
    if (serviceBookingEnabled && !serviceOrderingEnabled) {
      startText = "Book Table";
    } else if (!serviceBookingEnabled && serviceOrderingEnabled) {
      startText = "Order Online";
    }
    return (
      <Wrapper topBarHeight={view.s.top_nav_height} className="">
        <div className="">
          <div className="py-6 px-6 w-full" style={{ maxWidth: "340px" }}>
            <MainActionsRenderer
              renderClosed={() => (
                <div
                  className={cn(
                    "bg-gray-100 text-gray-900 py-8 text-center rounded",
                    orderingDisabled ? "px-4" : "px-6"
                  )}>
                  <h2 className="mb-1 text-xl">
                    {orderingDisabled ? "Not Accepting Orders" : "Store Closed"}
                  </h2>
                  <p className="text-md">
                    {orderingDisabled
                      ? "Sorry, we are currently not accepting online orders at this time"
                      : "Sorry, we are currently closed at the moment"}
                  </p>
                </div>
              )}
              renderOpen={() => (
                <div className="bg-gray-100 text-gray-900 px-6 py-8 text-center rounded">
                  <MdRestaurantMenu className="mb-2 text-5xl mx-auto" />
                  <h2 className="flex items-center justify-center mb-4">
                    <span className="mt-1">Ready To Eat?</span>
                  </h2>
                  <Button
                    color="primary"
                    className="!w-full shadow-md !text-md !px-0"
                    onClick={() => store.modal.toggle("order-config")}>
                    {startText}
                  </Button>
                  {serviceDeliveryEnabled && (
                    <p className="text-md mt-6">No contact delivery available</p>
                  )}
                </div>
              )}
              renderCart={() => <OrderCart showChangeDetails={true} />}
            />
          </div>
        </div>
      </Wrapper>
    );
  }
}

export const MenusCart = MenusCartClass;
