import * as React from "react";
import { observer } from "mobx-react";
import { logger, ApiError, formatOrderNumber, formatOrderStatus } from "@lib/common";
import { useStore } from "../../../mobx/component";
import { SessionStorage } from "../../../libs/session-storage";
import { OrderReceiptDetails } from "./details";
import { OrderReceiptHeader } from "./header";
import { OrderReceiptError, OrderReceiptLoading } from "./components";
import {
  Modal,
  ModalContent,
  ModalTabs,
  OrderTotals,
  OrderItems,
  OrderTimeline,
} from "@lib/components";
import classNames from "classnames";
import { OrderReceiptMap } from "./delivery/map";
import { OrderReceiptDriver } from "./delivery/driver";
import { useScreenMmd } from "../../../libs/media-queries";
import { MQTT_ENDPOINT, MQTT_PASSWORD, MQTT_USERNAME, mqttTopics } from "../../../config";
import Mqtt from "mqtt";

interface Props {}

const errors: { [key: string]: string } = {
  not_found: "Order not found, try refresh the page",
  generic: "Something went wrong, refresh the page or contact us",
};

const ReceiptModalClass = observer((props: Props) => {
  const store = useStore();
  const { r, router } = store;
  const { routeId } = store.order;
  const { loading, error, item: o } = store.order.s;
  const { logged_in_only } = r.setting!;

  const [connected, setConnected] = React.useState(false);
  const [tab, setTab] = React.useState(0);

  function handleNewMessage(topic: string, message: Buffer) {
    console.log("NEW MQTT MESSAGE", topic, message.toString());
    if (!store.order.s.item) return;
    let data: T.MQTT.PublicOrderUpdateMessage;
    try {
      data = JSON.parse(message.toString());
      const formattedOrderNumber = formatOrderNumber(store.order.s.item);
      if (data.event === "status") {
        store.order.updateItem(data);
        store.sendPushNotification(`Order ${formattedOrderNumber} Updated`, {
          body: `Your order status has been updated to ${formatOrderStatus(data.status)}`,
          timeout: 30000,
          requireInteraction: true,
        });
      } else if (data.event === "ready_time") {
        store.order.updateItem(data);
        store.sendPushNotification(`Order ${formattedOrderNumber} Updated`, {
          body: "Your order due time has been updated",
          timeout: 30000,
          requireInteraction: true,
        });
      } else if (data.event === "tookan_booked") {
        store.order.updateItem(data);
        store.sendPushNotification("Delivery tracking updated", {
          body: "Your delivery tracking link is now available",
          timeout: 6000,
          requireInteraction: false,
        });
      } else if (data.event === "driver_update") {
        store.order.updateItem({
          driver_deliveries: data.driver_deliveries,
        });
      } else if (data.event === "driver_location") {
        store.order.updateDriverCoords(data.latitude, data.longitude);
      }
    } catch (err) {
      logger.captureException(err);
      return;
    }
  }

  React.useEffect(() => {
    let interval: any;
    let mqtt: Mqtt.MqttClient;

    (async () => {
      try {
        if (!routeId) {
          return;
        }

        setTab(0);
        SessionStorage.remove(routeId);
        store.initPushNotification();

        if (!o || o.id !== routeId) {
          const findArgs: T.API.StoresOrderFindRequest = { id: routeId };
          store.order.update({ loading: true });
          if (store.router.s.query.payment_type === "poli") {
            console.log("WAIT POLI");
            await new Promise((resolve) => setTimeout(resolve, 5000));
          }
          if (store.router.s.query.payment_type === "windcave") {
            findArgs.windcave_session_id = router.s.query.sessionId;
            // await new Promise((resolve) => setTimeout(resolve, 2000));
          }
          const result = await store.api.order_find(findArgs);
          store.order.update({
            loading: false,
            error: "",
            item: result.order,
          });
        }

        mqtt = Mqtt.connect(MQTT_ENDPOINT, {
          clean: false,
          keepalive: 15,
          username: MQTT_USERNAME,
          password: MQTT_PASSWORD,
          clientId: `${routeId}-${Date.now()}`,
          reconnectPeriod: 5000, // attempt reconnection every 5s,
        });

        mqtt.on("error", (err) => {
          logger.captureException(err);
          console.log(`[MQTT] Error`, err);
        });
        mqtt.on("close", () => {
          console.log(`[MQTT] Disconnected from server `);
        });
        mqtt.on("connect", () => {
          console.log(`[MQTT] Connected successfully `);
        });
        mqtt.on("message", handleNewMessage);
        mqtt.subscribe(mqttTopics.orderUpdates(routeId), { qos: 1 }, (err, granted) => {
          if (err) {
            console.log("[MQTT] Subscribe error", err);
          } else {
            console.log(`[MQTT] Subscribed to topic`, granted);
          }
        });

        interval = setInterval(() => {
          setConnected(mqtt?.connected || false);
        }, 1000);
      } catch (e) {
        if (e instanceof ApiError && e.code === 404) {
          store.order.update({
            loading: false,
            error: "not_found",
            item: null,
          });
        } else {
          logger.captureException(e);
          store.order.update({
            loading: false,
            error: "generic",
            item: null,
          });
        }
      }
    })();

    return () => {
      clearInterval(interval);
      mqtt?.end();
    };
  }, [routeId]);

  const active = logged_in_only && !store.customer.isLoggedIn ? false : !!routeId;

  const isInternalDelivery = !!o?.driver_deliveries[0];
  const modalWidth = loading ? 420 : isInternalDelivery ? 840 : 420;
  const screenBelowMmd = useScreenMmd();

  return (
    <Modal
      id="order-receipt-modal"
      active={active}
      closeButton={true}
      fullScreenBelowSm={true}
      width={modalWidth}
      close={() => store.router.push("/")}>
      {loading && <OrderReceiptLoading />}

      {!loading && error && <OrderReceiptError error={errors[error]} />}

      {!loading && !error && o && (
        <div className={classNames(isInternalDelivery ? "flex" : "")}>
          <div className={isInternalDelivery ? "w-full mmd:w-1/2" : ""}>
            <OrderReceiptHeader r={r} o={o} connected={connected} />
            <OrderTimeline status={o.status} isDelivery={o.config_service === "delivery"} />
            {isInternalDelivery && screenBelowMmd && (
              <div className="flex flex-col flex-grow border-l">
                <OrderReceiptMap r={r} o={o} tab={tab} type="mobile" />
                <OrderReceiptDriver r={r} o={o} />
              </div>
            )}
            <ModalTabs
              value={tab}
              onChange={(selected) => setTab(selected.value as number)}
              tabs={[
                { label: "Details", value: 0 },
                { label: "Dishes", value: 1 },
              ]}
            />
            {tab === 0 && (
              <ModalContent paddinglr={20} paddingtb={25}>
                <OrderReceiptDetails r={r} o={o} />
              </ModalContent>
            )}
            {tab === 1 && (
              <ModalContent paddinglr={20} paddingtb={25}>
                <OrderItems items={o.dishes} />
                <OrderTotals
                  dishes={o.dishes}
                  fees={o.fees}
                  discounts={o.discounts}
                  promoCode={o.promo_old_code}
                  promoDiscount={o.promo_old_discount}
                  cart={o.payment_cart}
                  total={o.payment_total}
                  tip={o.payment_tip ?? 0}
                  rounded={0}
                />
              </ModalContent>
            )}
          </div>

          {isInternalDelivery && !screenBelowMmd && (
            <div className="flex flex-col border-l w-1/2">
              <OrderReceiptMap r={r} o={o} tab={tab} type="desktop" />
              <OrderReceiptDriver r={r} o={o} />
            </div>
          )}
        </div>
      )}
    </Modal>
  );
});

export const ReceiptModal = ReceiptModalClass;
