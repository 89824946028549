import * as React from "react";
import { observer } from "mobx-react";
import { styled, BlockError, InputBox, DotsLoader } from "@lib/components";
import { logger } from "@lib/common";

interface Props {}

const style = {
  base: {
    fontSmoothing: "antialiased",
  },
  invalid: {
    color: "#bd362f",
    iconColor: "#bd362f",
  },
};
const StripeInput = styled(InputBox)`
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    width: 100%;
  }
`;

export const StripeCardForm = observer((props: Props) => {
  const [initError, setInitError] = React.useState("");
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  function init() {
    try {
      if (!window.stripeCard) {
        window.stripeCard = window.stripeElements!.create("card", { style });
      }
      window.stripeCard.mount("#stripe-card-el");
      window.stripeCard.on("change", (event) => {
        if (!event) return;
        const err = event.error ? event.error.message : "";
        setError(err || "");
      });
    } catch (e) {
      logger.captureException(e);
      setInitError("Error loading payment form, please try again");
    }
  }

  React.useEffect(() => {
    let loadingInterval: any;
    if (window.stripeElements) {
      setLoading(false);
      setTimeout(init, 0);
    } else {
      loadingInterval = setInterval(() => {
        if (window.stripeElements) {
          setLoading(false);
          clearInterval(loadingInterval);
          setTimeout(init, 0);
        }
      }, 200);
    }
    return () => {
      clearInterval(loadingInterval);
      if (window.stripeCard) {
        window.stripeCard.destroy();
        window.stripeCard = undefined;
      }
    };
  }, []);

  if (loading) {
    return (
      <div className="pt-1 pb-2">
        <DotsLoader size={14} />
      </div>
    );
  }

  if (initError) {
    return <BlockError text={initError} />;
  }

  return (
    <>
      <StripeInput id="stripe-card-el" />
      {!!error && <BlockError text={error} className="mt-2" />}
    </>
  );
});
