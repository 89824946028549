import * as React from "react";
import { observer } from "mobx-react";
import { styled } from "@lib/components";
import { Dish } from "./dish";
import {
  RestaurantMenuCategoryFullFragment,
  RestaurantMenuDishFullFragment,
} from "@lib/types/graphql";

interface Props {
  category: Omit<RestaurantMenuCategoryFullFragment, "dishes"> & {
    dishes: Array<RestaurantMenuDishFullFragment & { surcharge_pct?: number }>;
  };
  restricted: boolean;
  restrictedReason: string;
}

const DishWrapper = styled.div`
  > * {
    &:first-child {
      border-top-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }
    &:last-child {
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }
`;

export const CategoryDishes = observer(({ category }: Props) => {
  return (
    <DishWrapper className="mt-4">
      {category.dishes.map((d, k) => {
        // const tags = r.dish_tags.filter((tag) => d.tags.indexOf(tag._id) !== -1);
        return <Dish key={d.id} d={d} c={category} index={k} />;
      })}
    </DishWrapper>
  );
});
