import * as React from "react";
import { observer } from "mobx-react";
import { useStore } from "../../../mobx/component";
import { HeaderA } from "./types/HeaderA";
import { HeaderB } from "./types/HeaderB";

export const Header = observer(() => {
  const store = useStore();
  const r = store.r;
  const w = r.website!;

  if (w.header_type === "b") {
    return <HeaderB />;
  }

  return <HeaderA />;
});
