import * as React from "react";
import cn from "classnames";
import Big from "big.js";
import { observer } from "mobx-react";
import { styled, css } from "@lib/components";
import { CoreUtils, formatCurrency, menuUtility, menuDish } from "@lib/common";
import { useStore } from "../../../../mobx/component";
import { RootStore } from "../../../../mobx/store";
import { RestaurantMenuCategoryFragment, RestaurantMenuDishFullFragment } from "@lib/types/graphql";
import TruncateMarkup from "react-truncate-markup";

interface Props {
  d: RestaurantMenuDishFullFragment & { surcharge_pct?: number };
  c: RestaurantMenuCategoryFragment;
  index: number;
}

const Image = styled("div")<{ url?: string }>`
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  &:before {
    content: "\\00a0 ";
  }
  ${({ url }) =>
    !url
      ? ""
      : css`
          background: url(${url}) no-repeat center center;
          background-size: cover;
        `}
`;

function onClick(store: RootStore, props: Props, os_id?: string, o_id?: string) {
  const require_id = false;
  const { d, c } = props;

  if (!store.order_config.d.confirmed) {
    store.dish.setPreset({
      dish_id: d.id,
      category_id: c.id,
      os_id: os_id,
      o_id: o_id,
    });
    store.modal.toggle("order-config");
    return;
  }

  if (store.menuAvailability.isRestricted) {
    if (store.menuAvailability.reason === "age") {
      require_id ? store.modal.toggle("menu-restrictions") : store.modal.toggle("over-18");
    } else {
      store.modal.toggle("menu-restrictions");
    }
    return;
  }

  // If status applied to dish or category unavailable
  if (d.status !== null) {
    return;
  }

  const categoryAvailability = store.menuAvailabilityCheck(c);
  if (categoryAvailability.isRestricted) {
    if (categoryAvailability.reason === "age" && !require_id) {
      store.modal.toggle("over-18");
    }
    return;
  }

  store.dish.set({
    dish_id: d.id,
    category_id: c.id,
    os_id: os_id,
    o_id: o_id,
  });
}

function getName({ d }: Props) {
  return d.display_name || d.name;
}
function getImage({ d }: Props) {
  return CoreUtils.image.uc(d.image, {
    w: 100, // 180,
    h: 100, // 220,
    fm: "jpg",
  });
}
function getPrices(store: RootStore, props: Props) {
  const r = store.r;
  const { d } = props;

  let prices = [
    {
      price: menuDish.price({ dish: d, service: store.order_config.s.config_service || null }),
      name: "",
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation();
        onClick(store, props);
      },
    },
  ];

  if (d.option_set_primary && d.type !== "combo") {
    const os = store.rOptionSets.find((option_set) => option_set.id === d.option_set_primary);
    if (os) {
      prices = os.options
        .filter((o) => !o.no_stock && !o.hide && !o.hide_online)
        .map((o) => ({
          price: parseFloat(
            Big(menuDish.price({ dish: d, service: store.order_config.s.config_service || null }))
              .plus(
                menuUtility.surcharge({
                  price: o.price,
                  surchargePct: d.surcharge_pct,
                }) || 0
              )
              .toFixed(2)
          ),
          name: o.name,
          onClick: (e: React.MouseEvent) => {
            e.stopPropagation();
            onClick(store, props, os.id, o.id);
          },
        }));
    }
  }

  return prices;
}
function getDescription(store: RootStore, { d }: Props) {
  // const hide = store.r.website.sections.menus.dish_hide_description;
  const hide = false;
  return hide ? "" : d.description;
}
function isOutOfStock({ d }: Props) {
  return d.status === "no_stock";
}

export const Dish = observer((props: Props) => {
  const { d } = props;

  if (d.status === "hidden") return null;

  const store = useStore();

  const image = getImage(props);
  const name = getName(props);
  const prices = getPrices(store, props);
  const description = getDescription(store, props);
  const outOfStock = isOutOfStock(props);

  const wrapperClass = cn(
    "flex items-center py-3 px-4 bg-white border-b shadow lg:shadow-md hover:shadow-lg trans-200",
    outOfStock ? "cursor-not-allowed" : "cursor-pointer"
  );

  return (
    <div className={wrapperClass} onClick={outOfStock ? undefined : () => onClick(store, props)}>
      {image && <Image url={image} className="mr-4 rounded" />}
      <div className="flex justify-between items-center flex-grow">
        <div>
          <p className="text-md font-medium">{name}</p>

          {d.subtitle && (
            <p className="font-semibold text-xs" style={{ marginTop: "-1px", marginBottom: "1px" }}>
              {d.subtitle}
            </p>
          )}

          {description && (
            <TruncateMarkup lines={store.r.website!.menu_dish_description_truncate_lines || 5}>
              <p className="text-sm text-gray-800 mr-4 lg:mr-8 xl:mr-12">{description}</p>
            </TruncateMarkup>
          )}

          {outOfStock && (
            <p style={{ fontSize: "12px" }} className="font-bold underline text-error">
              Out Of Stock
            </p>
          )}

          {/*tags.length > 0 && (
            <InnerTags className="flex mt-1">
              {tags.map((tag, i) => <DishTag key={i} {...tag}/>)}
            </InnerTags>
          )*/}

          {prices.length > 1 && (
            <div className="flex flex-wrap xl:hidden mt-2">
              {prices.map((p, i) => (
                <div key={i} className="mx-2" onClick={p.onClick}>
                  <p
                    className="text-sm bg-gray-100 rounded px-2 -ml-2 leading-relaxed"
                    style={{ background: `#f2f2f2` }}>
                    {formatCurrency(p.price)}
                  </p>
                  {p.name && (
                    <p style={{ fontSize: "12px" }} className="font-semibold text-gray-900">
                      {p.name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        {prices.length === 1 && (
          <div className="ml-2 flex flex-col items-end">
            <div className="text-sm font-medium bg-gray-100 rounded px-2 leading-relaxed">
              {formatCurrency(prices[0].price)}
            </div>
            {d.price_suffix && (
              <p style={{ fontSize: "12px" }} className="font-semibold text-gray-900 pr-2">
                {d.price_suffix}
              </p>
            )}
          </div>
        )}

        {prices.length > 1 && (
          <div className="hidden xl:flex">
            {prices.map((p, i) => (
              <div key={i} className="mx-2 text-right" onClick={p.onClick}>
                <p
                  className="text-sm bg-gray-100 rounded px-2 -mr-2 leading-relaxed"
                  style={{ background: `#f2f2f2` }}>
                  {formatCurrency(p.price)}
                </p>
                {p.name && (
                  <p style={{ fontSize: "12px" }} className="font-semibold text-gray-900">
                    {p.name}
                  </p>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

// Truncate description
// const truncateValue = !!image ? 54 : 94;
// const description = dish.description.length > truncateValue ? `${dish.description.substring(0, truncateValue)}...` : dish.description;

/*
statusApplied && (
  <p className="font-bold text-sm italic underline text-error mt-1">{t(`restaurant.menus.dish.${d.status!.replace("-", "_")}`)}</p>
)

prices.length > 1 && (
<div className="flex flex-wrap -m-1 mt-1 xl:hidden">
  {prices.map((p, i) => (
    <div key={i} className="flex items-center m-1 mr-2" onClick={p.onClick}>
      <p className="text-sm bg-gray-100 rounded px-2 mr-2 leading-relaxed" style={{ background: `#f2f2f2` }}>
        {t("currency", { value: p.cost })}
      </p>
      {p.name && <p style={{ fontSize: "12px" }} className="font-semibold text-gray-1000">{p.name}</p>}
    </div>
  ))}
</div>
)

prices.length > 1 && (
  <div className="flex flex-wrap -m-1 mt-1 xl:hidden">
    {prices.map((p, i) => (
      <div key={i} className="flex items-center bg-gray-100 rounded m-1 mr-1" onClick={p.onClick}>
        <p className="text-sm rounded px-2 leading-relaxed" style={{ background: `#f2f2f2` }}>
          {t("currency", { value: p.cost })}
        </p>
        <p style={{ fontSize: "12px" }} className="font-semibold px-2 text-gray-1000">
          {p.name}
        </p>
      </div>
    ))}
  </div>
)

prices.length === 1 && (
  <div className="flex">
    <div className="mt-1">
      <div className="text-sm font-medium bg-gray-100 rounded px-2 leading-relaxed" style={{ background: `#f2f2f2` }}>
        {t("currency", { value: prices[0].p })}
      </div>
    </div>
  </div>
)
*/
