import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";
import { Modal, ModalContent, Button } from "@lib/components";

interface Props {}

@inject("store")
@observer
class Over18ModalClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { view, r } = store;
    const { age_confirmed } = view.s;

    const currentMenu = store.menu;
    const onlyOneMenu = store.menusAll.length === 1;
    const menuAgeRestrictionActive = store.menusAll.reduce(
      (a, v) => v.restrict_age || false,
      false
    );

    let showModal = store.modal.isVisible("over-18");

    if (!age_confirmed) {
      if (onlyOneMenu && menuAgeRestrictionActive) {
        showModal = true;
      } else if (currentMenu?.restrict_age) {
        showModal = true;
      }
    }

    function close() {
      store.modal.close();
      if (onlyOneMenu && menuAgeRestrictionActive) {
        // One menu which is age restricted, so redirect them away
        window.location.href = `https://www.google.com`;
      } else if (onlyOneMenu) {
        // A category was restricted, nothing needs to be done
      } else if (currentMenu?.restrict_age) {
        // Multiple menus, go to one that's not age restricted
        let firstNonRestrictedMenu;
        for (const m of store.menusAll) {
          if (!m.restrict_age) {
            firstNonRestrictedMenu = m.id;
            break;
          }
        }
        if (firstNonRestrictedMenu) {
          store.filters.set([firstNonRestrictedMenu]);
          store.filters.scrollToCategory();
        }
      }
    }

    return (
      <Modal
        id="modal-menu-restrictions"
        width={420}
        closeButton={!onlyOneMenu}
        active={showModal}
        close={() => (!onlyOneMenu ? close() : null)}>
        <ModalContent>
          <h4>Adults Only (18+)</h4>
        </ModalContent>
        <ModalContent>
          {onlyOneMenu && menuAgeRestrictionActive ? (
            <p>
              This site sells age restricted products and is only suitable for those over the age of
              18
            </p>
          ) : (
            <p>
              This menu / category contains age restricted products and is only suitable for those
              over the age of 18
            </p>
          )}
        </ModalContent>
        <ModalContent className="text-right" paddingtb={15}>
          <Button className="mr-2" onClick={close}>
            I'm Under 18
          </Button>
          <Button
            color="primary"
            onClick={() => {
              store.modal.close();
              view.update({ age_confirmed: true });
            }}>
            I'm Over 18
          </Button>
        </ModalContent>
      </Modal>
    );
  }
}

export const Over18Modal = Over18ModalClass;
