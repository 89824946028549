import * as React from "react";
import { observer, inject } from "mobx-react";
import { MobxComponent } from "../../../mobx/component";

interface Props {
  renderClosed: () => React.ReactNode;
  renderOpen: () => React.ReactNode;
  renderCart: () => React.ReactNode;
}

@inject("store")
@observer
class MainActionsRendererClass extends MobxComponent<Props> {
  render() {
    const { store } = this.injected;
    const { renderClosed, renderOpen, renderCart } = this.props;
    const { servicesAllDisabled, orderingDisabled } = store;
    const confirmed = store.order_config.d.confirmed;
    return (
      <>
        {(servicesAllDisabled || orderingDisabled) && renderClosed()}
        {!confirmed && !servicesAllDisabled && !orderingDisabled && renderOpen()}
        {confirmed && renderCart()}
      </>
    );
  }
}

export const MainActionsRenderer = MainActionsRendererClass;
